import components from '../../common/_components';
import bugsnag from '../../common/_bugsnag-client';
import { _off, _on, _onTransitionEnd } from '../../common/_events';
import { _closest, _getData, _reflow, _setData, _toArray } from '../../common/_core';
import { _copyText } from '../../common/_selection';
import Tooltip from '../../components.theme/tooltip/tooltip';
import overlayTemplate from './__overlay-el';

import './deals-sharing.scss';

const blockName = 'deals-sharing';

const overlayVisibleMod = 'visible';
const overlayShownMod = 'shown';

let hasHandlerAttached = false;

const hideSharing = ({ exceptOverlay }) => {
    const overlays = document.querySelectorAll(`.js-${blockName}__overlay.${overlayShownMod}`);

    _toArray(overlays).forEach((overlayEl) => {
        if (!overlayEl.classList.contains(exceptOverlay)) {
            const sharingCopyButtonEl = overlayEl.querySelector(`.js-${blockName}__copy-button`);

            if (sharingCopyButtonEl) {
                const tooltip = new Tooltip(sharingCopyButtonEl);
                tooltip.hide();
            }

            _onTransitionEnd(overlayEl, () => {
                overlayEl.classList.remove(overlayVisibleMod);
            });

            overlayEl.classList.remove(overlayShownMod);
        }
    });
};

let hideTooltipTimeout;

const overlayClickHandler = (e) => {
    const buttonEl = _closest(e.target, `.js-${blockName}__button`);
    const copyButtonEl = _closest(e.target, `.js-${blockName}__copy-button`);

    if (buttonEl && buttonEl.getAttribute('target') === '_blank') {
        const width = _getData(buttonEl, 'popupWidth') || '600';
        const height = _getData(buttonEl, 'popupHeight') || '500';
        const targetUrl = buttonEl.getAttribute('href');

        window.open(targetUrl, 'share', `top=100,left=100,width=${width},height=${height}`);

        e.preventDefault();
        e.stopPropagation();
    } else if (copyButtonEl) {
        _copyText(_getData(copyButtonEl, 'clipboardText'), {
            onSuccess: () => {
                const tooltip = new Tooltip(copyButtonEl);

                if (hideTooltipTimeout) {
                    clearTimeout(hideTooltipTimeout);
                }

                tooltip.show(_getData(copyButtonEl, 'successText'));
                hideTooltipTimeout = setTimeout(tooltip.hide, 3000);
            },
        });

        e.stopPropagation();
    } else {
        const clickedEl = _closest(e.target, `.js-${blockName}__modal`)
            || _closest(e.target, `.js-${blockName}`);
        const sharingSelector = clickedEl ? _getData(clickedEl, 'sharingSelector') : '';

        hideSharing({
            exceptOverlay: sharingSelector,
        });

        if (!sharingSelector) {
            _off(document, 'click', overlayClickHandler);
        }
    }
};

const shareButtonClickHandler = (e) => {
    const buttonEl = e._caughtTarget_;

    if (!_getData(buttonEl, 'sharingSelector')) {
        const containerSelector = _getData(buttonEl, 'sharingContainer');
        const buttonsDataJSON = _getData(buttonEl, 'sharingButtons');
        const copyButtonJSON = _getData(buttonEl, 'sharingCopyButton');
        const blockEl = containerSelector
            ? _closest(buttonEl, `.${containerSelector}`) || document.body
            : document.body;
        const sharingUniqueClass = `js-deals-sharing__overlay_${Date.now()}`;

        let buttonsData;
        let copyButtonData;

        try {
            buttonsData = JSON.parse(buttonsDataJSON);
            copyButtonData = JSON.parse(copyButtonJSON);
        } catch (error) {
            bugsnag.notify(error.toString());
        }

        const overlayEl = overlayTemplate({
            buttons: buttonsData,
            copyButtonLabel: copyButtonData.label,
            copyClipboardText: copyButtonData.clipboardText,
            copySuccessText: copyButtonData.successText,
            uniqueMod: sharingUniqueClass,
            overlayClassName: _getData(buttonEl, 'sharingOverlay'),
            modalClassName: _getData(buttonEl, 'sharingModal'),
        });

        blockEl.appendChild(overlayEl);
        _setData(buttonEl, 'sharingSelector', sharingUniqueClass);
    }

    const sharingClassName = _getData(buttonEl, 'sharingSelector');
    const overlayEl = sharingClassName && document.querySelector(`.${sharingClassName}`);

    if (!overlayEl.classList.contains(overlayVisibleMod)) {
        overlayEl.classList.add(overlayVisibleMod);

        _reflow(overlayEl);

        overlayEl.classList.add(overlayShownMod);
        _on(document, 'click', overlayClickHandler);
    }
};

components.add(`js-${blockName}`, () => {
    if (!hasHandlerAttached) {
        _on(document, 'click', `.js-${blockName}`, shareButtonClickHandler, { passive: true });
        hasHandlerAttached = true;
    }
});
