import { _getData } from '../../common/_core';
import RangeSlider from '../range-slider/range-slider';

import './deals-filters-panel__block_for_price.scss';

const blockName = 'deals-filters-panel';

const sliderSelector = `.js-${blockName}__slider`;
const sliderForPriceMod = `${blockName}__slider_for_price`;
const sliderForDiscountMod = `${blockName}__slider_for_discount`;

const sliderInputSelector = `.js-${blockName}__slider-input`;
const sliderInputForMinMod = `${blockName}__slider-input_for_min`;
const sliderInputForMaxMod = `${blockName}__slider-input_for_max`;
const sliderRangeSelector = `.js-${blockName}__slider-range`;

const priceMinKey = 'price[min]';
const priceMaxKey = 'price[max]';
const discountMinKey = 'discount[min]';
const discountMaxKey = 'discount[max]';

class DealsFiltersPanelBlockForPrice {
    constructor(el, urlData, changeHandler) {
        const priceSliderEl = el.querySelector(`${sliderSelector}.${sliderForPriceMod}`);
        const discountSliderEl = el.querySelector(`${sliderSelector}.${sliderForDiscountMod}`);

        this._onChange = changeHandler;
        this._priceRange = [
            parseInt(_getData(priceSliderEl, 'minValue'), 10),
            parseInt(_getData(priceSliderEl, 'maxValue'), 10),
        ];
        this._discountRange = [
            parseInt(_getData(discountSliderEl, 'minValue'), 10),
            parseInt(_getData(discountSliderEl, 'maxValue'), 10),
        ];

        urlData = this._processUrlData(urlData);

        this._priceSlider = this._getRangeSlider(priceSliderEl, this._priceRange, [
            urlData.priceMin,
            urlData.priceMax,
        ]);

        this._discountSlider = this._getRangeSlider(discountSliderEl, this._discountRange, [
            urlData.discountMin,
            urlData.discountMax,
        ]);
    }

    resetToDefault = () => {
        this._priceSlider.setValues(this._priceRange);
        this._discountSlider.setValues(this._discountRange);
    }

    fulfillUrlData = (urlData) => {
        const priceValues = this._priceSlider.getValues();
        const discountValues = this._discountSlider.getValues();

        if (priceValues[0] === this._priceRange[0]) {
            delete urlData[priceMinKey];
        } else {
            urlData[priceMinKey] = priceValues[0];
        }

        if (priceValues[1] === this._priceRange[1]) {
            delete urlData[priceMaxKey];
        } else {
            urlData[priceMaxKey] = priceValues[1];
        }

        if (discountValues[0] === this._discountRange[0]) {
            delete urlData[discountMinKey];
        } else {
            urlData[discountMinKey] = discountValues[0];
        }

        if (discountValues[1] === this._discountRange[1]) {
            delete urlData[discountMaxKey];
        } else {
            urlData[discountMaxKey] = discountValues[1];
        }
    };

    fulfillRequestData = (requestData, urlData) => {
        urlData = this._processUrlData(urlData);

        if (urlData.priceMin !== undefined) {
            requestData[priceMinKey] = urlData.priceMin;
        }

        if (urlData.priceMax !== undefined) {
            requestData[priceMaxKey] = urlData.priceMax;
        }

        if (urlData.discountMin !== undefined) {
            requestData[discountMinKey] = urlData.discountMin;
        }

        if (urlData.discountMax !== undefined) {
            requestData[discountMaxKey] = urlData.discountMax;
        }
    };

    _processUrlData = (rawData) => {
        const getFloatValue = (value, range) => {
            const floatValue = parseFloat(value);
            // eslint-disable-next-line no-restricted-globals
            return isFinite(floatValue) && floatValue <= range[1] && floatValue >= range[0]
                ? floatValue : undefined;
        };

        return {
            priceMax: getFloatValue(rawData[priceMaxKey], this._priceRange),
            priceMin: getFloatValue(rawData[priceMinKey], this._priceRange),
            discountMin: getFloatValue(rawData[discountMinKey], this._discountRange),
            discountMax: getFloatValue(rawData[discountMaxKey], this._discountRange),
        };
    };

    _getRangeSlider = (el, valuesRange, values) => {
        const sliderEl = el.querySelector(sliderRangeSelector);
        const sliderInputMinEl = el.querySelector(`${sliderInputSelector}.${sliderInputForMinMod}`);
        const sliderInputMaxEl = el.querySelector(`${sliderInputSelector}.${sliderInputForMaxMod}`);

        return new RangeSlider(sliderEl, [values[0], values[1]], [sliderInputMinEl, sliderInputMaxEl], {
            minValue: valuesRange[0],
            maxValue: valuesRange[1],
            maxValueLabel: _getData(el, 'maxValueLabel'),
            pattern: _getData(el, 'pattern'),
            onChange: this._onChange,
        });
    };
}

export { DealsFiltersPanelBlockForPrice };
