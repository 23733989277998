import { _getData, _setData, _removeData } from './_core';
import { _on, _off, _getMouseWheelEvent } from './_events';

const installedDataKey = 'scrollLocked';
const startYDataKey = 'scrollStartClientY';
const hasTouchEvents = 'ontouchstart' in window;

function _handler(e) {
    if (!e.ctrlKey) {
        const el = e._caughtTarget_;
        const elScrollTop = el.scrollTop;
        const elScrollHeight = el.scrollHeight;
        const elClientHeight = el.clientHeight;

        if (elScrollHeight > elClientHeight) {
            e.stopPropagation();
        }

        let delta = e.wheelDelta || (-1 * e.detail) || (-1 * e.deltaY);
        let deltaY = 0;

        if (e.type === 'wheel') {
            deltaY = e.deltaY * el.getBoundingClientRect().height / document.documentElement.clientHeight;
        } else if (hasTouchEvents && e.type === 'touchmove') {
            delta = e.changedTouches[0].clientY - parseFloat(_getData(el, startYDataKey));
        }

        const isTop = delta > 0 && elScrollTop + deltaY <= 0;
        const isBottom = delta < 0 && elScrollTop + deltaY >= elScrollHeight - elClientHeight;

        if ((isTop || isBottom) && deltaY) {
            e.preventDefault();
            el.scrollTop = elScrollTop + deltaY;
        }
    }
}

function _touchHandler(e) {
    _setData(e._caughtTarget_, startYDataKey, e.touches[0].clientY);
}

function lockPageScrolling(el) {
    if (_getData(el, installedDataKey) !== 'true') {
        _setData(el, installedDataKey, 'true');

        _on(el, _getMouseWheelEvent(), _handler);

        if (hasTouchEvents) {
            _on(el, 'touchstart', _touchHandler);
            _on(el, 'touchmove', _handler);
        }
    }
}

function unlockPageScrolling(el) {
    if (_getData(el, installedDataKey) === 'true') {
        _removeData(el, installedDataKey);

        _off(el, _getMouseWheelEvent(), _handler);

        if (hasTouchEvents) {
            _off(el, 'touchstart', _touchHandler);
            _off(el, 'touchmove', _handler);
        }
    }
}

export { lockPageScrolling, unlockPageScrolling };
