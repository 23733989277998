import { _sendRequest } from '../../common/_ajax';
import components from '../../common/_components';
import { _getData } from '../../common/_core';
import { _on } from '../../common/_events';

import './deals-like-button.scss';

const blockName = 'deals-like-button';
const disabledMod = 'disabled';
const likedMod = 'liked';

let hasHandlerAttached = false;

const likeClickHandler = (e) => {
    const linkEl = e._caughtTarget_;

    if (!linkEl.classList.contains(disabledMod) && !linkEl.classList.contains(likedMod)) {
        linkEl.classList.add(disabledMod);

        _sendRequest({
            method: 'POST',
            url: _getData(linkEl, 'likeUrl'),
            success: (response) => {
                if (response.success) {
                    const linkTextEl = linkEl.querySelector(`.js-${blockName}__value`);
                    const likes = parseInt(linkTextEl.textContent, 10);

                    linkTextEl.textContent = String(likes + 1);
                    linkEl.classList.add(likedMod);
                }
            },
            always: () => {
                linkEl.classList.remove(disabledMod);
            },
        });
    }
};

components.add(`js-${blockName}`, () => {
    if (!hasHandlerAttached) {
        _on(document, 'click', `.js-${blockName}`, likeClickHandler, { passive: true });
        hasHandlerAttached = true;
    }
});
