import { _on } from './_events';
import { StorageSession } from './_storage-session';

const KEY_NAME = '__history__';
const path = [];
const pathMaxLen = 4;

class HistoryItem {
    constructor({ url, title, isCurrent }) {
        this.url = url;
        this.title = title;
        this.isCurrent = (isCurrent === true);
    }

    toStorageItem = () => (this.isCurrent === true
        ? [this.url, this.title, 1]
        : [this.url, this.title]);

    static fromStorageItem = storageItem => new HistoryItem({
        url: storageItem[0],
        title: storageItem[1],
        isCurrent: storageItem[2] === 1,
    });
}

function _readFromStorage() {
    const storageData = StorageSession.get(KEY_NAME);
    const parsedData = storageData ? JSON.parse(storageData) : null;

    if (Array.isArray(parsedData)) {
        parsedData.forEach(storageItem => path.push(HistoryItem.fromStorageItem(storageItem)));
    }
}

function _writeIntoStorage() {
    path.splice(0, path.length - pathMaxLen);
    StorageSession.set(KEY_NAME, JSON.stringify(path.map(historyItem => historyItem.toStorageItem())));
}

function getCurrentUrl() {
    return `${window.location.pathname}${window.location.search}${window.location.hash}`;
}

function _getRelativeUrl(url) {
    const aTag = document.createElement('a');
    let pathName;

    aTag.href = url;
    pathName = aTag.pathname;

    if (pathName[0] !== '/') {
        pathName = `/${pathName}`;
    }

    return `${pathName}${aTag.search}${aTag.hash}`;
}

function isStatePushed() {
    return window.history.state && window.history.state.isPushed === true;
}

let isPopStateItemAttached = false;
let isInReloadingProcess = false;

function _attachPopStateHandler() {
    if (!isPopStateItemAttached) {
        _on(window, 'popstate', () => {
            if (isStatePushed()) {
                isInReloadingProcess = true;
                window.location.reload();
            }
        });

        isPopStateItemAttached = true;
    }
}

function pushPreviousPath() {
    if (!StorageSession.isAvailable() || isStatePushed() || path.length < 2) {
        return;
    }

    const historyItems = path.slice();
    const pushItem = () => {
        const historyItem = historyItems.shift();
        const action = historyItem === path[0] ? 'replaceState' : 'pushState';
        const state = {
            isPushed: true,
            url: historyItem.url,
        };

        window.history[action](state, historyItem.title, historyItem.url);
        document.title = historyItem.title;

        if (historyItems.length > 0) {
            setTimeout(pushItem, 0);
        }
    };

    pushItem();
    _attachPopStateHandler();
}

function replaceState(url) {
    if (!StorageSession.isAvailable() || isInReloadingProcess) {
        return;
    }

    const targetUrl = _getRelativeUrl(url);
    let currentHistoryItem;

    if (isStatePushed()) {
        for (let i = 0; i < path.length; i += 1) {
            if (path[i].isCurrent) {
                currentHistoryItem = path[i];
                break;
            }
        }
    }

    if (!currentHistoryItem) {
        currentHistoryItem = path[path.length - 1];
    }

    if (currentHistoryItem && targetUrl !== currentHistoryItem.url) {
        const currentState = window.history.state;

        currentHistoryItem.url = targetUrl;

        if (currentState) {
            currentState.url = currentHistoryItem.url;
        }

        window.history.replaceState(currentState, currentHistoryItem.title, currentHistoryItem.url);
        _writeIntoStorage();
    }
}

function getPreviousPath() {
    const previousPath = [];

    for (let i = 0; i < path.length; i += 1) {
        const historyItem = path[i];

        previousPath.push(historyItem.url);

        if (historyItem.isCurrent === true) {
            break;
        }
    }

    return previousPath;
}

if (StorageSession.isAvailable()) {
    _readFromStorage();

    if (isStatePushed()) {
        const currentState = window.history.state || {};
        const currentUrl = getCurrentUrl();

        let hasFound = false;

        if (currentState.url === currentUrl) {
            path.forEach((historyItem) => {
                if (!hasFound && historyItem.url === currentUrl) {
                    historyItem.isCurrent = true;
                    hasFound = true;
                } else {
                    historyItem.isCurrent = false;
                }
            });

            _attachPopStateHandler();
            _writeIntoStorage();
        } else {
            replaceState(currentState.url);
            window.location.reload();
        }
    } else {
        const currentUrl = getCurrentUrl();
        const currentTitle = document.title;
        let i = 0;

        while (i < path.length) {
            if (path[i].isCurrent === true) {
                path[i].isCurrent = false;
                path.splice(i + 1, path.length);

                break;
            }

            i += 1;
        }

        const latestItem = path[path.length - 1];

        if (!latestItem || (currentUrl !== latestItem.url || currentTitle !== latestItem.title)) {
            path.push(new HistoryItem({ title: currentTitle, url: currentUrl }));
        }

        _writeIntoStorage();
    }
}

export {
    getCurrentUrl as _getCurrentUrl,
    getPreviousPath as _getPreviousPath,
    pushPreviousPath as _pushPreviousPath,
    replaceState as _replaceState,
    isStatePushed as _isStatePushed,
};
