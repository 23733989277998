import components from '../../common/_components';
import { _sendRequest, _serialize } from '../../common/_ajax';
import { _getData } from '../../common/_core';
import { _on } from '../../common/_events';
import Tooltip from '../../components.theme/tooltip/tooltip';
import './page-deals-add.scss';

const blockName = 'page-deals-add';

components.add(`js-${blockName}__newsletter-form`, (formEl) => {
    const inputEl = formEl.querySelector(`.js-${blockName}__newsletter-input`);
    const buttonEl = formEl.querySelector(`.js-${blockName}__newsletter-button`);

    let tooltip;

    function hideTooltip() {
        if (tooltip) {
            tooltip.hide();
        }
    }

    _on(inputEl, 'input', () => {
        const isValid = inputEl.checkValidity ? inputEl.checkValidity() : inputEl.value.length > 0;

        hideTooltip();
        buttonEl.disabled = !isValid;
    });

    _on(formEl, 'submit', (e) => {
        e.preventDefault();

        tooltip = new Tooltip(inputEl);
        tooltip.hide();

        if (!buttonEl.disabled) {
            const formData = _serialize(formEl);

            inputEl.disabled = true;
            buttonEl.disabled = true;

            _sendRequest({
                method: 'POST',
                url: _getData(formEl, 'ajaxUrl'),
                data: formData,
                success: (responseJSON) => {
                    inputEl.placeholder = responseJSON.data.message;
                    inputEl.value = '';
                },
                error: (responseJSON) => {
                    if (responseJSON && responseJSON.data && responseJSON.data.message) {
                        tooltip.show(responseJSON.data.message);
                    }

                    inputEl.disabled = false;
                    buttonEl.disabled = false;
                },
            });
        }

        e.preventDefault();
    });
});
