import components from '../../common/_components';
import { _on } from '../../common/_events';
import './promo-singles-day.scss';

const blockName = 'promo-singles-day';

components.add(`js-${blockName}`, (el) => {
    _on(el, 'done.promo-countdown', () => {
        const countdownEl = el.querySelector(`.js-${blockName}__countdown`);

        if (countdownEl) {
            countdownEl.parentNode.removeChild(countdownEl);
        }

        el.classList.remove(`${blockName}_with-counter`);
    }, { once: true, passive: true });
});
