import { _forIn } from './_core';
import { _on, _off, _getMouseWheelEvent } from './_events';

class Animation {
    /**
     * @deprecated
     * The class is placed here, because here is the one place we actually need that.
     * That is not so for now, because the class is created with refactoring and merging that fast is important
     * As far as known, all other cases can be done with CSS transitions.
     *
     * @param {Node} el
     * @param {Object} animations list of properties to animate
     *              to formats are supported:
     *              1. { scrollLeft: 100, opacity: 1 }
     *              2. {
     *                  getter: (el) => parseFloat(el.style.height) - optional
     *                  setter: (el, val) => el.style.height = `${val}px`  - optional
     *                  to: 100
     *               }
     * @param {Object} [options]
     * @param {Number} [options.duration=250]
     * @param {Function} [options.done]
     * @param {Function} [options.stopped]
     * @param {Function} [options.always]
     */
    constructor(el, animations, options) {
        this._el = el;
        this._props = {};
        this._options = {
            ...{ duration: 250 },
            ...options,
        };

        this._startTime = Date.now();
        this._callbacks = {};

        ['done', 'stopped', 'always'].forEach((queueName) => {
            this._callbacks[queueName] = this._options[queueName] ? [this._options[queueName]] : [];
        });

        _forIn(animations, (animation, prop) => {
            const getter = animation.getter || (animationTargetEl => animationTargetEl[prop]);
            const setter = animation.setter || ((animationTargetEl, val) => {
                animationTargetEl[prop] = val;
            });

            this._props[prop] = {
                getter,
                setter,
                from: getter(el),
                to: typeof animation.to === 'number' ? animation.to : animation,
            };
        });

        this._schedule(this._tick);
        return this;
    }

    done = (fn) => {
        this._callbacks.done.push(fn);
        return this;
    };

    stopped = (fn) => {
        this._callbacks.stopped.push(fn);
        return this;
    };

    always = (fn) => {
        this._callbacks.always.push(fn);
        return this;
    };

    stop = () => {
        this._startTime = null;
        this._callbacks.stopped.forEach(cb => cb());
        this._callbacks.always.forEach(cb => cb());
    };

    _schedule = (fn) => {
        if (document.hidden === false && window.requestAnimationFrame) {
            window.requestAnimationFrame(fn);
        } else {
            window.setTimeout(fn, 10);
        }
    };

    _tick = () => {
        if (this._startTime) {
            let percentages = (Date.now() - this._startTime) / this._options.duration;

            if (percentages < 0) {
                percentages = 0;
            } else if (percentages > 1) {
                percentages = 1;
            }

            const pos = 0.5 - Math.cos(percentages * Math.PI) / 2;

            _forIn(this._props, (animation) => {
                animation.setter(this._el, animation.from + (animation.to - animation.from) * pos);
            });

            if (percentages === 1) {
                this._startTime = null;
                this._callbacks.done.forEach(cb => cb());
                this._callbacks.always.forEach(cb => cb());
            } else {
                this._schedule(this._tick);
            }
        }
    };
}

export { Animation };

/**
 * @param {Object} localHeadersHeightManager
 * @return {{scroll: Function, stop: Function, isInProgress: Function, scrollToTop: Function}}
 */
export function getConfigured(
    {
        getHeaderHeight = () => 0,
        addHeaderStateChangeHandler = () => {},
        removeHeaderStateChangeHandler = () => {},
    },
) {
    let animationObj = null;

    function stop() {
        if (animationObj) {
            animationObj.stop();
        }
    }

    function scroll(scrollTop, options = {}) {
        const {
            jump = null,
            duration = 400,
            onStop = () => {},
        } = options;

        window.history.scrollRestoration = 'manual';

        stop();

        if (duration < 50) {
            window.scrollTo(window.pageXOffset, scrollTop);
            return;
        }

        _on(document, _getMouseWheelEvent(), stop, { passive: true });
        _on(document, 'touchstart', stop, { passive: true });

        if (jump) {
            const currentScrollTop = window.pageYOffset;

            if (scrollTop > currentScrollTop) {
                const jumpScrollTop = scrollTop - jump;

                if (jumpScrollTop > currentScrollTop) {
                    window.scrollTo(window.pageXOffset, jumpScrollTop);
                }
            } else if (scrollTop < currentScrollTop) {
                const jumpScrollTop = scrollTop + jump;

                if (jumpScrollTop < currentScrollTop) {
                    window.scrollTo(window.pageXOffset, jumpScrollTop);
                }
            }
        }

        animationObj = new Animation(document, {
            scrollTop: {
                getter: el => el.documentElement.scrollTop,
                setter: (el, value) => el.defaultView.scrollTo(el.defaultView.pageXOffset, value),
                to: scrollTop,
            },
        }, { duration });

        animationObj.always(() => {
            _off(document, _getMouseWheelEvent(), stop);
            _off(document, 'touchstart', stop);

            animationObj = null;
            onStop();
        });
    }

    function isInProgress() {
        return Boolean(animationObj);
    }

    function scrollToTop(el, options = {}) {
        const scrollTop = window.pageYOffset;
        const targetScrollTop = el.getBoundingClientRect().top + scrollTop - getHeaderHeight();

        if (scrollTop > targetScrollTop && !isInProgress()) {
            const onHeaderPinStateChange = () => {
                if (isInProgress()) {
                    stop();
                    scrollToTop(el, options);
                }
            };

            addHeaderStateChangeHandler(onHeaderPinStateChange);
            scroll(targetScrollTop, {
                ...{
                    onStop: () => {
                        removeHeaderStateChangeHandler(onHeaderPinStateChange);
                    },
                },
                ...options,
            });
        }
    }

    return {
        scroll,
        stop,
        isInProgress,
        scrollToTop,
    };
}
