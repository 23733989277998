import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _getData } from '../../common/_core';
import * as scrollPage from '../../common/_scroll-page';
import HeadersHeightManager from '../../common/_headers-height-manager';

import './shops-description-headlines.scss';

const blockName = 'shops-description-headlines';

function linkClickHandler(e) {
    e.preventDefault();

    const clickedLinkEl = e._caughtTarget_;
    const linkId = _getData(clickedLinkEl, 'linkId');
    const targetEl = document.getElementById(linkId);

    if (targetEl && !scrollPage.isInProgress()) {
        const targetOffsetTop = targetEl.getBoundingClientRect().top + window.pageYOffset;
        const offset = _getData(clickedLinkEl, 'linkOffset');

        scrollPage.scroll(targetOffsetTop - HeadersHeightManager.getHeight() - offset, { jump: 100 });
        document.location.hash = `#${linkId}`;
    }
}

components.add(`js-${blockName}`, (rootEl) => {
    _on(rootEl, 'click', `.js-${blockName}__link`, linkClickHandler);
});
