import components from '../../common/_components';
import { _getCSSProp, _getData, _isElementVisible, _reflow, _toArray } from '../../common/_core';
import { _sendRequest } from '../../common/_ajax';
import { _off, _on, _onTransitionEnd } from '../../common/_events';
import { _createEl } from '../../common/_create-el';
import { FixedPanelsManager } from '../../common/_fixed-panels-manager';
import { throttle } from '../../vendors.theme/_throttle-debounce';
import { onFiltersChange, resetFiltersToDefault } from '../deals-filters-panel/deals-filters-panel';
import { FullScreenModal } from '../full-screen-modal/full-screen-modal';

import './deals-filtered-list.scss';

const blockName = 'deals-filtered-list';
const buttonShownMod = 'shown';
const buttonVisibleMod = 'visible';

class DealsFilteredList {
    constructor(el) {
        this._el = el;
        this._buttonEl = el.querySelector(`.js-${blockName}__filters-button`);
        this._isButtonAvailable = false;

        onFiltersChange(this._onFiltersChange);

        _on(window, 'resize', throttle(200, this._resizeHandler));
        _on(window, 'scroll', throttle(200, this._scrollHandler));

        FixedPanelsManager.setupPanel(15, false, this._getButtonHeight, this._bottomOffsetChangeHandler);
        this._resizeHandler();
    }

    _resizeHandler = () => {
        if (_isElementVisible(this._buttonEl)) {
            if (!this._isButtonAvailable) {
                _on(this._buttonEl, 'click', this._onFiltersButtonClick);
                _on(this._el, '_nodes-inserted', this._scrollHandler);

                this._isButtonAvailable = true;
                this._scrollHandler();
            }
        } else if (this._isButtonAvailable) {
            _off(this._el, '_nodes-inserted', this._scrollHandler);
            _off(this._buttonEl, 'click', this._onFiltersButtonClick);

            this._isButtonAvailable = false;
            this._scrollHandler();
        }
    };

    _scrollHandler = () => {
        const buttonEl = this._buttonEl;

        if (!this._isButtonAvailable) {
            buttonEl.classList.remove(buttonShownMod);
            buttonEl.classList.remove(buttonVisibleMod);

            return;
        }

        const elRect = this._el.getBoundingClientRect();
        const windowHeight = document.documentElement.clientHeight;
        const hasVisibleMod = buttonEl.classList.contains(buttonVisibleMod);
        const shouldBeVisible = (elRect.top < windowHeight * 0.8 && elRect.top + elRect.height > windowHeight * 0.2);

        if (shouldBeVisible && !hasVisibleMod) {
            if (!buttonEl.classList.contains(buttonShownMod)) {
                buttonEl.classList.add(buttonShownMod);
                _reflow(buttonEl);
            }

            buttonEl.classList.add(buttonVisibleMod);
            FixedPanelsManager.dispatchUpdate();
        } else if (!shouldBeVisible && hasVisibleMod) {
            buttonEl.classList.remove(buttonVisibleMod);
            _onTransitionEnd(buttonEl, this._onButtonTransitionEnd);
        }
    };

    _onButtonTransitionEnd = () => {
        if (!this._buttonEl.classList.contains(buttonVisibleMod)) {
            this._buttonEl.classList.remove(buttonShownMod);
            FixedPanelsManager.dispatchUpdate();
        }
    };

    _getButtonHeight = () => {
        if (_isElementVisible(this._buttonEl) && this._buttonEl.classList.contains(buttonShownMod)) {
            return this._buttonEl.offsetHeight + parseFloat(_getCSSProp(this._buttonEl, 'bottom'));
        }

        return 0;
    };

    _bottomOffsetChangeHandler = (offset) => {
        this._buttonEl.style.transform = `translateY(-${offset}px)`;
    };

    _onFiltersChange = (requestData) => {
        if (this._request) {
            this._request.abort();
        }

        this._request = _sendRequest({
            url: _getData(this._el, 'dealsUrl'),
            data: requestData,
            success: (response) => {
                this._el.querySelector(`.js-${blockName}__list`).innerHTML = response;
                components.init();
            },
        });
    }

    _onFiltersButtonClick = () => {
        if (!this._modal) {
            this._createModal();
        }

        const filtersEl = this._el.querySelector(`.js-${blockName}__filters`);

        _toArray(filtersEl.childNodes).forEach((filtersChildEl) => {
            this._modalBodyEl.appendChild(filtersChildEl);
        });

        this._modal.open(this._modalEl);
    }

    _createModal = () => {
        const applyButtonLabel = _getData(this._el, 'filtersApplyLabel');
        const resetButtonLabel = _getData(this._el, 'filtersResetLabel');
        const applyButtonEl = _createEl('span', `btn btn_size_lg ${blockName}__modal-footer-apply`, [
            applyButtonLabel,
        ]);
        const resetButtonEl = _createEl('span', `${blockName}__modal-footer-reset`, [resetButtonLabel]);

        this._modal = new FullScreenModal({
            forMobileOnly: true,
            onClose: this._onModalClose,
        });

        this._modalBodyEl = _createEl('div', 'container');
        this._modalEl = _createEl('div', `${blockName}__modal`, [
            _createEl('div', `${blockName}__modal-body`, [
                this._modalBodyEl,
            ]),
            _createEl('div', `${blockName}__modal-footer`, [
                _createEl('div', 'container', [
                    _createEl('div', `${blockName}__modal-footer-inner`, [
                        applyButtonEl,
                        resetButtonEl,
                    ]),
                ]),
            ]),
        ]);

        _on(applyButtonEl, 'click', () => this._modal.close());
        _on(resetButtonEl, 'click', () => {
            resetFiltersToDefault();
            this._modal.close();
        });
    };

    _onModalClose = () => {
        const filtersEl = this._el.querySelector(`.js-${blockName}__filters`);

        _toArray(this._modalBodyEl.childNodes).forEach((filtersChildEl) => {
            filtersEl.appendChild(filtersChildEl);
        });
    }
}

components.add(`js-${blockName}`, el => new DealsFilteredList(el));
