import components from '../../common/_components';
import { _sendRequest, _serialize } from '../../common/_ajax';
import { _getData } from '../../common/_core';
import { _on } from '../../common/_events';
import './auth-modal-body.scss';

const blockName = 'auth-modal-body';

let closeAuthModal = () => {};
let callAuthDoneCallback = () => {};

class AuthModalBody {
    constructor(el) {
        this._el = el;
        this._formEl = el.querySelector(`.js-${blockName}__form`);

        _on(el, 'click', `.js-${blockName}__close`, this._closeClickHandler);
        _on(this._formEl, 'submit', this._onFormSubmit);
    }

    _closeClickHandler = (e) => {
        closeAuthModal();

        e.preventDefault();
    }

    _onFormSubmit = (e) => {
        const formEl = this._formEl;
        const buttonEl = formEl.querySelector(`.js-${blockName}__sign-in-button`);

        e.preventDefault();

        if (buttonEl.classList.contains('disabled')) {
            return;
        }

        buttonEl.classList.add('disabled');
        this._hideErrors();

        if (this._loginRequest) {
            this._loginRequest.abort();
        }

        this._loginRequest = _sendRequest({
            method: 'POST',
            url: formEl.action,
            dataType: 'json',
            data: _serialize(formEl),
            success: (response) => {
                if (response.success === true) {
                    callAuthDoneCallback();
                    return;
                }

                if (Array.isArray(response.errors)) {
                    this._showErrors(response.errors);
                }

                buttonEl.classList.remove('disabled');
            },
            error: (response) => {
                if (response && response.message) {
                    this._showErrors([response.message]);
                }

                buttonEl.classList.remove('disabled');
            },
        });
    }

    _hideErrors = () => {
        const errorsEl = this._formEl.querySelector(`.js-${blockName}__errors`);

        errorsEl.classList.remove('shown');
    }

    _showErrors = (errors) => {
        const placeholder = _getData(this._formEl, 'errorTemplatePlaceholder');
        const templateHTML = this._formEl.querySelector(`.js-${blockName}__error-template`).innerHTML;
        const errorsEl = this._formEl.querySelector(`.js-${blockName}__errors`);

        errorsEl.innerHTML = errors
            .map(errorMessage => templateHTML.replace(placeholder, errorMessage))
            .join('');

        errorsEl.classList.add('shown');
    }
}

components.add(`js-${blockName}`, el => new AuthModalBody(el));

function setAuthCloseAction(fn) {
    if (fn instanceof Function) {
        closeAuthModal = fn;
    }
}

function setAuthDoneAction(fn) {
    if (fn instanceof Function) {
        callAuthDoneCallback = fn;
    }
}

export { setAuthCloseAction, setAuthDoneAction };
