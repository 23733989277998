import { _on, _off } from './_events';
import { _getCSSProp, _isElementVisible } from './_core';

/**
 * @param {Object} localHeadersHeightManager
 * @return {class} StickyElement
 */
export function getConfigured(
    {
        getHeaderHeight = () => 0,
        addHeaderStateChangeHandler = () => {},
        removeHeaderStateChangeHandler = () => {},
    },
) {
    const STATE_FIXED = 'fixed';
    const STATE_ABSOLUTE = 'absolute';
    const STATE_DEFAULT = 'default';

    class StickyElement {
        constructor(stickyEl, parentEl, options) {
            this._stickyEl = stickyEl;
            this._parentEl = parentEl;
            this._options = {
                ...{
                    fixedClassName: 'fixed',
                    transitionClassName: 'transition',
                    absoluteClassName: 'absolute',
                    pushWithHeader: true,
                    pushedHeaderOffset: 0,
                    cssLeft: 'left',
                    cssRight: 'right',
                    restrictionAreaEl: null,
                    beforeStateChange: () => {},
                    afterStateChange: () => {},
                },
                ...options,
            };

            this._bindListeners();
            this._checkState();
        }

        destroy = () => {
            removeHeaderStateChangeHandler(this._onHeaderPinStateChange);

            _off(window, 'scroll', this._checkState);
            _off(window, 'resize', this._resizeHandler);

            this._resetState();

            this._parentEl = null;
            this._stickyEl = null;
            this._options = null;
            this._state = null;
        };

        update = () => this._checkState();

        _bindListeners = () => {
            _on(window, 'scroll', this._checkState);
            _on(window, 'resize', this._resizeHandler);

            if (this._options.pushWithHeader) {
                addHeaderStateChangeHandler(this._onHeaderPinStateChange);
            }
        };

        _resizeHandler = () => {
            const wasFixed = this._isFixed();

            if (this._parentEl !== null && this._state !== null) {
                this._checkState();
            }

            if (wasFixed && this._isFixed()) {
                this._setFixedDimension();
            }
        };

        _onHeaderPinStateChange = () => {
            if (this._isFixed()) {
                this._stickyEl.style.marginTop = `${this._getStickyOffset()}px`;
            }
        };

        _checkState = () => {
            if (_isElementVisible(this._parentEl)) {
                const prevState = this._state;
                const windowTop = window.pageYOffset;
                const offset = this._getStickyOffset();
                const minScrollTop = windowTop + (this._getRealStickyOffset() || offset);
                const maxScrollTop = windowTop + offset;
                const parentElRect = this._parentEl.getBoundingClientRect();
                const stickyElHeight = this._stickyEl.getBoundingClientRect().height;
                const holderTopOffset = windowTop + parentElRect.top;
                const { restrictionAreaEl } = this._options;
                const componentBottom = restrictionAreaEl
                    ? windowTop + restrictionAreaEl.getBoundingClientRect().bottom
                    : Number.POSITIVE_INFINITY;

                let relativeTopOffset;

                if (minScrollTop > holderTopOffset && maxScrollTop < componentBottom) {
                    relativeTopOffset = componentBottom - holderTopOffset - stickyElHeight;

                    if (componentBottom - maxScrollTop > stickyElHeight) {
                        this._state = STATE_FIXED;
                    } else if (Math.round(relativeTopOffset) > 0) {
                        this._state = STATE_ABSOLUTE;
                    } else {
                        this._state = STATE_DEFAULT;
                    }
                } else {
                    this._state = STATE_DEFAULT;
                }

                if (prevState !== this._state || this._needUpdateState(relativeTopOffset)) {
                    this._options.beforeStateChange(this._state, prevState);

                    if (this._isFixed()) {
                        this._setFixed();
                    } else if (this._isAbsolute()) {
                        this._setAbsolute(relativeTopOffset);
                    } else {
                        this._resetState();
                    }

                    this._options.afterStateChange(this._state, prevState);
                } else if ((this._isFixed() || this._isAbsolute()) && parentElRect.height !== stickyElHeight) {
                    this._setParentHeight(stickyElHeight);
                }
            }
        };

        _getRealStickyOffset = () => parseInt(_getCSSProp(this._stickyEl, 'marginTop'), 10);

        _isFixed = () => this._state === STATE_FIXED;

        _isAbsolute = () => this._state === STATE_ABSOLUTE;

        _needUpdateState = relativeTopOffset => (this._isAbsolute()
            && relativeTopOffset !== parseInt(_getCSSProp(this._stickyEl, 'top'), 10));

        _getStickyOffset = () => {
            if (this._options.pushWithHeader) {
                const offset = getHeaderHeight();

                return offset > 0 ? offset + this._options.pushedHeaderOffset : 0;
            }

            return 0;
        };

        _setParentHeight = (height) => {
            this._parentEl.style.height = height ? `${height}px` : '';
        };

        _setFixed = () => {
            const parentClassList = this._parentEl.classList;
            const stickyElStyle = this._stickyEl.style;

            this._setParentHeight(this._stickyEl.getBoundingClientRect().height);

            parentClassList.remove(this._options.absoluteClassName);
            parentClassList.add(this._options.fixedClassName);

            stickyElStyle.top = '';
            stickyElStyle.marginTop = `${this._getStickyOffset()}px`;

            this._setFixedDimension();

            requestAnimationFrame(() => {
                if (this._isFixed()) {
                    parentClassList.add(this._options.transitionClassName);
                }
            });
        };

        _setFixedDimension = () => {
            const windowWidth = document.documentElement.clientWidth;
            const parentRect = this._parentEl.getBoundingClientRect();

            this._stickyEl.style[this._options.cssLeft] = `${parentRect.left}px`;
            this._stickyEl.style[this._options.cssRight] = `${windowWidth - parentRect.width - parentRect.left}px`;
        };

        _setAbsolute = (top) => {
            const parentClassList = this._parentEl.classList;
            const stickyElStyle = this._stickyEl.style;

            this._setParentHeight(this._stickyEl.getBoundingClientRect().height);

            parentClassList.remove(this._options.fixedClassName);
            parentClassList.remove(this._options.transitionClassName);
            parentClassList.add(this._options.absoluteClassName);

            stickyElStyle.top = `${top}px`;
            stickyElStyle[this._options.cssRight] = '';
            stickyElStyle[this._options.cssLeft] = '';
            stickyElStyle.marginTop = '';
        };

        _resetState = () => {
            const parentClassList = this._parentEl.classList;
            const stickyElStyle = this._stickyEl.style;

            parentClassList.remove(this._options.transitionClassName);

            stickyElStyle.top = '';
            stickyElStyle[this._options.cssRight] = '';
            stickyElStyle[this._options.cssLeft] = '';
            stickyElStyle.marginTop = '';

            this._setParentHeight(null);
            parentClassList.remove(this._options.absoluteClassName);
            parentClassList.remove(this._options.fixedClassName);
        };

        static installTo(stickyEl, parentEl, options) {
            return new StickyElement(stickyEl, parentEl, options);
        }

        static STATE_FIXED = STATE_FIXED;

        static STATE_ABSOLUTE = STATE_ABSOLUTE;

        static STATE_DEFAULT = STATE_DEFAULT;
    }

    return StickyElement;
}
