import components from '../../common/_components';
import { _getData, _setData } from '../../common/_core';
import { _isElement } from '../../common/_types';
import { _on, _trigger } from '../../common/_events';
import { throttle } from '../../vendors.theme/_throttle-debounce';
import './ads-banner.scss';

window.asc = window.asc || {};
window.asc.cmd = window.asc.cmd || [];

const GRID_BREAKPOINTS = [
    [992, 'desktop'],
    [768, 'tablet'],
    [0, 'mobile'],
];

const ascObject = window.asc;
const bannersAtPage = [];
const bannerIdsCache = {};
const blockName = 'ads-banner';

function parseJSON(data, fallback) {
    let result = fallback;

    try {
        result = JSON.parse(data);
    } catch (e) {}

    return result;
}

function cacheID(id, data) {
    const key = `${data.slotName}|${data.sizes}`;

    if (!bannerIdsCache[key]) {
        bannerIdsCache[key] = [];
    }

    if (bannerIdsCache[key].indexOf(id) === -1) {
        bannerIdsCache[key].push(id);
    }
}

function getCachedId(data) {
    const key = `${data.slotName}|${data.sizes}`;

    if (bannerIdsCache[key] && bannerIdsCache[key].length > 0) {
        return bannerIdsCache[key].shift();
    }

    return null;
}

let devicesList;

function getDevicesList() {
    if (!devicesList) {
        devicesList = ascObject.getWrapperDeviceDetection().map(device => device.name);
    }

    return devicesList;
}

function getCurrentDevice() {
    const windowWidth = document.documentElement.clientWidth;

    for (let i = 0; i < GRID_BREAKPOINTS.length; i += 1) {
        if (windowWidth >= GRID_BREAKPOINTS[i][0]) {
            return GRID_BREAKPOINTS[i][1];
        }
    }

    return GRID_BREAKPOINTS[GRID_BREAKPOINTS.length - 1][1];
}

function getSlotSizes(slotData) {
    if (slotData.mediaTypes && slotData.mediaTypes.banner && Array.isArray(slotData.mediaTypes.banner.sizes)) {
        return slotData.mediaTypes.banner.sizes;
    }

    return [];
}

function getBannerData(bannerEl, allSlots, currentDevice) {
    const requiredSlots = parseJSON(_getData(bannerEl, 'adsSlots'), []);
    const maxHeight = parseFloat(_getData(bannerEl, 'adsMaxHeight')) || Infinity;
    const parentWidth = bannerEl.parentNode.getBoundingClientRect().width;
    const suitableSlots = {};

    let hasSuitableForDevice = false;

    allSlots.forEach((slotData) => {
        if (requiredSlots.indexOf(slotData.adunit) > -1) {
            const suitableSizes = getSlotSizes(slotData)
                .filter(([width, height]) => parseFloat(width) <= parentWidth && parseFloat(height) <= maxHeight);

            if (suitableSizes.length) {
                const isSuitableForDevice = (slotData.device || []).indexOf(currentDevice) > -1;

                suitableSlots[slotData.adunit] = {
                    sizes: JSON.stringify(suitableSizes),
                    isSuitableForDevice,
                };

                if (!hasSuitableForDevice) {
                    hasSuitableForDevice = isSuitableForDevice;
                }
            }
        }
    });

    for (let i = 0; i < requiredSlots.length; i += 1) {
        if (suitableSlots[requiredSlots[i]]) {
            const { sizes, isSuitableForDevice } = suitableSlots[requiredSlots[i]];

            if (!hasSuitableForDevice || isSuitableForDevice) {
                return {
                    slotName: requiredSlots[i],
                    sizes,
                };
            }
        }
    }

    return {};
}

function updateBanners(bannerEls) {
    const allSlots = ascObject.getWrapperInternalAdunits();
    const currentDevice = getCurrentDevice();
    const listToDisable = [];
    const listToRefresh = [];

    bannerEls.forEach((el) => {
        const data = getBannerData(el, allSlots, currentDevice);
        const { slotName, sizes } = data;

        if (_getData(el, 'aaad') === 'true') {
            if (_getData(el, 'aaAdunit') !== slotName || _getData(el, 'aaSizes') !== sizes) {
                listToDisable.push(el);

                if (slotName) {
                    listToRefresh.push([el, data]);
                }
            }
        } else if (slotName) {
            listToRefresh.push([el, data]);
        }
    });

    if (listToDisable.length) {
        ascObject.cmd.push(() => ascObject.destroy(listToDisable.map((el) => {
            const id = el.getAttribute('id');

            if (id) {
                cacheID(id, {
                    slotName: _getData(el, 'aaAdunit'),
                    sizes: _getData(el, 'aaSizes'),
                });

                el.removeAttribute('id');
            }

            return el;
        })));
    }

    if (listToRefresh.length) {
        ascObject.cmd.push(() => {
            ascObject.refresh(listToRefresh.map(([el, data]) => {
                const id = getCachedId(data);

                if (id) {
                    el.setAttribute('id', id);
                }

                _setData(el, 'aaAdunit', data.slotName);
                _setData(el, 'aaSizes', data.sizes);
                _setData(el, 'aaDevice', JSON.stringify(getDevicesList()));
                _setData(el, 'aaad', 'true');

                return el;
            }));
        });
    }
}

function resizeHandler() {
    bannersAtPage.slice().forEach((el, i) => {
        if (!_isElement(el) || !_isElement(el.parentNode)) {
            bannersAtPage.splice(i, 1);
        }
    });

    updateBanners(bannersAtPage);
}

function onRenderSucceeded(event) {
    const data = {};
    let bannerEl;

    if (event.bid && event.bid.adUnitCode) {
        data.slotId = event.bid.adUnitCode;

        try {
            bannerEl = document.getElementById(data.slotId);
        } catch (e) {}
    }

    _trigger(bannerEl || document, '_ads-banner-loaded', data);
}

let isInited = false;
let bannersToInit = [];

components.add(`js-${blockName}`, (el, api) => {
    bannersAtPage.push(el);
    bannersToInit.push(el);

    if (bannersToInit.length === 1) {
        api.afterAll(() => {
            ascObject.cmd.push(() => {
                updateBanners(bannersToInit);
                bannersToInit = [];

                if (!isInited) {
                    ascObject.onEvent('adRenderSucceeded', onRenderSucceeded);
                    _on(window, 'resize', throttle(200, resizeHandler));

                    isInited = true;
                }
            });
        });
    }
});
