import bugsnagClient from '../../common/_bugsnag-client';
import components from '../../common/_components';
import { _getData, _isElementVisible, _toArray } from '../../common/_core';
import { _on, _off } from '../../common/_events';
import Overlay from '../overlay/overlay';
import { Filter } from '../shops-offers-list/__filter';
import { OffersList } from '../shops-offers-list/shops-offers-list';

import './shops-filters-panel.scss';

const blockName = 'shops-filters-panel';
const openedMod = 'opened';
const filterSelectedMod = 'selected';
const applyDisabledMod = 'disabled';

let shouldBeOpened = false;
let initialFilter;
let offersList;
let instance;

class ShopsFiltersPanel {
    constructor(el) {
        this._el = el;
        this._filtersEl = _toArray(el.querySelectorAll(`.js-${blockName}__filter`));
        this._applyButtonEl = el.querySelector(`.js-${blockName}__apply`);

        this._isOpened = false;
        this._overlay = new Overlay({ forMobileOnly: true });

        this._filter = initialFilter || new Filter();
        this._offersListFilter = this._filter;

        this._updateView();

        _on(el, 'click', `.js-${blockName}__filter`, this._onFilterClick);
        _on(this._applyButtonEl, 'click', this._onApplyClick);

        instance = this;
        this.updateVisibilityState();
    }

    updateVisibilityState = () => {
        if (shouldBeOpened && !this._isOpened) {
            _on(document, this._overlay.getCloseEvent(), this._close, { once: true });
            _on(this._el, 'click', `.js-${blockName}__close`, this._close);

            this._overlay.show();
            this._el.classList.add(openedMod);

            this._isOpened = true;
        } else if (!shouldBeOpened && this._isOpened) {
            _off(document, this._overlay.getCloseEvent(), this._close);
            _off(this._el, 'click', `.js-${blockName}__close`, this._close);

            this._overlay.hide();
            this._el.classList.remove(openedMod);

            this._isOpened = false;
        }
    };

    resetFilters = () => {
        this._filter = new Filter();

        this._updateView();
        this._applyChanges();
    };

    _close = () => {
        shouldBeOpened = false;

        this.updateVisibilityState();
        this._resetChanges();
    };

    _onFilterClick = (e) => {
        const filterName = _getData(e._caughtTarget_, 'filterName');
        const filterValue = _getData(e._caughtTarget_, 'filterValue');

        this._filter = this._filter.toggle(filterName, filterValue);
        this._updateView();

        if (!_isElementVisible(this._applyButtonEl)) {
            this._applyChanges();
        }
    };

    _onApplyClick = () => {
        if (!this._applyButtonEl.classList.contains(applyDisabledMod)) {
            this._applyChanges();
            this._updateControls();
            this._close();
        }
    };

    _updateView = () => {
        this._filtersEl.forEach((filterEl) => {
            const filterName = _getData(filterEl, 'filterName');
            const filterValue = _getData(filterEl, 'filterValue');
            const isEnabled = this._filter.isFilterEnabled(filterName, filterValue);

            filterEl.classList[isEnabled ? 'add' : 'remove'](filterSelectedMod);
        });

        this._updateControls();
    };

    _updateControls = () => {
        const hasNoChanges = this._offersListFilter.isEqual(this._filter);
        this._applyButtonEl.classList[hasNoChanges ? 'add' : 'remove'](applyDisabledMod);
    };

    _applyChanges = () => {
        offersList.setFilter(this._filter);
        this._filter.toUrlData();

        this._offersListFilter = this._filter;
    };

    _resetChanges = () => {
        this._filter = this._offersListFilter;
        this._updateView();
    };
}

components.add(`js-${blockName}`, element => new ShopsFiltersPanel(element));

export default {
    open: () => {
        shouldBeOpened = true;

        if (instance) {
            instance.updateVisibilityState();
        }
    },
    setInitialFilter: (filter) => {
        if (filter instanceof Filter) {
            initialFilter = filter;
        } else {
            const viewName = filter.constructor ? filter.constructor.name : filter;
            bugsnagClient.notify(`${viewName} is non instance of Filter`);
        }
    },
    setOffersList: (offersListInstance) => {
        if (offersListInstance instanceof OffersList) {
            offersList = offersListInstance;

            offersList.onResetClick(() => {
                if (instance) {
                    instance.resetFilters();
                } else {
                    initialFilter = new Filter();
                }
            });
        } else {
            const viewName = offersListInstance.constructor ? offersListInstance.constructor.name : offersListInstance;
            bugsnagClient.notify(`${viewName} is non instance of OffersList`);
        }
    },
};
