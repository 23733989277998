import components from '../../common/_components';
import ScrollHorizontal from '../../common/_scroll-horizontal';

import './teaser-content-landing-page.scss';

const blockName = 'teaser-content-landing-page';
const interval = 3500;

class TeaserContentLandingPageShops {
    constructor(rootEl) {
        this._rootEl = rootEl;

        this._scroll = new ScrollHorizontal(rootEl, `.js-${blockName}__shops-list-item`, {
            listEl: rootEl.querySelector(`.js-${blockName}__shops-list`),
            isInfinity: true,
            onDragStart: this.dragStartHandler,
            onSlideChangeDone: this.slideChangeDoneHandler,
        });

        if (rootEl.getBoundingClientRect().width < rootEl.scrollWidth) {
            this._timeout = setTimeout(this.scrollLeft, interval);
        }
    }

    dragStartHandler = () => {
        clearTimeout(this._timeout);
    };

    slideChangeDoneHandler = () => {
        if (this._rootEl.getBoundingClientRect().width < this._rootEl.scrollWidth) {
            this._timeout = setTimeout(this.scrollLeft, interval);
        }
    };

    scrollLeft = () => {
        this._scroll.moveRight();
    };
}

components.add(`js-${blockName}__shops`, el => new TeaserContentLandingPageShops(el));
