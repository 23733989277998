import Cookies from 'js-cookie';
import { _isString, _isNumber, _isPlainObject, _isNil, _isFunction } from './_types';
import { _forIn, _toArray } from './_core';

const bracketRe = /\[]$/;
const METHOD_GET = 'GET';
const VALID_METHODS = [METHOD_GET, 'POST'];
const HAS_CONTENT_METHODS = ['POST'];
const ACCEPT_ALL_HEADER = '*/*';
const ACCEPT_HEADER = {
    '*': ACCEPT_ALL_HEADER,
    'text': 'text/plain',
    'html': 'text/html',
    'json': 'application/json, text/javascript',
};

function parseQueryString(queryString) {
    const results = {};
    const decodeString = decodeURIComponent;
    const plusRe = /\+/g;

    if (!_isString(queryString) || queryString.length === 0) {
        return results;
    }

    queryString
        .split('&')
        .forEach((rawPair) => {
            const pair = rawPair.replace(plusRe, '%20');
            const indexOfEqualSign = pair.indexOf('=');

            let rawKey;
            let rawValue;

            if (indexOfEqualSign >= 0) {
                rawKey = pair.substr(0, indexOfEqualSign);
                rawValue = pair.substr(indexOfEqualSign + 1);
            } else {
                rawKey = pair;
                rawValue = '';
            }

            const key = decodeString(rawKey).replace(bracketRe, '');
            const value = decodeString(rawValue);

            if (!Object.prototype.hasOwnProperty.call(results, key)) {
                results[key] = value;
            } else if (Array.isArray(results[key])) {
                results[key].push(value);
            } else {
                results[key] = [results[key], value];
            }
        });

    return results;
}

function _buildParams(prefix, data, add) {
    if (Array.isArray(data)) {
        data.forEach((value, i) => {
            if (bracketRe.test(prefix)) {
                add(prefix, value);
            } else {
                _buildParams(`${prefix}[${_isPlainObject(value) ? i : ''}]`, value, add);
            }
        });
    } else if (_isPlainObject(data)) {
        Object
            .keys(data)
            .sort()
            .forEach(key => _buildParams(`${prefix}[${key}]`, data[key], add));
    } else {
        add(prefix, data);
    }
}

function stringify(data) {
    const s = [];
    const add = (rawKey, valueOrFunction) => {
        const rawValue = _isFunction(valueOrFunction) ? valueOrFunction() : valueOrFunction;
        const key = encodeURIComponent(rawKey);

        if (rawValue === '') {
            s.push([key]);
        } else if (!_isNil(rawValue)) {
            s.push([key, encodeURIComponent(rawValue)]);
        }
    };

    if (_isNil(data)) {
        return '';
    }

    _forIn(data, (value, key) => _buildParams(key, value, add));

    return s
        .sort((leftChunk, rightChunk) => {
            if (leftChunk[0] === rightChunk[0]) {
                return 0;
            }

            return leftChunk[0] > rightChunk[0] ? 1 : -1;
        })
        .map(chunk => chunk.join('='))
        .join('&');
}

function serialize(formEl) {
    const result = {};
    const fieldRe = /^(?:input|select|textarea)$/i;
    const typeRe = /^(?:submit|button|image|reset|file)$/i;
    const checkboxRe = /^(?:checkbox|radio)$/i;
    const CRLFre = /\r?\n/g;
    const elements = formEl.tagName.toLowerCase() === 'form'
        ? formEl.elements
        : formEl.querySelectorAll('input, select, textarea');

    _toArray(elements).forEach((field) => {
        const fieldName = field.name;
        const fieldType = field.type;
        const isValidCheckbox = !checkboxRe.test(fieldType) || field.checked;
        const value = field.value.replace(CRLFre, '\r\n');

        if (fieldName && !field.disabled && fieldRe.test(field.nodeName)
            && !typeRe.test(fieldType) && isValidCheckbox
            && !_isNil(value)) {
            if (result[fieldName] !== undefined) {
                if (!Array.isArray(result[fieldName])) {
                    result[fieldName] = [result[fieldName]];
                }

                result[fieldName].push(value);
            } else {
                result[fieldName] = value;
            }
        }
    });

    return result;
}

function isSameOriginUrl(url) {
    const linkEl = document.createElement('a');

    linkEl.href = url;

    return linkEl.protocol === document.location.protocol && linkEl.host === document.location.host;
}

function send(options = {}) {
    const method = options.method ? options.method.toUpperCase() : METHOD_GET;
    const callbacks = {};
    const {
        url = document.location.href,
        dataType = '*',
        contentType = 'application/x-www-form-urlencoded; charset=UTF-8',
        data = {},
        headers = {},
    } = options;

    if (!_isString(url) || VALID_METHODS.indexOf(method) === -1 || !_isString(contentType) || !_isString(dataType)) {
        throw new Error('Invalid parameters');
    }

    const hasContent = (HAS_CONTENT_METHODS.indexOf(method) > -1);
    const isFormData = data instanceof window.FormData;
    const dataTypes = dataType.split(/\s+/);
    const xhr = new window.XMLHttpRequest();

    let dataString = _isString(data) || (hasContent && isFormData) ? data : stringify(data);
    let xhrUrl = url.replace(/#.*$/, '');
    let hasFinished = false;

    ['success', 'error', 'abort', 'always'].forEach((propName) => {
        callbacks[propName] = options[propName] ? [options[propName]] : [];
    });

    if (dataString && !isFormData) {
        if (hasContent) {
            if ((contentType || '').indexOf('application/x-www-form-urlencoded') === 0) {
                dataString = dataString.replace(/%20/g, '+');
            }

            if (contentType) {
                headers['Content-Type'] = contentType;
            }
        } else {
            xhrUrl += (/\?/.test(xhrUrl) ? '&' : '?') + dataString;
        }
    }

    if (ACCEPT_HEADER[dataTypes[0]]) {
        /* eslint-disable-next-line dot-notation */
        headers['Accept'] = dataTypes[0] === '*'
            ? ACCEPT_HEADER[dataTypes[0]]
            : `${ACCEPT_HEADER[dataTypes[0]]}, ${ACCEPT_ALL_HEADER}; q=0.01`;
    } else {
        headers['Accept'] = ACCEPT_HEADER['*']; // eslint-disable-line dot-notation
    }

    if (isSameOriginUrl(xhrUrl)) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
        headers['X-CSRF-Token'] = Cookies.get('csrfToken');
    }

    xhr.open(method, xhrUrl, true);
    _forIn(headers, (value, name) => xhr.setRequestHeader(name, value));

    function finish() {
        xhr.onload = xhr.onerror = xhr.onabort = xhr.ontimeout = xhr.onreadystatechange = null;
        hasFinished = true;

        const status = _isNumber(xhr.status) ? xhr.status : 0;
        const response = _isString(xhr.responseText) ? xhr.responseText : xhr.response;
        const isSuccess = (status >= 200 && status < 300) || status === 304;

        let responseJSON;

        try {
            responseJSON = JSON.parse(response);
        } catch (e) {}

        if (isSuccess) {
            callbacks.success.forEach(cb => cb(responseJSON || response, status, xhr));
        } else {
            callbacks.error.forEach(cb => cb(responseJSON || response, status, xhr));
        }

        callbacks.always.forEach(cb => cb(isSuccess, responseJSON || response, status, xhr));
    }

    function onabort() {
        xhr.onload = xhr.onerror = xhr.onabort = xhr.ontimeout = xhr.onreadystatechange = null;
        hasFinished = true;

        callbacks.abort.forEach(cb => cb());
        callbacks.always.forEach(cb => cb(false, '', 0, xhr));
    }

    xhr.onload = finish;
    xhr.onerror = xhr.ontimeout = finish;

    if (xhr.onabort !== undefined) {
        xhr.onabort = onabort;
    } else {
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
                setTimeout(() => {
                    if (!hasFinished) {
                        finish();
                    }
                });
            }
        };
    }

    try {
        xhr.send(hasContent ? dataString : null);
    } catch (e) {
        if (!hasFinished) {
            throw e;
        }
    }

    const api = {
        abort: () => {
            if (!hasFinished) {
                onabort();
                xhr.abort();
            }

            return api;
        },
        done: (cb) => {
            callbacks.success.push(cb);
            return api;
        },
        fail: (cb) => {
            callbacks.error.push(cb);
            return api;
        },
        always: (cb) => {
            callbacks.always.push(cb);
            return api;
        },
    };

    return api;
}

export {
    send as _sendRequest,
    serialize as _serialize,
    stringify as _stringify,
    parseQueryString as _parseQueryString,
};
