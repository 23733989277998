const MAX_SAFE_INTEGER = 9007199254740991;
const objectToString = Object.prototype.toString;

function isNil(value) {
    return value === null || value === undefined;
}

function isBoolean(value) {
    return value === true || value === false || objectToString.call(value) === '[object Boolean]';
}

function isString(value) {
    return objectToString.call(value) === '[object String]';
}

function isNumber(value) {
    // eslint-disable-next-line no-restricted-globals
    return objectToString.call(value) === '[object Number]' && isFinite(value);
}

function isLength(value) {
    return isNumber(value) && (value % 1 === 0) && value > -1 && value <= MAX_SAFE_INTEGER;
}

function isArrayLike(value) {
    return !isNil(value) && typeof value !== 'function' && !isString(value) && isLength(value.length);
}

function isPlainObject(value) {
    if (isNil(value) || typeof value !== 'object' || objectToString.call(value) !== '[object Object]') {
        return false;
    }

    if (Object.getPrototypeOf(value) === null) {
        return true;
    }

    let proto = value;
    while (Object.getPrototypeOf(proto) !== null) {
        proto = Object.getPrototypeOf(proto);
    }

    return Object.getPrototypeOf(value) === proto;
}

function isFunction(value) {
    const type = typeof value;
    return !isNil(value) && (type === 'function' || type === 'object')
        && objectToString.call(value) === '[object Function]';
}

function isElement(value) {
    return !isNil(value) && typeof value === 'object' && value.nodeType === 1 && !isPlainObject(value);
}

export {
    isNil as _isNil,
    isBoolean as _isBoolean,
    isString as _isString,
    isNumber as _isNumber,
    isArrayLike as _isArrayLike,
    isPlainObject as _isPlainObject,
    isFunction as _isFunction,
    isElement as _isElement,
};
