const STATE_ACTIVATED = 'activated';
const STATE_DEACTIVATED = 'deactivated';

const stateChangeHandlers = [];
const panels = [];

let activePanel;

/**
 * @typedef HeadersHeightManager
 * @instance
 */
const HeadersHeightManager = {
    STATE_ACTIVATED,
    STATE_DEACTIVATED,

    _resizeHandler: () => {
        const windowWidth = document.documentElement.clientWidth;

        let currentPanel;

        for (let i = 0; i < panels.length; i += 1) {
            const panel = panels[i];

            if (windowWidth >= panel.minWidth && windowWidth <= panel.maxWidth) {
                currentPanel = panel;
                break;
            }
        }

        if (activePanel && currentPanel !== activePanel) {
            HeadersHeightManager.dispatchUpdate(STATE_DEACTIVATED);
            activePanel = null;
        }

        if (!activePanel && currentPanel) {
            activePanel = currentPanel;
            HeadersHeightManager.dispatchUpdate(STATE_ACTIVATED);
        }
    },

    setupHeader: (rootEl, minWidth, maxWidth, getHeight) => {
        panels.push({
            rootEl,
            minWidth,
            maxWidth,
            getHeight,
        });

        HeadersHeightManager._resizeHandler();

        if (panels.length === 1) {
            window.addEventListener('resize', HeadersHeightManager._resizeHandler);
        }
    },

    removeHeader: (rootEl) => {
        for (let i = 0; i < panels.length; i += 1) {
            const panel = panels[i];

            if (panel.rootEl === rootEl) {
                panels.splice(i, 1);
                break;
            }
        }

        if (panels.length === 0) {
            window.removeEventListener('resize', HeadersHeightManager._resizeHandler);
        }
    },

    getHeight: () => {
        if (!activePanel) {
            return 0;
        }

        if (typeof activePanel.getHeight === 'function') {
            return activePanel.getHeight(activePanel);
        }

        return activePanel.rootEl.getBoundingClientRect().height;
    },

    addStateChangeHandler: (fn) => {
        stateChangeHandlers.push(fn);
    },

    removeStateChangeHandler: (fn) => {
        for (let i = 0; i < stateChangeHandlers.length; i += 1) {
            if (stateChangeHandlers[i] === fn) {
                stateChangeHandlers.splice(i, 1);
                break;
            }
        }
    },

    dispatchUpdate: (state) => {
        stateChangeHandlers.forEach(cb => cb(activePanel, state));
    },
};

export default HeadersHeightManager;
