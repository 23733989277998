import '../promo-telegram/promo-telegram';

import '../promo-countdown/promo-countdown';
import '../promo-black-friday/promo-black-friday';
import '../promo-cyber-monday/promo-cyber-monday';
import '../promo-singles-day/promo-singles-day';
import '../promo-christmas/promo-christmas';
// import '../promo-prime-day/promo-prime-day.scss';

import '../promo-landing-page-banner/promo-landing-page-banner.scss';
// import '../promo-campaign-app/promo-campaign-app.scss';
