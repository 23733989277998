import components from '../../common/_components';
import HeadersHeightManager from '../../common/_headers-height-manager';
import { _on, _off, _onTransitionEnd, _trigger } from '../../common/_events';
import { _closest, _getCSSProp, _getData, _reflow, _toArray } from '../../common/_core';
import { lockPageScrolling, unlockPageScrolling } from '../../common/_scroll-page-lock';
import Overlay from '../../components.theme/overlay/overlay';
import './layout-menu.scss';

const blockName = 'layout-menu';
const openedMod = `${blockName}_opened`;
const closingMod = `${blockName}_closing`;

const navigationItemSelector = `.js-${blockName}__navigation-li`;
const navigationItemExpandedMod = `${blockName}__navigation-li_expanded`;
const navigationItemAnimatingMod = `${blockName}__navigation-li_animating`;

const HANDLERS_MODE_DESKTOP = 'desktop';
const HANDLERS_MODE_MOBILE = 'mobile';

class LayoutMenu {
    constructor(el) {
        this._el = el;
        this._fixedEl = el.querySelector(`.js-${blockName}__fixed`);
        this._bodyEl = el.querySelector(`.js-${blockName}__body`);
        this._mobileWidthLimit = parseFloat(_getData(el, 'mobileWidthLimit'));

        this._updateHandlers();
        HeadersHeightManager.addStateChangeHandler(this._updateHandlers);
    }

    _updateHandlers = () => {
        const prevHandlersMode = this._handlersMode;
        const handlersMode = document.body.clientWidth < this._mobileWidthLimit
            ? HANDLERS_MODE_MOBILE : HANDLERS_MODE_DESKTOP;

        if (prevHandlersMode !== handlersMode) {
            if (prevHandlersMode === HANDLERS_MODE_MOBILE) {
                _off(document, 'menu-mobile_opened', this._openMenu);

                _off(this._el, 'click', `.js-${blockName}__heading-close`, this._closeClickHandler);
                _off(this._el, 'click', `.js-${blockName}__navigation-aside`, this._arrowTouchendHandler);

                this._closeMenu();
                this._closeNavigationItems();
            } else if (prevHandlersMode === HANDLERS_MODE_DESKTOP) {
                _off(this._el, 'pointerover', navigationItemSelector, this._itemMouseoverHandler);
                this._closeNavigationItems();
            }

            if (handlersMode === HANDLERS_MODE_MOBILE) {
                const options = { passive: true };

                _on(document, 'menu-mobile_opened', this._openMenu);

                _on(this._el, 'click', `.js-${blockName}__heading-close`, this._closeClickHandler, options);
                _on(this._el, 'click', `.js-${blockName}__navigation-aside`, this._arrowTouchendHandler, options);
            } else if (handlersMode === HANDLERS_MODE_DESKTOP) {
                _on(this._el, 'pointerover', navigationItemSelector, this._itemMouseoverHandler);
            }

            this._handlersMode = handlersMode;
        }
    };

    _openMenu = () => {
        if (!this._overlay) {
            this._overlay = new Overlay({
                forMobileOnly: true,
            });
        }

        this._el.classList.add(openedMod);
        this._overlay.show();

        lockPageScrolling(this._bodyEl);
    };

    _closeMenu = () => {
        this._el.classList.remove(openedMod);
        unlockPageScrolling(this._bodyEl);
    };

    _closeClickHandler = () => {
        this._el.classList.add(closingMod);
        _onTransitionEnd(this._fixedEl, this._closeTransitionEnd);

        this._closeMenu();
    };

    _closeTransitionEnd = () => {
        this._el.classList.remove(closingMod);
        this._overlay.hide();
    }

    _itemMouseoverHandler = (e) => {
        const navigationItemEl = e._caughtTarget_;

        if (!navigationItemEl.classList.contains(navigationItemExpandedMod)) {
            this._closeNavigationItems();

            navigationItemEl.classList.add(navigationItemExpandedMod);
            _on(navigationItemEl, 'mouseout', this._navigationItemMouseoutHandler);

            _trigger(document, 'layout-menu_item-expanded');
        }
    }

    _navigationItemMouseoutHandler = (e) => {
        const rTarget = e.relatedTarget;
        const navigationItemEl = e.currentTarget;

        if (!rTarget || (rTarget !== navigationItemEl && !navigationItemEl.contains(rTarget))) {
            _on(navigationItemEl, 'mouseover', this._clearCloseTimeout, { once: true });

            this._closeTimeout = setTimeout(() => {
                _off(navigationItemEl, 'mouseover', this._clearCloseTimeout);
                this._closeNavigationItem(navigationItemEl);
            }, 200);
        }
    }

    _clearCloseTimeout = () => {
        clearTimeout(this._closeTimeout);
        this._closeTimeout = null;
    };

    _closeNavigationItems = () => {
        const openedListItemsSelector = `${navigationItemSelector}.${navigationItemExpandedMod}`;
        const openedListItems = _toArray(this._el.querySelectorAll(openedListItemsSelector));

        openedListItems.forEach(this._closeNavigationItem);
    }

    _closeNavigationItem = (navigationItemEl) => {
        _off(navigationItemEl, 'mouseover', this._clearCloseTimeout);
        _off(navigationItemEl, 'mouseout', this._navigationItemMouseoutHandler);
        navigationItemEl.classList.remove(navigationItemExpandedMod);
    }

    _arrowTouchendHandler = (e) => {
        const navigationItemEl = _closest(e._caughtTarget_, navigationItemSelector);
        const navigationDropdownEl = navigationItemEl.querySelector(`.js-${blockName}__dropdown`);

        if (navigationItemEl.classList.contains(navigationItemExpandedMod)) {
            navigationDropdownEl.style.height = _getCSSProp(navigationDropdownEl, 'height');

            navigationItemEl.classList.add(navigationItemAnimatingMod);
            navigationItemEl.classList.remove(navigationItemExpandedMod);

            _reflow(navigationDropdownEl);
            navigationDropdownEl.style.height = '0px';
        } else {
            navigationDropdownEl.style.height = '0px';

            navigationItemEl.classList.add(navigationItemAnimatingMod);
            navigationItemEl.classList.add(navigationItemExpandedMod);

            _reflow(navigationDropdownEl);
            navigationDropdownEl.style.height = `${navigationDropdownEl.scrollHeight}px`;
        }

        _onTransitionEnd(navigationDropdownEl, this._dropdownTransitionEndHandler);
    };

    _dropdownTransitionEndHandler = (e) => {
        const navigationDropdownEl = e.target;
        const navigationItemEl = _closest(navigationDropdownEl, navigationItemSelector);

        navigationItemEl.classList.remove(navigationItemAnimatingMod);
        navigationDropdownEl.style.height = '';
    }
}

components.add(`js-${blockName}`, element => new LayoutMenu(element));
