import components from '../../common/_components';
import { _getData, _toArray } from '../../common/_core';
import { _on } from '../../common/_events';
import ScrollHorizontal from '../../common/_scroll-horizontal';
import { Dots } from '../dots/dots';

import './placements-slideshow.scss';

const interval = 3000;
const blockName = 'placements-slideshow';
const listSelector = `.js-${blockName}__slides-list`;
const listItemSelector = `.js-${blockName}__slide`;
const dotsSelector = `.js-${blockName}__dots`;
const navigationButtonSelector = `.js-${blockName}__nav-button`;

class PlacementsSlideshow {
    constructor(el) {
        this._rootEl = el;
        this._slides = el.querySelectorAll(listItemSelector);
        this._isMouseOver = false;

        if (this._slides.length > 1) {
            this._scroll = new ScrollHorizontal(el, el.querySelector(listSelector), listItemSelector, {
                onDragStart: this._stopCarousel,
                onSlideChange: this._slideChangeHandler,
                onSlideChangeDone: this._slideChangeDoneHandler,
            });

            this._buildDots(el.querySelector(dotsSelector));

            _on(el, 'mouseover', this._mouseOverHandler);
            _on(el, 'mouseout', this._mouseOutHandler);
            _on(el, 'click', navigationButtonSelector, this._onNavigationButtonClick, { passive: true });

            this._startCarousel();
        }
    }

    _slideChangeHandler = (currentSlideEl) => {
        if (this._dots) {
            this._dots.select(ScrollHorizontal.getSlideId(currentSlideEl));
        }
    };

    _slideChangeDoneHandler = () => {
        if (!this._isMouseOver) {
            this._startCarousel();
        }
    };

    _mouseOverHandler = (e) => {
        const rootEl = this._rootEl;
        const relatedTargetEl = e.relatedTarget;

        if (!relatedTargetEl || (relatedTargetEl !== rootEl && !rootEl.contains(relatedTargetEl))) {
            this._isMouseOver = true;
            this._stopCarousel();
        }
    };

    _mouseOutHandler = (e) => {
        const rootEl = this._rootEl;
        const relatedTargetEl = e.relatedTarget;

        if (!relatedTargetEl || (relatedTargetEl !== rootEl && !rootEl.contains(relatedTargetEl))) {
            this._isMouseOver = false;
            this._startCarousel();
        }
    };

    _onNavigationButtonClick = (e) => {
        const arrowEl = e._caughtTarget_;
        const direction = _getData(arrowEl, 'direction');

        if (direction === 'prev') {
            this._scroll.moveLeft();
        } else if (direction === 'next') {
            this._scroll.moveRight();
        }
    };

    _buildDots = (dotsEl) => {
        const currentSlideId = this._scroll.getCurrentSlideId();

        this._dots = new Dots(dotsEl, this._onDotActive);

        _toArray(this._slides).forEach((slideEl) => {
            const slideId = ScrollHorizontal.getSlideId(slideEl);
            this._dots.addDot(slideId, slideEl.getAttribute('title'), slideId === currentSlideId);
        });

        this._dots.build();
    };

    _onDotActive = (id) => {
        this._scroll.moveTo(id);
    };

    _startCarousel = () => {
        this._stopCarousel();
        this._timeout = setTimeout(() => {
            this._scroll.moveRight();
        }, interval);
    };

    _stopCarousel = () => {
        clearTimeout(this._timeout);
    }
}

components.add(`js-${blockName}`, el => new PlacementsSlideshow(el));
