import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _createEl } from '../../common/_create-el';

import './input-type-password.scss';

const blockName = 'input-type-password';
const visibleMod = 'input-type-password_visible';

components.add(`js-${blockName}`, (el) => {
    const buttonEl = _createEl('i', `${blockName}__button lazyload`);

    _on(buttonEl, 'pointerup', (e) => {
        const inputEl = el.querySelector('input');

        if (inputEl) {
            const isPassword = inputEl.getAttribute('type') === 'password';

            inputEl.setAttribute('type', isPassword ? 'text' : 'password');
            el.classList[isPassword ? 'add' : 'remove'](visibleMod);
        }

        e.preventDefault();
    });

    el.appendChild(buttonEl);
});
