import components from '../../common/_components';
import { _isElementVisible } from '../../common/_core';
import { _on } from '../../common/_events';
import HeadersHeightManager from '../../common/_headers-height-manager';
import { scroll } from '../../common/_scroll-page';
import { OffersList } from '../shops-offers-list/shops-offers-list';
import { Filter } from '../shops-offers-list/__filter';
import shopsFilterPanel from '../shops-filters-panel/shops-filters-panel';
import { _extractActionsFromHash } from '../clickout/clickout';

import './page-shops-view.scss';

const blockName = 'page-shops-view';

components.add(`js-${blockName}`, (element) => {
    const filtersButtonEl = element.querySelector(`.js-${blockName}__filters-button`);
    const offersListEl = element.querySelector(`.js-${blockName}__offers-list`);
    const offersList = new OffersList(offersListEl);
    const currentFilter = Filter.fromURLData();

    if (filtersButtonEl) {
        _on(filtersButtonEl, 'click', shopsFilterPanel.open);
    }

    if (offersList.hasOffersList()) {
        const jumpToOfferFromHash = () => {
            const hashData = _extractActionsFromHash(window.location.hash);

            if (hashData.offerEl) {
                if (!_isElementVisible(hashData.offerEl)) {
                    offersList.showHiddenItems();
                }

                if (_isElementVisible(hashData.offerEl)) {
                    scroll(window.pageYOffset + hashData.offerEl.getBoundingClientRect().top
                        - HeadersHeightManager.getHeight() - 25, { duration: 0 });
                }
            }
        };

        shopsFilterPanel.setInitialFilter(currentFilter);
        shopsFilterPanel.setOffersList(offersList);

        offersList.setFilter(currentFilter);
        offersList.init();

        jumpToOfferFromHash();
        _on(window, 'hashchange', jumpToOfferFromHash);
    }
});
