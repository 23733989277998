import bugsnagClient from '../../common/_bugsnag-client';
import { _getData, _reflow, _toArray } from '../../common/_core';
import { _on, _onTransitionEnd } from '../../common/_events';
import { Filter } from './__filter';

import './shops-offers-list.scss';

const blockName = 'shops-offers-list';
const offerClass = `${blockName}__offer`;
const offerHiddenMod = 'hidden';
const showNoResultsMod = 'shown';

class OffersList {
    constructor(el) {
        this._el = el;
        this._offerListEl = el.querySelector(`.js-${blockName}__list`);

        this._isInited = false;
        this._limit = parseInt(_getData(this._el, 'offersLimit'), 10) || Infinity;
    }

    hasOffersList = () => Boolean(this._offerListEl);

    init = () => {
        if (this._isInited || !this.hasOffersList()) {
            return;
        }

        if (!this._filter) {
            bugsnagClient.notify('Filter is required to init OffersList');
            return;
        }

        this._originalList = _toArray(this._offerListEl.querySelectorAll(`.js-${offerClass}`));

        this._noResultsEl = this._el.querySelector(`.js-${blockName}__no-results`);
        this._loadMoreButtonEl = this._el.querySelector(`.js-${blockName}__load-more`);

        if (this._loadMoreButtonEl) {
            _on(this._loadMoreButtonEl, 'click', this._loadMoreClickHandler);
            _on(this._loadMoreButtonEl, 'touchstart', this._loadMoreClickHandler, { passive: true });
        }

        _on(this._noResultsEl, 'click', `.js-${blockName}__reset-filters`, this._resetClickHandler);

        if (!this._filter.isEmpty()) {
            this._applyFilters();
        }

        this._isInited = true;
    };

    onResetClick = (cb) => {
        if (!this._resetClickCallbacks) {
            this._resetClickCallbacks = [];
        }

        this._resetClickCallbacks.push(cb);
    };

    setFilter = (filter) => {
        if (!(filter instanceof Filter)) {
            bugsnagClient.notify(`${filter} is not an instance of Filter`);
            return;
        }

        if (!this._isInited) {
            this._filter = filter;
        } else if (!this._filter.isEqual(filter)) {
            this._filter = filter;
            this._applyFiltersWithHeightAnimation();
            this._animateShownOffers();
        }
    };

    showHiddenItems = () => {
        if (this._limit < Infinity) {
            this._limit = Infinity;

            if (this._isInited) {
                this._applyFilters();
            }
        }
    };

    _loadMoreClickHandler = (e) => {
        this._limit = Infinity;
        this._applyFiltersWithHeightAnimation();

        e.stopPropagation();
        e.preventDefault();
    };

    _resetClickHandler = () => {
        if (this._resetClickCallbacks) {
            this._resetClickCallbacks.forEach(cb => cb());
        }
    };

    _applyFilters = () => {
        let isLoadMoreButtonShown = false;
        let shownOffers = 0;

        this._shownOffersList = [];
        this._originalList.forEach((offerEl) => {
            let isShown = false;

            if (this._filter.isElementSatisfy(offerEl)) {
                shownOffers += 1;

                if (shownOffers <= this._limit) {
                    isShown = true;
                    this._shownOffersList.push(offerEl);
                } else {
                    isLoadMoreButtonShown = true;
                }
            }

            offerEl.classList.remove('animate-offer');
            offerEl.classList[isShown ? 'remove' : 'add'](offerHiddenMod);
        });

        this._noResultsEl.classList[this._shownOffersList.length === 0 ? 'add' : 'remove'](showNoResultsMod);

        if (this._loadMoreButtonEl) {
            this._loadMoreButtonEl.style.display = isLoadMoreButtonShown ? 'block' : 'none';
        }
    };

    _applyFiltersWithHeightAnimation = () => {
        const currentHeight = this._offerListEl.getBoundingClientRect().height;

        this._applyFilters();
        this._offerListEl.classList.remove('animate-height');
        this._offerListEl.style.height = '';

        const targetHeight = this._offerListEl.getBoundingClientRect().height;

        if (currentHeight !== targetHeight) {
            this._offerListEl.style.height = `${currentHeight}px`;
            this._offerListEl.classList.add('animate-height');

            _reflow(this._offerListEl);
            this._offerListEl.style.height = `${targetHeight}px`;

            _onTransitionEnd(this._offerListEl, this._onHeightAnimationEnd);
        }
    };

    _onHeightAnimationEnd = () => {
        this._offerListEl.style.height = '';
    };

    _animateShownOffers = () => {
        const shownOffersList = this._shownOffersList || [];

        if (shownOffersList.length > 0) {
            requestAnimationFrame(() => {
                shownOffersList.forEach(offer => offer.classList.add('animate-offer'));
            });
        }
    };
}

export { OffersList };
