import components from '../../common/_components';
import { _getData } from '../../common/_core';
import { _trigger } from '../../common/_events';

import './promo-countdown.scss';

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const blockName = 'promo-countdown';
const ensure2digest = num => (`0${num}`).slice(-2);

class PromoCountdown {
    constructor(el) {
        const targetDate = _getData(el, 'targetDate');

        this._el = el;
        this._targetTimestamp = new Date(Date.parse(targetDate)).getTime();
        this._daysEl = el.querySelector(`.js-${blockName}__days`);
        this._hoursEl = el.querySelector(`.js-${blockName}__hours`);
        this._minutesEl = el.querySelector(`.js-${blockName}__minutes`);
        this._secondsEl = el.querySelector(`.js-${blockName}__seconds`);

        this._update();
    }

    _update = () => {
        const currentTimestamp = new Date().getTime();
        const diff = Math.floor((this._targetTimestamp - currentTimestamp) / 1000);

        let rest;

        if (diff <= 0) {
            _trigger(this._el, 'done.promo-countdown');
            return;
        }

        const days = Math.floor(diff / DAY);
        rest = diff - days * DAY;

        const hours = Math.floor(rest / HOUR);
        rest -= hours * HOUR;

        const minutes = Math.floor(rest / MINUTE);
        rest -= minutes * MINUTE;

        this._daysEl.textContent = ensure2digest(days);
        this._hoursEl.textContent = ensure2digest(hours);
        this._minutesEl.textContent = ensure2digest(minutes);
        this._secondsEl.textContent = ensure2digest(rest);

        setTimeout(this._update, 1000);
    }
}

components.add(`js-${blockName}`, el => new PromoCountdown(el));
