import { _on } from '../../common/_events';
import { _getData, _toArray } from '../../common/_core';

import './deals-filters-panel__block_for_categories.scss';

const blockName = 'deals-filters-panel';

const categoriesStatusSelector = `.js-${blockName}__categories-status`;
const categoriesStatusVisibleMod = `${blockName}__categories-status_visible`;
const categoriesStatusNumSelector = `.js-${blockName}__categories-status-num`;

const categoriesSelector = `.js-${blockName}__categories`;
const categoriesHasSelectedMod = 'has-selected';

const categorySelector = `.js-${blockName}__category`;
const categoryCheckedMod = 'checked';

const categoriesKey = 'categories';

class DealsFiltersPanelBlockForCategories {
    constructor(el, urlData, changeHandler) {
        this._el = el;
        this._categoriesEl = el.querySelector(categoriesSelector);
        this._onChange = changeHandler;

        this._updateCategories(this._getCategories(urlData));

        _on(el, 'click', categorySelector, this._categoryClickHandler);
    }

    resetToDefault = () => {
        _toArray(this._categoriesEl
            .querySelectorAll(`${categorySelector}.${categoryCheckedMod}`))
            .forEach(categoryEl => categoryEl.classList.remove(categoryCheckedMod));
    };

    fulfillUrlData = (currentUrlData) => {
        const checkedCategoriesList = _toArray(this._categoriesEl
            .querySelectorAll(`${categorySelector}.${categoryCheckedMod}`));

        if (checkedCategoriesList.length > 0) {
            currentUrlData[categoriesKey] = checkedCategoriesList.map(categoryEl => _getData(categoryEl, 'categoryId'));
        } else {
            delete currentUrlData[categoriesKey];
        }
    };

    fulfillRequestData = (requestData, currentUrlData) => {
        const categories = this._getCategories(currentUrlData);

        if (categories.length > 0) {
            requestData[categoriesKey] = categories;
        }

        this._categoriesEl.classList[categories.length > 0 ? 'add' : 'remove'](categoriesHasSelectedMod);
        this._updateCategoriesStatus(categories.length);
    };

    _getCategories = (rawData) => {
        let categories = [];

        if (rawData[categoriesKey]) {
            if (Array.isArray(rawData[categoriesKey])) {
                categories = rawData[categoriesKey];
            } else {
                categories.push(rawData[categoriesKey]);
            }
        }

        return categories;
    };

    _updateCategories = (checkedCategoriesList) => {
        const checkHash = checkedCategoriesList.reduce((data, id) => {
            data[id] = true;
            return data;
        }, {});

        let selectedAmount = 0;
        let hasChanges = false;

        _toArray(this._categoriesEl.querySelectorAll(`${categorySelector}.${categoryCheckedMod}`))
            .forEach((categoryEl) => {
                const id = _getData(categoryEl, 'categoryId');

                if (checkHash[id] === true) {
                    categoryEl.classList.add(categoryCheckedMod);

                    delete checkHash[id];
                    selectedAmount += 1;
                    hasChanges = true;
                } else {
                    categoryEl.classList.remove(categoryCheckedMod);
                    hasChanges = true;
                }
            });

        Object.keys(checkHash).forEach((categoryId) => {
            const el = this._categoriesEl.querySelector(`${categorySelector}[data-category-id="${categoryId}"]`);

            if (el) {
                el.classList.add(categoryCheckedMod);

                selectedAmount += 1;
                hasChanges = true;
            }
        });

        if (hasChanges) {
            this._categoriesEl.classList[selectedAmount > 0 ? 'add' : 'remove'](categoriesHasSelectedMod);
            this._updateCategoriesStatus(selectedAmount);
        }
    };

    _updateCategoriesStatus = (num) => {
        const categoriesSelectedEl = this._el.querySelector(categoriesStatusSelector);
        const categoriesSelectedAmountEl = categoriesSelectedEl.querySelector(categoriesStatusNumSelector);

        categoriesSelectedAmountEl.innerText = num;
        categoriesSelectedEl.classList[num > 0 ? 'add' : 'remove'](categoriesStatusVisibleMod);
    };

    _categoryClickHandler = (e) => {
        e._caughtTarget_.classList.toggle(categoryCheckedMod);

        if (this._onChange) {
            this._onChange();
        }
    };
}

export { DealsFiltersPanelBlockForCategories };
