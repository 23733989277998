import { _on, _off, _trigger } from '../../common/_events';
import { _detachEl, _isElementVisible } from '../../common/_core';
import { throttle } from '../../vendors/_throttle-debounce';
import './overlay.scss';

const blockName = 'overlay';
const openedClass = `${blockName}__opened`;

class Overlay {
    constructor(options = {}) {
        this._isOpen = false;
        this._forMobileOnly = (options.forMobileOnly === true);
        this._closeEvent = `${blockName}_close`;

        this._resizeHandler = throttle(250, this._resizeHandler);
    }

    show = () => {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        const bodyEl = document.body;
        const overlayEl = this._getOverlay();

        if (this._isOpen) {
            return;
        }

        this._isOpen = true;

        bodyEl.insertBefore(overlayEl, bodyEl.firstChild);
        bodyEl.style.paddingRight = `${scrollbarWidth}px`;

        _on(overlayEl, 'touchstart', this._clickHandler);
        _on(overlayEl, 'click', this._clickHandler);

        document.body.classList.add(openedClass);

        if (this._forMobileOnly) {
            _on(window, 'resize', this._resizeHandler);
        }
    };

    hide = () => {
        if (!this._isOpen) {
            return;
        }

        this._isOpen = false;
        _detachEl(this._overlayEl);

        document.body.style.paddingRight = '';
        document.body.classList.remove(openedClass);

        if (this._forMobileOnly) {
            _off(window, 'resize', this._resizeHandler);
        }
    };

    getCloseEvent = () => this._closeEvent;

    _fireCloseEvent = () => {
        _trigger(document, this._closeEvent);
    };

    _getOverlay = () => {
        if (!this._overlayEl) {
            this._overlayEl = document.createElement('div');
            this._overlayEl.className = this._forMobileOnly
                ? `${blockName} ${blockName}_for-mobile`
                : blockName;
        }

        return this._overlayEl;
    };

    _clickHandler = (e) => {
        this._fireCloseEvent();
        e.preventDefault();
    };

    _resizeHandler = () => {
        if (this._isOpen && !_isElementVisible(this._overlayEl)) {
            this.hide();
            this._fireCloseEvent();
        }
    }
}

export default Overlay;
