import { _on, _off } from './_events';
import { _sendRequest } from './_ajax';
import { throttle } from '../vendors/_throttle-debounce';
import { lockPageScrolling, unlockPageScrolling } from './_scroll-page-lock';

function Node(key, val) {
    this.key = key;
    this.val = val;
    this.prev = this.next = null;
}

class List {
    constructor() {
        this.head = this.tail = null;
    }

    add = (node) => {
        if (this.head) {
            node.next = this.head;
            this.head.prev = node;
        }

        this.head = node;
        this.tail = this.tail || node;
    };

    remove = (node) => {
        if (node.prev) {
            node.prev.next = node.next;
        } else {
            this.head = node.next;
        }

        if (node.next) {
            node.next.prev = node.prev;
        } else {
            this.tail = node.prev;
        }
    };

    moveToFront = (node) => {
        this.remove(node);
        this.add(node);
    };
}

class LruCache {
    constructor() {
        this.maxSize = 18;
        this.reset();
    }

    set = (key, val) => {
        const tailItem = this.list.tail;

        if (this.size >= this.maxSize) {
            this.list.remove(tailItem);
            delete this.hash[tailItem.key];

            this.size -= 1;
        }

        let node = this.hash[key];

        if (node) {
            node.val = val;
            this.list.moveToFront(node);
        } else {
            node = new Node(key, val);

            this.list.add(node);
            this.hash[key] = node;

            this.size += 1;
        }
    };

    get = (key) => {
        const node = this.hash[key];

        if (node) {
            this.list.moveToFront(node);
            return node.val;
        }

        return null;
    };

    reset = () => {
        this.size = 0;
        this.hash = {};
        this.list = new List();
    };

    static getInstance = () => {
        if (!LruCache._instance) {
            LruCache._instance = new LruCache();
        }

        return LruCache._instance;
    };
}

const blockName = 'search';
const openedClassName = `${blockName}__opened`;
const activeClassName = `${blockName}__cursor`;
const suggestionClassName = `${blockName}__suggestion`;

const minLength = 2;
const key = {
    TAB: 9,
    ESC: 27,
    LEFT: 37,
    RIGHT: 39,
    ENTER: 13,
    UP: 38,
    DOWN: 40,
};
const SearchStates = {
    REGULAR: 'REGULAR',
    PENDING: 'PENDING',
    NOT_FOUND: 'NOT_FOUND',
};

class Search {
    /**
     * @param options
     * @param {Element} options.inputEl
     * @param {Array<SearchSection>|SearchSection} options.sections
     * @param {Element} [options.resultsEl]
     * @param {Function} [options.onInputFocus] function which is to be called on input focus event
     * @param {Function} [options.onInputBlur] function which is to be called on input blur event
     * @param {Function} [options.onInputKeyDown] function which is to be called on input keydown event
     * @param {Function} [options.onValueChange] function which is to be called when the value was changed
     * @param {Function} [options.onSelect] function which is to be called on Enter press
     * @param {Function} [options.getValueFn] function which returns input value from suggestion elemen
     */
    constructor(options) {
        this._inputEl = options.inputEl;
        this._resultsEl = options.resultsEl;
        this._getValueFromSuggestion = options.getValueFn || (el => el.textContent);
        this._sections = Array.isArray(options.sections) ? options.sections : [options.sections];
        this._options = options;

        this._bindListeners();
    }

    /**
     * @param {String} value
     */
    setValue = (value) => {
        this._setInputValue(value);
        this._onChange();
    };

    _bindListeners = () => {
        _on(this._inputEl, 'focus', this._onFocus);
        _on(this._inputEl, 'blur', this._onBlur);
        _on(this._inputEl, 'input', this._onChange);

        if (this._resultsEl) {
            _on(this._resultsEl, 'click', `.${suggestionClassName}`, this._onSuggestionClick);
            _on(this._resultsEl, 'mousedown', e => e.preventDefault());
        }
    };

    _onFocus = () => {
        if (this._resultsEl) {
            _on(this._inputEl, 'keydown', this._onKeyDown);
        }

        this._onChange();

        if (this._options.onInputFocus) {
            this._options.onInputFocus.call(null);
        }
    };

    _onBlur = () => {
        if (this._resultsEl) {
            _off(this._inputEl, 'keydown', this._onKeyDown);
        }

        this._close();
        this._resetInputValue();

        if (this._options.onInputBlur) {
            this._options.onInputBlur.call(null);
        }
    };

    _onChange = () => {
        const rawValue = this._getInputValue();
        const processedValue = this._processQuery(rawValue);

        if (processedValue.length >= minLength) {
            this._open();

            if (processedValue !== this._processQuery(this._rawValue)) {
                this._rawValue = rawValue;
                this._sections.forEach(section => section.update(processedValue));
            }
        } else {
            this._rawValue = rawValue;
            this._close();
        }

        if (this._options.onValueChange) {
            this._options.onValueChange.call(null);
        }
    };

    _onKeyDown = (e) => {
        const keyCode = e.which;

        if (this._options.onInputKeyDown) {
            this._options.onInputKeyDown.call(null, e);
        }

        if ((keyCode === key.UP || keyCode === key.DOWN) && !(e.altKey || e.ctrlKey || e.metaKey || e.shiftKey)) {
            e.preventDefault();
        }

        if (keyCode === key.TAB && (e.altKey || e.ctrlKey || e.metaKey || e.shiftKey)) {
            return;
        }

        if (this._isInputFocused() && this._isOpened()) {
            if (keyCode === key.ENTER) {
                const cursorEl = this._getActiveEl();

                if (cursorEl) {
                    this._select(cursorEl);
                    e.preventDefault();
                }
            } if (keyCode === key.TAB) {
                const cursorEl = this._getActiveEl();

                if (cursorEl) {
                    this._select(cursorEl);
                    e.preventDefault();
                } else {
                    const firstSuggestionEl = this._resultsEl.querySelector(`.${suggestionClassName}`);

                    if (firstSuggestionEl) {
                        this.setValue(this._processQuery(this._getValueFromSuggestion(firstSuggestionEl)));
                        e.preventDefault();
                    }
                }
            } else if (keyCode === key.ESC) {
                this._close();
                this._resetInputValue();
            } else if (keyCode === key.UP) {
                this._moveCursor(-1);
            } else if (keyCode === key.DOWN) {
                this._moveCursor(1);
            } else if (keyCode === key.RIGHT && this._isCursorAtEnd()) {
                const firstSuggestionEl = this._resultsEl.querySelector(`.${suggestionClassName}`);

                if (firstSuggestionEl) {
                    this.setValue(this._processQuery(this._getValueFromSuggestion(firstSuggestionEl)));
                }
            }
        }
    };

    _onSuggestionClick = (e) => {
        this._select(e._caughtTarget_);
    };

    _setInputValue = (value) => {
        this._inputEl.value = value;
    };

    _resetInputValue = () => {
        this._setInputValue(this._rawValue);
    };

    _getInputValue = () => this._inputEl.value;

    _isInputFocused = () => this._inputEl === document.activeElement && (!document.hasFocus || document.hasFocus());

    _processQuery = val => Search.processQuery(val);

    _isOpened = () => this._resultsEl && this._resultsEl.classList.contains(openedClassName);

    _open = () => {
        if (this._resultsEl && !this._isOpened()) {
            this._resultsEl.classList.add(openedClassName);
            lockPageScrolling(this._resultsEl);
        }
    };

    _close = () => {
        if (this._resultsEl && this._isOpened()) {
            this._resultsEl.classList.remove(openedClassName);
            unlockPageScrolling(this._resultsEl);
            this._removeCursor();
        }
    };

    _select = (cursorEl) => {
        this.setValue(this._processQuery(this._getValueFromSuggestion(cursorEl)));

        if (this._options.onSelect) {
            this._options.onSelect.call(null, cursorEl);
        }

        this._close();
    };

    _moveCursor = (delta) => {
        const processedValue = this._processQuery(this._rawValue);
        const areSectionsUpToDate = this._sections.every(section => section.currentQuery() === processedValue);

        if (this._isOpened() && areSectionsUpToDate) {
            const suggestions = this._resultsEl.querySelectorAll(`.${suggestionClassName}`);
            const suggestionsArray = Array.prototype.slice.call(suggestions);
            const fitIndex = (index) => {
                const len = suggestions.length + 1;
                const offset = -1;
                return ((index - offset + len * (Math.floor(Math.abs(index) / len) + 1)) % len) + offset;
            };

            const newIndex = fitIndex(suggestionsArray.indexOf(this._getActiveEl()) + delta);
            const activeEl = newIndex === -1 ? null : suggestionsArray[newIndex];

            this._removeCursor();

            if (activeEl) {
                activeEl.classList.add(activeClassName);
                this._ensureVisible(activeEl);
                this._setInputValue(this._processQuery(this._getValueFromSuggestion(activeEl)));
            } else {
                this._setInputValue(this._rawValue);
            }
        } else {
            this._onChange();
        }
    };

    _removeCursor = () => {
        const cursorEl = this._getActiveEl();

        if (cursorEl) {
            cursorEl.classList.remove(activeClassName);
        }
    };

    _getActiveEl = () => this._resultsEl.querySelector(`.${suggestionClassName}.${activeClassName}`);

    _ensureVisible = (el) => {
        const resultsRect = this._resultsEl.getBoundingClientRect();
        const elRect = el.getBoundingClientRect();

        if (elRect.top < resultsRect.top) {
            this._resultsEl.scrollTop += elRect.top - resultsRect.top;
        } else if (elRect.bottom > resultsRect.bottom) {
            this._resultsEl.scrollTop += elRect.bottom - resultsRect.bottom;
        }
    };

    _isCursorAtEnd = () => this._inputEl.selectionStart === this._getInputValue().length;

    static processQuery = val => (val || '').replace(/^\s*/g, '').replace(/\s{2,}/g, ' ');
}

class SearchSection {
    /**
     * @param options
     * @param {String} options.url
     * @param {String} options.wildcard
     * @param {Function} options.render
     */
    constructor(options) {
        this._url = decodeURI(options.url);
        this._wildcard = options.wildcard;
        this._renderFn = options.render;

        this._lastReq = null;
        this._pendingRequests = {};
        this._cache = LruCache.getInstance();

        this._request = throttle(300, this._request);
    }

    currentQuery = () => this._query;

    update = (query) => {
        const requestedQuery = query || '';
        const url = this._url.replace(this._wildcard, encodeURIComponent(requestedQuery));

        this._query = query;
        this._render(SearchStates.PENDING, requestedQuery, []);

        this._get({ url, dataType: 'json' }, (err, resp) => {
            if (requestedQuery === this._query) {
                const suggestions = err ? [] : resp.data;
                const state = suggestions.length ? SearchStates.REGULAR : SearchStates.NOT_FOUND;

                this._render(state, requestedQuery, suggestions);
            }
        });
    };

    _render = (state, query, suggestions) => {
        this._renderFn.call(null, state, query, suggestions, {
            states: SearchStates,
            suggestionClassName,
        });
    };

    _get = (options, cb) => {
        const fingerprint = this._getFingerprint(options);
        const cachedResponse = this._cache.get(fingerprint);

        this._lastReq = fingerprint;

        if (cachedResponse) {
            cb(null, cachedResponse);
        } else {
            this._request(options, cb);
        }
    };

    _request = (options, cb) => {
        const fingerprint = this._getFingerprint(options);

        let request = this._pendingRequests[fingerprint];

        if (fingerprint !== this._lastReq) {
            return;
        }

        if (!request) {
            request = _sendRequest(options).always(() => {
                delete this._pendingRequests[fingerprint];

                if (this._onDeckRequestArgs) {
                    this._request.call(this, this._onDeckRequestArgs[0], this._onDeckRequestArgs[1]);
                    this._onDeckRequestArgs = null;
                }
            });
        }

        if (!request) {
            this._onDeckRequestArgs = [options, cb];
        } else {
            request
                .done((resp) => {
                    cb(null, resp);
                    this._cache.set(fingerprint, resp);
                })
                .fail(() => cb(true));
        }
    };

    _getFingerprint = o => `${o.url}|${o.type}`;
}

function highlight(query, text, tagName) {
    const queryRE = new RegExp(`(${query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')})`, 'i');
    tagName = typeof tagName === 'string' ? tagName : 'strong';
    return text.replace(queryRE, `<${tagName}>$1</${tagName}>`);
}

export {
    Search,
    SearchSection,
    SearchStates,
    suggestionClassName as searchSuggestionClassName,
    highlight as searchHighlightQuery,
};
