import components from '../../common/_components';
import { _on } from '../../common/_events';

import './page-users-add.scss';

const blockName = 'page-users-add';

components.add(`js-${blockName}__checkbox`, (rowEl) => {
    const fieldEl = rowEl.querySelector(`.js-${blockName}__checkbox-field`);
    const boxEl = rowEl.querySelector(`.js-${blockName}__checkbox-box`);
    const updateRow = () => {
        rowEl.classList[fieldEl.checked ? 'add' : 'remove']('checked');
    };

    updateRow();
    rowEl.classList.add('ready');

    _on(fieldEl, 'change', updateRow);
    _on(boxEl, 'click', () => {
        fieldEl.checked = !fieldEl.checked;
        updateRow();
    });
});
