import components from '../../common/_components';
import ScrollHorizontal from '../../common/_scroll-horizontal';
import './guides-sidebar.scss';

const blockName = 'guides-sidebar';
const listSelector = `.js-${blockName}__list`;
const listItemSelector = `.js-${blockName}__list-item`;

components.add(`js-${blockName}`, (el) => {
    ScrollHorizontal.installTo(el, el.querySelector(listSelector), listItemSelector);
});
