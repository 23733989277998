const storage = window.sessionStorage;

let isAvailable;

const StorageSession = {
    isAvailable: () => {
        if (isAvailable !== true && isAvailable !== false) {
            try {
                storage.setItem('__testprop__', '__testprop__');
                storage.removeItem('__testprop__');

                isAvailable = true;
            } catch (e) {
                isAvailable = false;
            }
        }

        return isAvailable;
    },
    set: (key, value) => {
        let result = false;

        try {
            storage.setItem(key, value);
            result = true;
        } catch (e) {}

        return result;
    },
    get: (key) => {
        if (isAvailable === true) {
            return storage.getItem(key);
        }

        return null;
    },
    remove: (key) => {
        if (isAvailable === true) {
            storage.removeItem(key);
        }
    },
};

export { StorageSession };
