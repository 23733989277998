import components from '../../common/_components';
import { _createEl } from '../../common/_create-el';
import { _getData, _reflow, _toArray } from '../../common/_core';
import { _off, _on, _onTransitionEnd } from '../../common/_events';
import { _isNumber } from '../../common/_types';

import { FixedPanelsManager } from '../../common/_fixed-panels-manager';
import { throttle } from '../../vendors.theme/_throttle-debounce';

import './deals-view-body.scss';

const blockName = 'deals-view-body';

let splashEl;

function buildSplashFor(el) {
    const isExpired = el.classList.contains(`${blockName}_is-expired`);
    const imageSrc = _getData(el, 'splashImageUrl');
    const titleEl = el.querySelector(`.js-${blockName}__title`);
    const priceEl = el.querySelector(`.js-${blockName}__price`); // ?
    const prevPriceEl = el.querySelector(`.js-${blockName}__prev-price`); // ?
    const deliveryEl = el.querySelector(`.js-${blockName}__delivery`); // ?
    const checkoutButtonEl = el.querySelector(`.js-${blockName}__footer-clickout`); // ?

    if (!priceEl && !deliveryEl && !checkoutButtonEl) {
        return;
    }

    splashEl = _createEl('div', `${blockName}__splash${isExpired ? ' is-expired' : ''}`, [
        _createEl('div', 'container', [
            _createEl('div', `${blockName}__splash-inner`, [
                _createEl('div', `${blockName}__splash-image`, [
                    _createEl('img', `${blockName}__splash-image-img`, {
                        src: imageSrc,
                        alt: '',
                    }),
                ]),

                _createEl('div', `${blockName}__splash-title`, [
                    titleEl.textContent,
                ]),

                _createEl('div', `${blockName}__info-and-checkout`, (() => {
                    const results = [];
                    const infoNodes = [];

                    if (priceEl) {
                        const priceContent = [];

                        priceContent.push(
                            _createEl(
                                'span',
                                `${blockName}__splash-price`,
                                _toArray(priceEl.childNodes).map(node => node.cloneNode(true)),
                            ),
                        );

                        if (prevPriceEl) {
                            priceContent.push(
                                _createEl(
                                    'span',
                                    `${blockName}__splash-prev-price`,
                                    _toArray(prevPriceEl.childNodes).map(node => node.cloneNode(true)),
                                ),
                            );
                        }

                        infoNodes.push(
                            _createEl('div', `${blockName}__splash-prices`, priceContent),
                        );
                    }

                    if (deliveryEl) {
                        infoNodes.push(
                            _createEl('div', `${blockName}__splash-delivery`, [
                                deliveryEl.textContent,
                            ]),
                        );
                    }

                    if (checkoutButtonEl) {
                        results.push(_createEl('div', `${blockName}__splash-checkout`, [
                            checkoutButtonEl.cloneNode(true),
                        ]));
                    }

                    if (infoNodes.length) {
                        results.push(_createEl('div', `${blockName}__splash-info`, infoNodes));
                    }

                    return results;
                })()),
            ]),
        ]),
    ]);

    document.body.appendChild(splashEl);
    components.init();
}

function showSplash() {
    const targetScrollHeight = splashEl.scrollHeight;

    _onTransitionEnd(splashEl, onSplashTransitionEnd);

    splashEl.style.height = `${targetScrollHeight}px`;
    splashEl.classList.add('shown');

    FixedPanelsManager.dispatchUpdate();
}

function hideSplash() {
    splashEl.style.height = `${splashEl.offsetHeight}px`;

    _reflow(splashEl);
    _onTransitionEnd(splashEl, onSplashTransitionEnd);

    splashEl.style.height = '0px';
    splashEl.classList.remove('shown');

    FixedPanelsManager.dispatchUpdate();
}

function onSplashTransitionEnd() {
    splashEl.style.height = '';
}

const getSplashHeight = () => {
    if (splashEl.classList.contains('shown')) {
        const styleHeight = parseFloat(splashEl.style.height);
        return _isNumber(styleHeight) ? styleHeight : splashEl.offsetHeight;
    }

    return 0;
};

components.add(`js-${blockName}`, (el) => {
    let scrollHandlerDecorated;
    let resizeHandlerDecorated;

    function scrollHandler() {
        const elRect = el.getBoundingClientRect();

        if (elRect.top + elRect.height < 0) {
            if (!splashEl) {
                buildSplashFor(el);

                if (!splashEl) {
                    _off(window, 'scroll', scrollHandlerDecorated);
                    _off(window, 'resize', resizeHandlerDecorated);

                    return;
                }

                FixedPanelsManager.setupPanel(0, true, getSplashHeight);
            }

            if (!splashEl.classList.contains('shown')) {
                showSplash();
            }
        } else if (splashEl && splashEl.classList.contains('shown')) {
            hideSplash();
        }
    }

    resizeHandlerDecorated = throttle(200, () => {
        if (splashEl && splashEl.classList.contains('shown')) {
            scrollHandler();

            if (splashEl.classList.contains('shown')) {
                FixedPanelsManager.dispatchUpdate();
            }
        } else {
            scrollHandler();
        }
    });

    scrollHandlerDecorated = throttle(200, scrollHandler);

    _on(window, 'scroll', scrollHandlerDecorated);
    _on(window, 'resize', resizeHandlerDecorated);
});
