import { _parseQueryString, _stringify } from '../../common/_ajax';
import { _getData } from '../../common/_core';
import { _replaceState } from '../../common/_history';
import { _isBoolean, _isNumber, _isString } from '../../common/_types';

const SUPPORTED_FILTERS = [
    'type',
    'currency',
    'free-shipping',
    'freebie',
];

class Filter {
    constructor(__data) {
        this._data = __data || {};
    }

    _getDataKey = (filterName) => {
        const firstLetter = filterName[0].toUpperCase();
        const restName = filterName.slice(1).replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());

        return `filter${firstLetter}${restName}`;
    };

    toggle = (filterName, filterValue) => {
        const data = {};

        let hasFound = false;

        Object.keys(this._data).forEach((name) => {
            const availableValues = this._data[name].slice();

            if (name === filterName) {
                const index = availableValues.indexOf(filterValue);

                if (index === -1) {
                    availableValues.push(filterValue);
                } else {
                    availableValues.splice(index, 1);
                }

                hasFound = true;
            }

            if (availableValues.length) {
                data[name] = availableValues;
            }
        });

        if (!hasFound) {
            data[filterName] = [filterValue];
        }

        return new Filter(data);
    };

    isFilterEnabled = (filterName, filterValue) => Array.isArray(this._data[filterName])
        && this._data[filterName].indexOf(filterValue) !== -1;

    isElementSatisfy = (element) => {
        const filtersNames = Object.keys(this._data);

        while (filtersNames.length) {
            const filterName = filtersNames.shift();
            const availableValues = this._data[filterName];
            const elementValue = _getData(element, this._getDataKey(filterName));

            if (availableValues.indexOf(elementValue) === -1) {
                return false;
            }
        }

        return true;
    };

    isEqual = filter => JSON.stringify(this._data) === JSON.stringify(filter._data);

    isEmpty = () => Object.keys(this._data).length === 0;

    toUrlData = () => {
        const urlData = _parseQueryString(window.location.search.slice(1));
        const data = this._data;
        let queryString;

        SUPPORTED_FILTERS.forEach((filterName) => {
            if (data[filterName]) {
                urlData[filterName] = data[filterName].slice();
            } else if (urlData[filterName]) {
                delete urlData[filterName];
            }
        });

        queryString = _stringify(urlData);

        if (queryString) {
            queryString = `?${queryString}`;
        }

        if (window.location.search !== queryString) {
            _replaceState(`${window.location.pathname}${queryString}${window.location.hash}`);
        }
    };

    static fromURLData = () => {
        const data = {};
        const urlData = _parseQueryString(window.location.search.slice(1));

        SUPPORTED_FILTERS.forEach((filterName) => {
            const value = urlData[filterName];

            if (value) {
                if (Array.isArray(value)) {
                    data[filterName] = value.slice();
                } else if (_isString(value) || _isBoolean(value) || _isNumber(value)) {
                    data[filterName] = [String(value)];
                }
            }
        });

        return new Filter(data);
    };
}

export { Filter };
