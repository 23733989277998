import { _createEl } from '../../common/_create-el';
import './__input.scss';

const blockName = 'layout-search';

export function layoutSearchInput(name, placeholder) {
    return _createEl('input', `${blockName}__input form-control`, {
        'type': 'text',
        'autocomplete': 'off',
        'name': name,
        'placeholder': placeholder,
        'aria-label': placeholder,
    });
}
