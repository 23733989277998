import { _createEl } from '../../common/_create-el';
import { _getSvgIcon } from '../../common/_svg-icon';
import './__button-search.scss';

const blockName = 'layout-search';

export function layoutSearchButtonSearch(label) {
    return _createEl('div', `btn btn_size_lg ${blockName}__button-submit`, [
        _getSvgIcon('search', `${blockName}__button-submit-icon`),
        _createEl('span', `${blockName}__button-submit-label`, [label]),
    ]);
}
