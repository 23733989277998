import HeadersHeightManager from './_headers-height-manager';
import { getConfigured, Animation } from '../../../../assets/common/_scroll-page';

export { Animation };
export const {
    scroll,
    stop,
    isInProgress,
    scrollToTop,
} = getConfigured(
    {
        getHeaderHeight: HeadersHeightManager.getHeight,
        addHeaderStateChangeHandler: HeadersHeightManager.addStateChangeHandler,
        removeHeaderStateChangeHandler: HeadersHeightManager.removeStateChangeHandler,
    },
);
