import components from '../../common/_components';
import { _on, _onTransitionEnd } from '../../common/_events';
import { _closest, _forIn, _getCSSProp, _reflow } from '../../common/_core';

import './wysiwyg-accordion.scss';

const blockName = 'wysiwyg-accordion';
const itemExpandedMod = 'expanded';

function transitionEndHandler(e) {
    e.target.style.height = '';
}

function collapse(itemEl) {
    const bodyEl = itemEl.querySelector(`.js-${blockName}__item-body`);

    bodyEl.style.height = _getCSSProp(bodyEl, 'height');
    _reflow(bodyEl);

    bodyEl.style.height = '0';
    itemEl.classList.remove(itemExpandedMod);

    _onTransitionEnd(bodyEl, transitionEndHandler);
}

function expand(itemEl) {
    const bodyEl = itemEl.querySelector(`.js-${blockName}__item-body`);

    bodyEl.style.height = `${bodyEl.scrollHeight}px`;
    itemEl.classList.add(itemExpandedMod);

    _onTransitionEnd(bodyEl, transitionEndHandler);
}

components.add(`js-${blockName}`, (element) => {
    _on(element, 'click', `.js-${blockName}__item-heading`, (e) => {
        const itemEl = _closest(e._caughtTarget_, `.js-${blockName}__item`);

        if (itemEl.classList.contains(itemExpandedMod)) {
            collapse(itemEl);
        } else {
            const selectedItemsEls = element.querySelectorAll(`.js-${blockName}__item.${itemExpandedMod}`);

            _forIn(selectedItemsEls, (expandedItemEl) => {
                if (expandedItemEl !== itemEl) {
                    collapse(expandedItemEl);
                }
            });

            expand(itemEl);
        }
    });
});
