const panels = {};
const subscribers = [];
const blockName = 'fixed-panels-manager';

let compensationEl;

const FixedPanelsManager = {
    /**
     *
     * @param {Number} bottomIndex
     * @param {Boolean} needsCompensation
     * @param {Function} getHeight
     * @param {Function} [onOffsetChange]
     */
    setupPanel: (bottomIndex, needsCompensation, getHeight, onOffsetChange) => {
        if (panels[bottomIndex]) {
            throw new Error(`We have panel with same index: "${bottomIndex}"`);
        }

        panels[bottomIndex] = {
            getHeight,
            onOffsetChange,
            needsCompensation,
            height: 0,
            isNew: true,
        };

        FixedPanelsManager.dispatchUpdate();
    },

    /**
     * @param {Function} handler
     */
    subscribeOnChange: (handler) => {
        subscribers.push(handler);
    },

    dispatchUpdate: () => {
        const sortedIndexes = Object.keys(panels).sort((a, b) => parseFloat(a) - parseFloat(b));

        let fullOffset = 0;
        let compensationHeight = 0;
        let hasChanges = false;
        let updateCompensation = false;

        for (let i = 0; i < sortedIndexes.length; i += 1) {
            const currentKey = sortedIndexes[i];
            const panel = panels[currentKey];
            const panelHeight = panel.getHeight();

            if (hasChanges && panel.onOffsetChange) {
                panel.onOffsetChange(fullOffset);
            }

            if (panel.height !== panelHeight) {
                panel.height = panelHeight;
                hasChanges = true;

                if (panel.isNew && panel.onOffsetChange) {
                    panel.onOffsetChange(fullOffset);
                    delete panel.isNew;
                }
            }

            fullOffset += panelHeight;

            if (panel.needsCompensation === true) {
                compensationHeight += panelHeight;
                updateCompensation = true;
            }
        }

        if (hasChanges && updateCompensation) {
            if (!compensationEl) {
                compensationEl = document.createElement('div');
                compensationEl.className = `${blockName}__placeholder`;

                document.body.appendChild(compensationEl);
            }

            compensationEl.style.height = `${compensationHeight}px`;
        }

        if (hasChanges) {
            subscribers.forEach(sub => sub({
                fullOffset,
                compensationHeight,
            }));
        }
    },
};

export { FixedPanelsManager };
