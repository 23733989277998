import components from '../../common/_components';
import { _getData, _parseHTML } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { _on, _off } from '../../common/_events';
import { _isNumber } from '../../common/_types';
import './input-type-date.scss';

const pickerJSURL = 'https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.1.4/dist/js/datepicker.min.js';
const pickerLocaleURL = 'https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.1.4/dist/js/locales/{locale}.js';
const pickerCSSURL = 'https://cdn.jsdelivr.net/npm/vanillajs-datepicker@1.1.4/dist/css/datepicker.min.css';
const blockName = 'input-type-date';

let isLoading = false;
let isLoaded = false;

class DatePicker {
    constructor(el) {
        this._el = el;
        this._buttonEl = _createEl('i', `${blockName}__button lazyload`);
        this._inputEl = el.querySelector('input');

        _on(this._buttonEl, 'pointerdown', this._onButtonClick);
        _on(this._inputEl, 'focus', this._onInputFocus);

        this._el.appendChild(this._buttonEl);
    }

    _onButtonClick = (e) => {
        if (this._picker) {
            if (this._picker.active) {
                this._picker.hide();
            } else {
                this._picker.show();
            }
        } else if (this._isLoaderShown) {
            this._hideLoader();
        } else {
            this._onInputFocus();
        }

        e.preventDefault();
    };

    _onInputFocus = () => {
        this._buildPicker();

        if (this._picker) {
            _off(this._inputEl, 'focus', this._onInputFocus);
        } else if (!this._isLoaderShown) {
            _on(this._inputEl, 'blur', this._hideLoader);

            this._requestPicker();
            this._showLoader();
        }
    }

    _showLoader = () => {
        if (!this._isLoaderShown) {
            if (!this._loaderEl) {
                this._loaderEl = _createEl(
                    'div',
                    `${blockName}__loader`,
                    _parseHTML(`<i class="fa fa-spin fa-circle-notch ${blockName}__loader-spinner"></i>`),
                );
            }

            this._el.appendChild(this._loaderEl);
            this._addLoaderPosition();

            this._isLoaderShown = true;
        }
    };

    _addLoaderPosition = () => {
        const inputRect = this._el.getBoundingClientRect();
        const tooltipHeight = this._loaderEl.getBoundingClientRect().height;
        const windowHeight = document.documentElement.clientHeight;
        const maxHeightBottom = windowHeight - inputRect.top - inputRect.height;

        let position = 'bottom';

        if (maxHeightBottom < tooltipHeight + 4 && inputRect.top > tooltipHeight + 4) {
            position = 'top';
        }

        this._loaderEl.classList.add(position);
    };

    _hideLoader = () => {
        if (this._isLoaderShown) {
            this._loaderEl.parentNode.removeChild(this._loaderEl);
            this._loaderEl.classList.remove('top', 'bottom');

            this._isLoaderShown = false;
        }
    };

    _requestPicker = () => {
        if (!isLoading && !isLoaded) {
            const onPickerLoaded = this._onPickerLoaded;
            const locale = _getData(this._el, 'dateLocale');
            const script = _createEl('script', { src: pickerJSURL });
            const localeScript = locale === 'en'
                ? null
                : _createEl('script', { src: pickerLocaleURL.replace('{locale}', locale) });
            const css = _createEl('link', {
                rel: 'stylesheet',
                href: pickerCSSURL,
            });

            let isScriptLoaded = false;
            let isCssLoaded = false;
            let isLocaleLoaded = localeScript === null;

            const check = () => {
                if (isScriptLoaded && isCssLoaded && isLocaleLoaded) {
                    setTimeout(onPickerLoaded, 100);
                }
            };

            const loadLocale = () => {
                if (localeScript) {
                    let localeTimeout;
                    const onLocalLoaded = () => {
                        isLocaleLoaded = true;
                        clearTimeout(localeTimeout);

                        check();
                    };

                    localeTimeout = setTimeout(() => {
                        if (isScriptLoaded && isCssLoaded) {
                            onLocalLoaded();
                        }
                    }, 5000);

                    _on(localeScript, 'load', onLocalLoaded);
                    document.body.appendChild(localeScript);
                }
            };

            _on(script, 'load', () => {
                isScriptLoaded = true;

                loadLocale();
                check();
            });

            _on(css, 'load', () => {
                isCssLoaded = true;
                check();
            });

            document.body.appendChild(script);
            document.body.appendChild(css);

            isLoading = true;
        }
    };

    _onPickerLoaded = () => {
        const showPicker = this._isLoaderShown;

        isLoaded = true;

        this._hideLoader();
        this._buildPicker();

        if (showPicker) {
            this._picker.show();
        }
    };

    _buildPicker = () => {
        if (!this._picker && isLoaded) {
            const ensureTimestamp = (timestamp) => {
                const number = parseFloat(timestamp);
                return _isNumber(number) ? number * 1000 : timestamp;
            };

            this._picker = new window.Datepicker(this._inputEl, {
                autohide: true,
                defaultViewDate: ensureTimestamp(_getData(this._el, 'dateDefault')),
                format: _getData(this._el, 'dateFormat'),
                language: _getData(this._el, 'dateLocale'),
                maxDate: ensureTimestamp(_getData(this._el, 'dateMax')),
                minDate: ensureTimestamp(_getData(this._el, 'dateMin')),
                nextArrow: `<svg viewBox="0 0 20 20" class="${blockName}__datepicker-next">
                    <use xlink:href="#arrow-right"></use>
                </svg>`,
                prevArrow: `<svg viewBox="0 0 20 20" class="${blockName}__datepicker-prev">
                    <use xlink:href="#arrow-right"></use>
                </svg>`,
            });
        }
    };
}

components.add(`js-${blockName}`, el => new DatePicker(el));
