import { _on } from '../../common/_events';
import { _toArray } from '../../common/_core';
import { scroll as scrollPage } from '../../common/_scroll-page';
import HeadersHeightManager from '../../common/_headers-height-manager';
import {
    getPaginationSideEl,
    loadThePage,
    PAGE_PREV,
    PAGE_NEXT,
} from '../../components.theme/load-more-page/load-more-page';

const blockName = 'keyboard-navigation';

const KEYBOARD_CODE_J = 74;
const KEYBOARD_CODE_K = 75;
const OFFSET = 20;

let scrollingTarget = null;

function scrollToTarget() {
    if (!scrollingTarget) {
        return;
    }

    const onHeaderPinStateChange = () => scrollToTarget();
    const targetScrollTop = window.pageYOffset + scrollingTarget.getBoundingClientRect().top
        - HeadersHeightManager.getHeight() - OFFSET;

    HeadersHeightManager.addStateChangeHandler(onHeaderPinStateChange);
    scrollPage(targetScrollTop, {
        onDone: (isCanceled) => {
            HeadersHeightManager.removeStateChangeHandler(onHeaderPinStateChange);

            if (!isCanceled) {
                scrollingTarget = null;
            }
        },
    });
}

function getNearestItem(items, desiredClientTop, checkFn) {
    let minDistance = Infinity;
    let result;

    for (let i = 0; i < items.length; i += 1) {
        const currentEl = items[i];
        const clientTop = currentEl.getBoundingClientRect().top;
        const distance = Math.abs(desiredClientTop - clientTop);

        if (distance < minDistance && checkFn(clientTop)) {
            minDistance = distance;
            result = currentEl;
        }
    }

    return result;
}

function keyupHandler(e) {
    const targetEl = e.target;
    const isUpPressed = e.which === KEYBOARD_CODE_J;
    const isDownPressed = e.which === KEYBOARD_CODE_K;

    if (!isUpPressed && !isDownPressed) {
        return;
    }

    if (targetEl && targetEl.tagName && ['input', 'textarea'].indexOf(targetEl.tagName.toLowerCase()) !== -1) {
        return;
    }

    const items = _toArray(document.querySelectorAll(`.js-${blockName}__item`));

    if (!items.length) {
        return;
    }

    if (!scrollingTarget) {
        const desiredClientTop = HeadersHeightManager.getHeight() + OFFSET;
        const checkFn = isUpPressed
            ? clientTop => desiredClientTop - clientTop > 5
            : clientTop => clientTop - desiredClientTop > 5;

        scrollingTarget = getNearestItem(items, desiredClientTop, checkFn);
    } else {
        const scrollingTargetIndex = items.indexOf(scrollingTarget);
        const nextIndex = isUpPressed ? scrollingTargetIndex - 1 : scrollingTargetIndex + 1;

        if (scrollingTargetIndex > -1 && items[nextIndex]) {
            scrollingTarget = items[nextIndex];
        }
    }

    if (!scrollingTarget) {
        const sideEl = getPaginationSideEl(isUpPressed ? PAGE_PREV : PAGE_NEXT);

        if (sideEl) {
            scrollingTarget = sideEl;
            loadThePage(sideEl);
        }
    }

    if (scrollingTarget) {
        scrollToTarget();
    }
}

if (document.querySelector(`.js-${blockName}__item`)) {
    _on(document, 'keyup', keyupHandler);
}
