let isDataSetSupported;

function _isDataSetSupported() {
    if (isDataSetSupported === undefined) {
        const el = document.createElement('div');
        el.setAttribute('data-a-b', 'c');
        isDataSetSupported = Boolean(el.dataset && el.dataset.aB === 'c');
    }

    return isDataSetSupported;
}

function _getDataAttrName(datasetKey) {
    return `data-${datasetKey.replace(/([A-Z])/g, letter => `-${letter.toLowerCase()}`)}`;
}

/**
 * Makes array from collections (like NodeList or DOMTokenList),
 * wraps in array other once, copies true Array`s
 * returns empty array for null and undefined
 *
 * @param {*} collection
 * @return {Array}
 */
function toArray(collection) {
    if (collection !== null && collection !== undefined) {
        if (collection.length > -1
            && typeof collection !== 'function'
            && Object.prototype.toString.call(collection) !== '[object String]'
            && !Array.isArray(collection)) {
            return Array.prototype.slice.call(collection);
        }

        return [].concat(collection);
    }

    return [];
}

/**
 * For in wrapper for iteration within Object
 *
 * @param {Object} object
 * @param {Function} iteratee
 */
function forIn(object, iteratee) {
    const keys = Object.keys(object);

    while (keys.length) {
        const key = keys.shift();
        iteratee(object[key], key, object);
    }
}

/**
 * Returns current style value
 *
 * @param {Element} el
 * @param {String} propName
 * @return {String}
 */
function getCSSProp(el, propName) {
    return String(window.getComputedStyle(el)[propName]);
}

/**
 * Enforce element reflow. Especially useful for animation
 *
 * @param {Element} el
 */
function reflow(el) {
    getCSSProp(el, 'height');
}

/**
 * Returns the nearest parent Element with given className
 *
 * @param {Element} el
 * @param {String} parentClass
 * @return {Element|Null}
 */
function closest(el, parentClass) {
    parentClass = parentClass.slice(1);

    while (el && el.nodeType === Node.ELEMENT_NODE) {
        if (el.classList.contains(parentClass)) {
            return el;
        }

        el = el.parentNode;
    }

    return null;
}

/**
 * Converts HTML string into array of Nodes. Removes empty text nodes.
 *
 * @param {String} content
 * @return {Array}
 */
function parseHTML(content) {
    const context = document.createElement('div');
    const letterRE = /\w/;
    context.innerHTML = content;
    return toArray(context.childNodes).filter((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            return letterRE.test(node.nodeValue);
        }

        return true;
    });
}

/**
 * Note: value casts to string when setting
 *
 * @param {Element} el
 * @param {String} name
 * @param {*} value
 */
function setData(el, name, value) {
    if (_isDataSetSupported() && el.dataset) {
        el.dataset[name] = value;
    } else {
        el.setAttribute(_getDataAttrName(name), value);
    }
}

/**
 *
 * @param {Element} el
 * @param {String} name Should be in camelCase
 */
function removeData(el, name) {
    if (_isDataSetSupported() && el.dataset) {
        delete el.dataset[name];
    } else {
        el.removeAttribute(_getDataAttrName(name));
    }
}

/**
 * @param {Element} el
 * @param {String} name Should be in camelCase
 * @return {String}
 */
function getData(el, name) {
    return _isDataSetSupported() && el.dataset
        ? el.dataset[name]
        : el.getAttribute(_getDataAttrName(name));
}

/**
 * Helper to remove Element from document tree
 * @param {Element} el
 */
function detachEl(el) {
    const parentEl = el.parentNode;

    if (parentEl) {
        parentEl.removeChild(el);
    }
}

/**
 * Checks whether Element visible or not
 *
 * @param {Element} el
 */
function isElementVisible(el) {
    return Boolean(el.offsetWidth || el.offsetHeight || el.getClientRects().length);
}

export {
    getCSSProp as _getCSSProp,
    reflow as _reflow,
    toArray as _toArray,
    forIn as _forIn,
    closest as _closest,
    setData as _setData,
    getData as _getData,
    removeData as _removeData,
    parseHTML as _parseHTML,
    detachEl as _detachEl,
    isElementVisible as _isElementVisible,
};
