import { _on, _onTransitionEnd } from '../../common/_events';
import { _reflow, _getData, _getCSSProp } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import Overlay from '../overlay/overlay';
import './lightbox.scss';

/**
 * We use .featherlight-trigger until we refactored other satellites to similar solutions.
 * All satellites uses WysiwygMiddleware
 */
_on(document, 'click', '.featherlight-trigger', (e) => {
    const linkEl = e._caughtTarget_;

    if (_getCSSProp(linkEl, 'cursor') !== 'default') {
        const overlay = new Overlay();
        const src = _getData(linkEl, 'featherlight');
        const lightboxEl = _createEl('div', 'lightbox', [
            _createEl('div', 'lightbox__content', [
                _createEl('img', 'lightbox__content-img', {
                    src,
                    alt: '',
                }),
            ]),
        ]);

        _on(lightboxEl, 'click', () => {
            if (lightboxEl.classList.contains('shown')) {
                lightboxEl.classList.remove('shown');

                _onTransitionEnd(lightboxEl, () => {
                    document.body.removeChild(lightboxEl);
                    linkEl.removeAttribute('tabindex');
                    overlay.hide();
                });
            }
        });

        linkEl.setAttribute('tabindex', '-1');
        document.body.appendChild(lightboxEl);
        _reflow(lightboxEl);

        overlay.show();
        lightboxEl.classList.add('shown');
    }

    e.preventDefault();
});
