import components from '../../common/_components';
import { _closest, _getData } from '../../common/_core';
import { _on } from '../../common/_events';
import { _copyText, _getSelectedText, _selectEl } from '../../common/_selection';

import './deals-coupon-code.scss';

const blockName = 'deals-coupon-code';

let buttonResetTimeout;
let selectionTimout = null;

function _clearSelectionTimeout() {
    if (selectionTimout !== null) {
        clearTimeout(selectionTimout);
        selectionTimout = null;
    }
}

function _hasNoSelectionTimout() {
    return selectionTimout === null;
}

function _startSelectionTimeout(elToSelect) {
    selectionTimout = setTimeout(() => {
        _clearSelectionTimeout();
        _selectEl(elToSelect);
    }, 700);
}

function codeTouchendHandler(e, codeEl) {
    const successClassName = 'success';
    const isFromButton = _closest(e.target, `.js-${blockName}__button`) !== null;

    if (e.touches && e.touches.length > 0) {
        return;
    }

    if (isFromButton || !_getSelectedText()) {
        _copyText(_getData(codeEl, 'code'), {
            onSuccess: () => {
                if (!codeEl.classList.contains(successClassName)) {
                    codeEl.classList.add(successClassName);
                } else {
                    clearTimeout(buttonResetTimeout);
                }

                buttonResetTimeout = setTimeout(() => {
                    codeEl.classList.remove(successClassName);
                }, 2000);
            },
        });
    }
}

components.add(`js-${blockName}`, (el) => {
    const getTextEl = () => el.querySelector(`.js-${blockName}__text`);

    if (el) {
        _on(el, 'mouseup', e => codeTouchendHandler(e, el));
        _on(el, 'touchend', e => codeTouchendHandler(e, el));
        _on(el, 'mousedown', () => {
            _clearSelectionTimeout();
            _on(document, 'mouseup', _clearSelectionTimeout);

            _startSelectionTimeout(getTextEl());
        });

        _on(el, 'touchstart', (touchstartEvent) => {
            if (_hasNoSelectionTimout()) {
                const id = touchstartEvent.changedTouches[0].identifier;

                _on(document, 'touchend', (touchendEnd) => {
                    if (touchendEnd.changedTouches[0].identifier === id) {
                        _clearSelectionTimeout();
                    }
                });

                _startSelectionTimeout(getTextEl());
            }
        });
    }
});
