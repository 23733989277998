import { _off, _on } from '../../common/_events';
import { _isNumber } from '../../common/_types';
import { _createEl } from '../../common/_create-el';

import './range-slider.scss';

const blockName = 'range-slider';

const handleClassName = `${blockName}__handle`;
const handleForMinClassName = `${blockName}__handle_for_min`;
const handleForMaxClassName = `${blockName}__handle_for_max`;

const startEventsList = ['mousedown', 'touchstart', 'pointerdown'];
const moveEventsList = ['mousemove', 'touchmove', 'pointermove'];
const endEventsList = ['mouseup', 'touchend', 'pointerup'];

class RangeSlider {
    /**
     *
     * @param el {Element}
     * @param values {Array} [min, max]
     * @param inputs {Array} [minInputEl, maxInputEl]
     * @param rawOptions {Object}
     */
    constructor(el, values, inputs, rawOptions = {}) {
        this._rankSymbol = '.';
        this._rankRE = /\./g;

        this._options = {
            ...{
                minValue: 0,
                maxValue: Infinity,
                maxValueLabel: null,
                pattern: '{{value}}',
                onSlide: () => {},
                onChange: () => {},
            },
            ...rawOptions,
        };

        this._inputMinEl = inputs[0];
        this._inputMaxEl = inputs[1];

        this._el = el;
        this._lineEl = _createEl('div', `${blockName}__line`);
        this._fillEl = _createEl('div', `${blockName}__fill`, [
            _createEl('div', `${handleClassName} ${handleForMinClassName}`),
            _createEl('div', `${handleClassName} ${handleForMaxClassName}`),
        ]);

        this.setValues(values);

        this._lineEl.appendChild(this._fillEl);
        el.appendChild(this._lineEl);
        el.classList.add(blockName);

        startEventsList.forEach((eventName) => {
            _on(el, eventName, `${blockName}__handle`, this._handleDown);
        });

        inputs.forEach((inputEl) => {
            _on(inputEl, 'change', this._changeHandler);
        });
    }

    getValues = () => [Math.round(this._values[0]), Math.round(this._values[1])];

    setValues = (values) => {
        this._values = [
            _isNumber(values[0]) ? this._ensureValidValue(values[0]) : this._options.minValue,
            _isNumber(values[1]) ? this._ensureValidValue(values[1]) : this._options.maxValue,
        ];

        if (this._values[0] > this._values[1]) {
            this._values[0] = this._values[1];
        }

        this._setMin(this._values[0]);
        this._setMax(this._values[1]);
    };

    _changeHandler = (e) => {
        const inputEl = e.target;
        const beforeValue = this._serializeValue(this._values);

        if (this._handledEl) {
            return;
        }

        if (document.activeElement === inputEl) {
            inputEl.blur();
        }

        if (inputEl === this._inputMinEl) {
            this._setMin(this._tryParseInputValue(inputEl.value, this._values[0]));
        } else if (e.target === this._inputMaxEl) {
            let inputValue = inputEl.value;

            if (this._options.maxValueLabel && this._values[1] === this._options.maxValue) {
                inputValue = inputEl.value.replace(/[^0-9]/g, '');
            }

            this._setMax(this._tryParseInputValue(inputValue, this._values[1]));
        }

        if (beforeValue !== this._serializeValue(this._values)) {
            this._options.onChange(this.getValues());
        }
    };

    _tryParseInputValue = (str, defaultValue) => {
        const value = parseFloat(str.replace(this._rankRE, ''));

        return _isNumber(value) ? value : defaultValue;
    };

    _handleDown = (e) => {
        e.preventDefault();

        if (e.button && e.button !== 0) { return; }

        const handledEl = e._caughtTarget_;
        const handledRect = handledEl.getBoundingClientRect();
        const activeEl = document.activeElement;

        this._handledEl = handledEl;
        this._handleOffset = handledRect.left + handledRect.width / 2 - this._getClientX(e);
        this._startValues = this._serializeValue(this._values);
        this._canChangeHandle = (this._values[0] === this._values[1]);

        if (activeEl === this._inputMinEl) {
            this._setMin(this._values[0]);
            this._inputMinEl.blur();
        } else if (activeEl === this._inputMaxEl) {
            this._setMax(this._values[1]);
            this._inputMaxEl.blur();
        } else if (activeEl && activeEl.blur) {
            activeEl.blur();
        }

        moveEventsList.forEach((eventName) => { _on(document, eventName, this._handleMove); });
        endEventsList.forEach((eventName) => { _on(document, eventName, this._handleEnd); });
    };

    _getClientX = (e) => {
        const COORDINATE = 'x';
        const propName = `client${COORDINATE.toUpperCase()}`;

        let pageCoordinate = 0;

        if (typeof e[propName] !== 'undefined') {
            pageCoordinate = e[propName];
        } else if (e.touches && e.touches[0] && typeof e.touches[0][propName] !== 'undefined') {
            pageCoordinate = e.touches[0][propName];
        } else if (e.currentPoint && typeof e.currentPoint[COORDINATE] !== 'undefined') {
            pageCoordinate = e.currentPoint[COORDINATE];
        }

        return pageCoordinate;
    };

    _handleMove = (e) => {
        if (this._handledEl) {
            const lineRect = this._lineEl.getBoundingClientRect();
            const ratio = (this._getClientX(e) + this._handleOffset - lineRect.left) / lineRect.width;
            const value = this._options.minValue + ratio * (this._options.maxValue - this._options.minValue);

            if (this._handledEl.classList.contains(handleForMinClassName)) {
                this._setMin(value);
                this._options.onSlide(this.getValues());
            } if (this._handledEl.classList.contains(handleForMaxClassName)) {
                if (this._canChangeHandle === true && value < this._values[0]) {
                    this._handledEl = this._el.querySelector(`.${handleClassName}.${handleForMinClassName}`);
                    this._setMin(value);
                } else {
                    this._setMax(value);
                }

                this._options.onSlide(this.getValues());
            }

            this._canChangeHandle = false;
        } else {
            this._handleEnd(e);
        }

        e.preventDefault();
    };

    _handleEnd = (e) => {
        const startValues = this._startValues;

        e.preventDefault();

        moveEventsList.forEach(eventName => _off(document, eventName, this._handleMove));
        endEventsList.forEach(eventName => _off(document, eventName, this._handleEnd));

        this._handledEl = null;
        this._startValues = null;
        this._handleOffset = null;
        this._canChangeHandle = null;

        if (this._serializeValue(this._values) !== startValues) {
            this._options.onChange(this.getValues());
        }
    };

    _serializeValue = values => values.join('|');

    _setMin = (rawValue) => {
        const value = Math.min(this._ensureValidValue(rawValue), this._values[1]);
        const fraction = value / (this._options.maxValue - this._options.minValue);

        this._fillEl.style.left = `${(fraction * 100).toFixed(3)}%`;
        this._values[0] = value;

        this._setInputValue(this._inputMinEl, value);
    };

    _setMax = (rawValue) => {
        const value = Math.max(this._ensureValidValue(rawValue), this._values[0]);
        const fraction = 1 - value / (this._options.maxValue - this._options.minValue);

        this._fillEl.style.right = `${(fraction * 100).toFixed(3)}%`;
        this._values[1] = value;

        if (this._options.maxValueLabel && value === this._options.maxValue) {
            this._inputMaxEl.value = this._options.maxValueLabel;
        } else {
            this._setInputValue(this._inputMaxEl, value);
        }
    };

    _ensureValidValue = (value) => {
        if (value < this._options.minValue) {
            return this._options.minValue;
        }

        if (value > this._options.maxValue) {
            return this._options.maxValue;
        }

        return value;
    };

    _setInputValue = (inputEl, value) => {
        const intValue = String(Math.round(value));
        const valueLength = intValue.length;

        let viewValue = '';

        for (let i = 1; i <= valueLength; i += 1) {
            viewValue += intValue[i - 1];

            if (i !== valueLength && i % 3 === valueLength % 3) {
                viewValue += this._rankSymbol;
            }
        }

        inputEl.value = this._options.pattern.replace('{{value}}', viewValue);
    };
}

export default RangeSlider;
