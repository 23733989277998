import { _getData } from '../../common/_core';
import { _off, _on } from '../../common/_events';
import { _getCurrentUrl, _getPreviousPath } from '../../common/_history';
import { scroll as scrollPage } from '../../common/_scroll-page';
import { StorageSession } from '../../common/_storage-session';
import HeadersHeightManager from '../../common/_headers-height-manager';

const ELEMENT_OFFSET = 15;

const blockName = 'entity-anchor';
const storageKey = '__entity-anchor__';

function processEntityAnchor() {
    const entityEl = document.querySelector(`.js-${blockName}.${blockName}_entity`);

    if (entityEl) {
        const previousPath = _getPreviousPath();
        const entityId = _getData(entityEl, 'entityAnchor');

        if (previousPath.length > 1 && entityId) {
            const prevPageUrl = previousPath[previousPath.length - 2];
            StorageSession.set(storageKey, JSON.stringify([prevPageUrl, entityId]));
        }

        return;
    }

    const entityAnchorRawData = StorageSession.get(storageKey);

    let storedPath;
    let storedId;

    StorageSession.remove(storageKey);

    try {
        [storedPath, storedId] = JSON.parse(entityAnchorRawData);
    } catch (e) {}

    if (storedPath !== _getCurrentUrl()) {
        return;
    }

    const targetEl = document.querySelector(`.js-${blockName}[data-entity-anchor="${storedId}"]`);
    let targetScrollTop;

    if (!targetEl) {
        return;
    }

    const performScroll = () => {
        const targetElRect = targetEl.getBoundingClientRect();
        const scrollTop = window.pageYOffset + targetElRect.top - ELEMENT_OFFSET - HeadersHeightManager.getHeight();

        if (scrollTop !== targetScrollTop) {
            targetScrollTop = scrollTop;

            scrollPage(targetScrollTop, {
                jump: 200,
                onDone: (isCanceled) => {
                    if (!isCanceled) {
                        HeadersHeightManager.removeStateChangeHandler(performScroll);
                        setTimeout(() => _off(document, '_ads-banner-loaded', performScroll), 500);

                        targetScrollTop = null;
                    }
                },
            });
        }
    };

    _on(document, '_ads-banner-loaded', performScroll);
    HeadersHeightManager.addStateChangeHandler(performScroll);

    performScroll();
}

if (StorageSession.isAvailable()) {
    processEntityAnchor();
}
