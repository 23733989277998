import components from '../../common/_components';
import { _getData, _toArray } from '../../common/_core';
import { _on } from '../../common/_events';
import ScrollHorizontal from '../../common/_scroll-horizontal';
import { Dots } from '../dots/dots';

import './deals-similar-deals.scss';

const blockName = 'deals-similar-deals';
const listSelector = `.js-${blockName}__list`;
const listInnerSelector = `.js-${blockName}__list-inner`;
const listItemSelector = `.js-${blockName}__list-item`;
const dotsSelector = `.js-${blockName}__dots`;

class DealsSimilarDeals {
    constructor(el) {
        this._slides = el.querySelectorAll(listItemSelector);

        if (this._slides.length > 1) {
            const listEl = el.querySelector(listSelector);
            const listInnerEl = el.querySelector(listInnerSelector);

            this._scroll = new ScrollHorizontal(listEl, listInnerEl, listItemSelector, {
                onSlideChange: this._slideChangeHandler,
            });

            this._buildDots(el.querySelector(dotsSelector));

            _on(el, 'click', `.js-${blockName}__arrow`, this._onArrowClick, { passive: true });
        }
    }

    _slideChangeHandler = (currentSlideEl) => {
        if (this._dots) {
            this._dots.select(ScrollHorizontal.getSlideId(currentSlideEl));
        }
    };

    _onArrowClick = (e) => {
        const arrowEl = e._caughtTarget_;
        const direction = _getData(arrowEl, 'direction');

        if (direction === 'prev') {
            this._scroll.moveLeft();
        } else if (direction === 'next') {
            this._scroll.moveRight();
        }
    };

    _buildDots = (dotsEl) => {
        const currentSlideId = this._scroll.getCurrentSlideId();

        this._dots = new Dots(dotsEl, this._onDotActive);

        _toArray(this._slides).forEach((slideEl) => {
            const slideId = ScrollHorizontal.getSlideId(slideEl);
            this._dots.addDot(slideId, _getData(slideEl, 'itemLabel'), slideId === currentSlideId);
        });

        this._dots.build();
    };

    _onDotActive = (id) => {
        this._scroll.moveTo(id);
    };
}

components.add(`js-${blockName}`, element => new DealsSimilarDeals(element));
