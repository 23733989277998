import { _getMouseWheelEvent, _off, _on } from './_events';
import HeadersHeightManager from './_headers-height-manager';

const CHECK_TIMEOUT = 100;
const SCROLL_DURATION = 300;

let index = 0;
let scrollingData;

function _getScrollY() {
    return (document.scrollingElement || document.documentElement).scrollTop;
}

function _getScrollX() {
    return (document.scrollingElement || document.documentElement).scrollLeft;
}

function _setScrollTop(targetScrollTop) {
    window.scrollTo(_getScrollX(), targetScrollTop);
}

function _nativeSmoothScrollTo(targetScrollTop) {
    window.scrollTo({
        top: targetScrollTop,
        left: _getScrollX(),
        behavior: 'smooth',
    });
}

/* Polyfill for smooth scrolling in IE, Edge & Safari */
function _polyfilledSmoothScrollTo(targetScrollTop) {
    const scrollID = scrollingData.id;
    const animateScroll = () => {
        if (!scrollingData || scrollingData.id !== scrollID) {
            return;
        }

        const currentTime = Date.now();
        const timeOffset = currentTime - scrollingData.startTime;
        const position = timeOffset / SCROLL_DURATION;

        if (position < 1) {
            _setScrollTop(Math.round(scrollingData.from + (scrollingData.to - scrollingData.from) * position));
            window.requestAnimationFrame(animateScroll);
        } else {
            _setScrollTop(targetScrollTop);
        }
    };

    window.requestAnimationFrame(animateScroll);
}

function _markAsTouched() {
    if (scrollingData) {
        scrollingData.isTouched = true;
    }
}

function _cancelScrolling() {
    clearTimeout(scrollingData.timeout);
    scrollingData.runDoneCallback(false);
}

function scroll(rawScrollTop, options = {}) {
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    const initialScrollTop = _getScrollY();
    const scrollTop = Math.round(rawScrollTop);
    const startTime = Date.now();
    const scrollID = `page-scrolling-${startTime}-${index += 1}`;
    const {
        jump = null,
        onDone = () => {},
    } = options;

    window.history.scrollRestoration = 'manual';

    if (scrollingData) {
        _cancelScrolling();
    }

    _on(document, _getMouseWheelEvent(), _markAsTouched, { passive: true });
    _on(document, 'touchstart', _markAsTouched, { passive: true });

    if (jump) {
        let jumpScrollTop = null;

        if (scrollTop > initialScrollTop) {
            if (scrollTop - jump > initialScrollTop) {
                jumpScrollTop = scrollTop - jump;
            }
        } else if (scrollTop < initialScrollTop) {
            if (scrollTop + jump < initialScrollTop) {
                jumpScrollTop = scrollTop + jump;
            }
        }

        if (jumpScrollTop !== null) {
            delete options.jump;

            _setScrollTop(jumpScrollTop);
            setTimeout(() => scroll(scrollTop, options), 30);

            return;
        }
    }

    const check = () => {
        if (!scrollingData) {
            return;
        }

        if (scrollID !== scrollingData.id) {
            _cancelScrolling();
            return;
        }

        const docElement = document.documentElement;
        const currentScrollTop = Math.round(_getScrollY());
        const windowHeight = docElement.clientHeight;
        const documentHeight = docElement.scrollHeight;
        const isTimeOut = (Date.now() - scrollingData.startTime) > 4000;

        if (scrollTop === currentScrollTop
            || currentScrollTop <= 0
            || currentScrollTop + windowHeight >= documentHeight
            || isTimeOut
            || scrollingData.isTouched === true
        ) {
            scrollingData.runDoneCallback(true);
        } else {
            scrollingData.timeout = setTimeout(check, CHECK_TIMEOUT);
        }
    };

    scrollingData = {
        id: scrollID,
        startTime,
        from: initialScrollTop,
        to: scrollTop,
        runDoneCallback: (isSuccess) => {
            _off(document, _getMouseWheelEvent(), _markAsTouched);
            _off(document, 'touchstart', _markAsTouched);

            scrollingData = null;
            onDone(isSuccess !== true);
        },
        timeout: setTimeout(check, CHECK_TIMEOUT),
        isTouched: false,
    };

    if (supportsNativeSmoothScroll) {
        _nativeSmoothScrollTo(scrollTop);
    } else {
        _polyfilledSmoothScrollTo(scrollTop);
    }
}

function isInProgress() {
    return Boolean(scrollingData);
}

function scrollToTop(el, options = {}) {
    const scrollTop = _getScrollY();
    const targetScrollTop = el.getBoundingClientRect().top + scrollTop - HeadersHeightManager.getHeight();

    if (scrollTop > targetScrollTop) {
        const onHeaderPinStateChange = () => scrollToTop(el, options);

        HeadersHeightManager.addStateChangeHandler(onHeaderPinStateChange);
        scroll(targetScrollTop, {
            onDone: (isCanceled) => {
                HeadersHeightManager.removeStateChangeHandler(onHeaderPinStateChange);

                if (options.onDone && !isCanceled) {
                    options.onDone();
                }
            },
            jump: options.jump,
        });
    }
}

export {
    scroll,
    isInProgress,
    scrollToTop,
};
