import { _off, _on, _onTransitionEnd } from '../../common/_events';
import { _detachEl, _getData, _reflow } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { _getSvgIcon } from '../../common/_svg-icon';
import Overlay from '../../components.theme/overlay/overlay';
import './full-screen-modal.scss';

const blockName = 'full-screen-modal';

class FullScreenModal {
    constructor({ onClose, forMobileOnly } = {}) {
        this._isOpened = false;
        this._closeHander = onClose;
        this._overlayOptions = { forMobileOnly };
    }

    open = (el) => {
        if (this._isOpened) {
            return;
        }

        if (!this._overlay) {
            this._overlay = new Overlay(this._overlayOptions);
        }

        if (!this._modalEl) {
            this._buildModal();
        }

        this._bodyEl.innerHTML = '';
        this._bodyEl.appendChild(el);

        this._overlay.show();

        _on(document, this._overlay.getCloseEvent(), this.close, { once: true });
        document.body.appendChild(this._modalEl);

        _reflow(this._modalEl);
        this._modalEl.classList.add('shown');
    };

    close = () => {
        _off(document, this._overlay.getCloseEvent(), this.close);
        _onTransitionEnd(this._modalEl, this._onCloseTransitionEnd);

        this._modalEl.classList.remove('shown');
    }

    getBody = () => this._bodyEl;

    _onCloseTransitionEnd = () => {
        this._overlay.hide();
        _detachEl(this._modalEl);

        if (this._closeHander) {
            this._closeHander();
        }
    }

    _buildModal = () => {
        const bodyEl = _createEl('div', `${blockName}__body`);
        const dataEl = document.querySelector(`.js-${blockName}__data`);
        const logoUrl = _getData(dataEl, 'logoUrl');
        const closeButtonEl = _createEl('i', `${blockName}__close`, [
            _getSvgIcon('close', `${blockName}__close-icon`),
        ]);

        this._bodyEl = bodyEl;
        this._modalEl = _createEl('div', blockName, [
            _createEl('div', `${blockName}__modal`, [
                _createEl('div', `${blockName}__heading`, [
                    _createEl('div', 'container', [
                        _createEl('div', `${blockName}__heading-inner`, [
                            _createEl('img', `${blockName}__heading-logo lazyload`, {
                                'data-src': logoUrl,
                                'alt': '',
                            }),
                        ]),
                    ]),
                ]),
                closeButtonEl,
                bodyEl,
            ]),
        ]);

        _on(closeButtonEl, 'click', this.close);

        _on(this._modalEl, 'touchstart', this._onClickHandler);
        _on(this._modalEl, 'click', this._onClickHandler);
    }

    _onClickHandler = (e) => {
        if (e.target && e.target.classList.contains(blockName)) {
            this.close();
        }
    };
}

export { FullScreenModal };
