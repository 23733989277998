import bugsnag from './_bugsnag-client';

function getSelectedText() {
    let selectedText = '';

    try {
        selectedText = window.getSelection().toString();
    } catch (e) {
        bugsnag.notify(e.toString());
    }

    return selectedText;
}

/**
 *
 * This code is based on clipboardjs (https://clipboardjs.com/, https://github.com/zenorocha/clipboard.js),
 * because other event should be used and latest commit was made more than 2 years ago
 */
function selectEl(el) {
    if (el.nodeName === 'SELECT') {
        el.focus();
    } else if (el.nodeName === 'INPUT' || el.nodeName === 'TEXTAREA') {
        const isReadOnly = el.hasAttribute('readonly');

        if (!isReadOnly) {
            el.setAttribute('readonly', '');
        }

        el.select();
        el.setSelectionRange(0, el.value.length);

        if (!isReadOnly) {
            el.removeAttribute('readonly');
        }
    } else {
        if (el.hasAttribute('contenteditable')) {
            el.focus();
        }

        const selection = window.getSelection();
        const range = document.createRange();

        range.selectNodeContents(el);
        selection.removeAllRanges();
        selection.addRange(range);
    }
}

let _fakeInputEl;
let _fakeHandler;
let _fakeHandlerCallback;

/**
 * @see https://github.com/zenorocha/clipboard.js/blob/master/src/clipboard-action.js
 *
 * Only removes the fake element after another click event, that way
 * a user can hit `Ctrl+C` to copy because selection still exists.
 */
function _removeFakeInput() {
    if (_fakeHandler) {
        document.body.removeEventListener('click', _fakeHandlerCallback);
        _fakeHandler = null;
        _fakeHandlerCallback = null;
    }

    if (_fakeInputEl) {
        document.body.removeChild(_fakeInputEl);
        _fakeInputEl = null;
    }
}

/**
 *
 * @see https://github.com/zenorocha/clipboard.js/blob/master/src/clipboard-action.js
 */
function _createFakeInput() {
    const isRTL = document.documentElement.getAttribute('dir') === 'rtl';

    _removeFakeInput();

    _fakeHandlerCallback = () => _removeFakeInput();
    _fakeHandler = document.body.addEventListener('click', _fakeHandlerCallback) || true;

    _fakeInputEl = document.createElement('textarea');
    // Prevent zooming on iOS
    _fakeInputEl.style.fontSize = '12pt';
    // Reset box model
    _fakeInputEl.style.border = '0';
    _fakeInputEl.style.padding = '0';
    _fakeInputEl.style.margin = '0';
    // Move element out of screen horizontally
    _fakeInputEl.style.position = 'absolute';
    _fakeInputEl.style[isRTL ? 'right' : 'left'] = '-9999px';
    // Move element to the same position vertically
    const yPosition = window.pageYOffset || document.documentElement.scrollTop;
    _fakeInputEl.style.top = `${yPosition}px`;

    _fakeInputEl.setAttribute('readonly', '');
    document.body.appendChild(_fakeInputEl);

    return _fakeInputEl;
}

function copyText(text, { onSuccess = () => {}, onError = () => {}, always = () => {} }) {
    const inputEl = _createFakeInput();
    let isSucceeded = false;

    inputEl.value = text;
    selectEl(inputEl);

    try {
        document.execCommand('copy');
        isSucceeded = true;
    } catch (err) {
        bugsnag.notify(err);
    }

    if (isSucceeded) {
        onSuccess();
    } else {
        onError();
    }

    always();
}

export {
    getSelectedText as _getSelectedText,
    selectEl as _selectEl,
    copyText as _copyText,
};
