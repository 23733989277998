import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _getCSSProp, _getData } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { FixedPanelsManager } from '../../common/_fixed-panels-manager';

import './privacy-button.scss';
import { scroll as scrollPage } from '../../common/_scroll-page';

const blockName = 'privacy-button';
const isShownMod = `${blockName}_is-shown`;

class PrivacyButton {
    constructor(rootEl) {
        this._rootEl = rootEl;
        this._label = _getData(rootEl, 'buttonLabel');
        this._privacyUrl = _getData(rootEl, 'privacyUrl');

        this._isCreated = false;
        this._isVisible = false;

        _on(window, 'scroll', this._handleScroll);
        this._handleScroll();
    }

    _handleScroll = () => {
        const isVisible = this._isVisible;

        if (window.pageYOffset > window.innerHeight * 2) {
            if (!isVisible) {
                this._show();
            }
        } else if (isVisible) {
            this._hide();
        }
    };

    _show = () => {
        if (!this._isCreated) {
            this._createButton();
            this._isCreated = true;
        }

        this._rootEl.classList.add(isShownMod);
        this._isVisible = true;

        FixedPanelsManager.dispatchUpdate();
    };

    _hide = () => {
        this._rootEl.classList.remove(isShownMod);
        this._isVisible = false;

        FixedPanelsManager.dispatchUpdate();
    };

    _createButton = () => {
        const buttonEl = _createEl('button', `btn ${blockName}__button`, {
            'aria-label': this._label,
            'title': this._label,
        });
        const getButtonHeight = () => {
            if (this._isVisible) {
                return buttonEl.offsetHeight + parseFloat(_getCSSProp(buttonEl, 'bottom'));
            }

            return 0;
        };
        const bottomOffsetChangeHandler = (offset) => {
            buttonEl.style.transform = `translateY(-${offset}px)`;
        };

        buttonEl.innerHTML = `<span class="${blockName}__icon"></span>`;

        _on(buttonEl, 'click', this._openPrivacy);
        this._rootEl.appendChild(buttonEl);

        FixedPanelsManager.setupPanel(20, false, getButtonHeight, bottomOffsetChangeHandler);
    };

    _openPrivacy = (e) => {
        window.open(this._privacyUrl, '_blank').focus();

        e.preventDefault();
    };
}

components.add(`js-${blockName}`, el => new PrivacyButton(el));
