import components from '../../common/_components';
import { _sendRequest } from '../../common/_ajax';
import { _closest, _getData } from '../../common/_core';
import { _on } from '../../common/_events';
import { commentsWidgetModalShow, commentsWidgetRemoveComment } from '../comments-widget/comments-widget';
import './comments-list-item.scss';

const blockName = 'comments-list-item';

function likeClickHandler(e) {
    const buttonEl = e._caughtTarget_;
    const likeUrl = _getData(buttonEl, 'likeUrl');

    if (!buttonEl.classList.contains('disabled') && !buttonEl.classList.contains('liked')) {
        buttonEl.classList.add('disabled');

        _sendRequest({
            method: 'POST',
            url: likeUrl,
            success: (response) => {
                if (response.success === true) {
                    const likesCountEl = buttonEl.querySelector(`.js-${blockName}__likes-count`);
                    const currentValue = parseInt(likesCountEl.textContent, 10);

                    likesCountEl.innerText = String(currentValue + 1);
                    buttonEl.classList.add('liked');
                }
            },
            always: () => {
                buttonEl.classList.remove('disabled');
            },
        });
    }
}

function replyToComment(commentEl) {
    const commentId = _getData(commentEl, 'commentId');
    const userNameEl = commentEl.querySelector(`.js-${blockName}__heading-user-name`);
    const commentBodyEl = commentEl.querySelector(`.js-${blockName}__comment`);

    commentsWidgetModalShow({
        parentId: commentId,
        parentUsername: userNameEl.innerText.trim(),
        parentCommentHTML: commentBodyEl.innerHTML,
    });
}

function editComment(commentEl, { url, modalTitle, sendLabel }) {
    const parentBodyEl = commentEl.querySelector(`.js-${blockName}__parent-body`);
    const modalOptions = {
        url,
        modalTitle,
        sendLabel,
        commentHTML: commentEl.querySelector(`.js-${blockName}__comment`).innerText.trim(),
        onPositingSuccess: (newCommentEl) => {
            commentEl.parentNode.replaceChild(newCommentEl, commentEl);
        },
        onClose: () => {
            commentEl.classList.remove(`${blockName}_is-active`);
        },
    };

    commentEl.classList.add(`${blockName}_is-active`);

    if (parentBodyEl) {
        const parentHeadingEl = commentEl.querySelector(`.js-${blockName}__parent-heading`);

        modalOptions.parentUsername = parentHeadingEl.innerText;
        modalOptions.parentCommentHTML = parentBodyEl.innerHTML;
    }

    commentsWidgetModalShow(modalOptions);
}

function deleteComment(commentEl, deleteUrl) {
    // eslint-disable-next-line no-alert
    if (window.confirm('Are you sure?')) {
        _sendRequest({
            method: 'POST',
            headers: { 'X-HTTP-Method-Override': 'DELETE' },
            url: deleteUrl,
            always: (isSuccess, response) => {
                if (isSuccess && response.success === true) {
                    commentsWidgetRemoveComment(commentEl);
                } else {
                    // eslint-disable-next-line no-alert
                    window.alert('Oops, an error occurred, please try again.');
                }
            },
        });
    }
}

function buttonLinkClickHandler(e) {
    const buttonEl = e._caughtTarget_;
    const commentEl = _closest(buttonEl, `.js-${blockName}`);
    const deleteUrl = _getData(buttonEl, 'deleteUrl');
    const editUrl = _getData(buttonEl, 'editUrl');

    if (deleteUrl) {
        deleteComment(commentEl, deleteUrl);
    } else if (editUrl) {
        editComment(commentEl, {
            url: editUrl,
            modalTitle: _getData(buttonEl, 'editTitle'),
            sendLabel: _getData(buttonEl, 'editSend'),
        });
    } else {
        replyToComment(commentEl);
    }
}

let hasAttached = false;

components.add(`js-${blockName}`, () => {
    if (!hasAttached) {
        _on(document, 'click', `.js-${blockName}__likes`, likeClickHandler);
        _on(document, 'click', `.js-${blockName}__button-link`, buttonLinkClickHandler);

        hasAttached = true;
    }
});
