import components from '../../common/_components';
import { _closest } from '../../common/_core';
import { _on, _off } from '../../common/_events';
import StickyElement from '../../common/_sticky-element';

import './layout-sidebar.scss';

const blockName = 'layout-sidebar';
const asideForDesktopMod = `${blockName}_aside-for-desktop`;

components.add(`js-${blockName}`, (el) => {
    if (!el.classList.contains(asideForDesktopMod)) {
        return;
    }

    const checkForNonInfinityScrollPage = () => {
        const noInfinityScrollPageEl = el.querySelector(`.js-${blockName}__non-infinity-load-page`);

        if (noInfinityScrollPageEl) {
            el.classList.remove(asideForDesktopMod);
            _off(el, '_nodes-inserted', checkForNonInfinityScrollPage);
        }
    };

    _on(el, '_nodes-inserted', checkForNonInfinityScrollPage);
});

components.add(`js-${blockName}__aside-sticky`, (holderEl) => {
    const blockEl = _closest(holderEl, `.js-${blockName}`);
    const stickyEl = holderEl.querySelector(`.js-${blockName}__aside-sticky-el`);

    if (blockEl && stickyEl) {
        const sticky = new StickyElement(stickyEl, holderEl, {
            restrictionAreaEl: blockEl,
        });

        _on(stickyEl, '_ads-banner-loaded', () => sticky.update());
    }
});
