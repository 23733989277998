import { _getData } from '../../common/_core';
import { _on, _off, _trigger } from '../../common/_events';
import components from '../../common/_components';
import HeadersHeightManager from '../../common/_headers-height-manager';
import Headroom from '../../vendors.theme/_headroom';
import LayoutSearch from '../../components.theme/layout-search/layout-search';
import { layoutSearchInput } from '../layout-search/__input';
import { layoutSearchButtonSearch } from '../layout-search/__button-search';

import './layout-header-mobile.scss';

const blockName = 'layout-header-mobile';
const searchMod = `${blockName}_search-mod`;
const wrapperUnpinnedMod = `${blockName}__fixed_unpinned`;

const HEADERS_HEIGHT_STATE_PINNED = 'pinned';
const HEADERS_HEIGHT_STATE_UNPINNED = 'unpinned';

class LayoutHeaderMobile {
    constructor(el) {
        this._el = el;
        this._fixedEl = el.querySelector(`.js-${blockName}__fixed`);

        this._isSearchInputFocused = false;

        HeadersHeightManager.addStateChangeHandler(this._headerStateChangeHandler);
        HeadersHeightManager.setupHeader(this._fixedEl, 0, _getData(el, 'mobileWidthLimit'), this._getHeaderHeight);
    }

    _getHeaderHeight = () => {
        if (this._fixedEl.classList.contains(wrapperUnpinnedMod)) {
            return 0;
        }

        return this._fixedEl.getBoundingClientRect().height;
    }

    _headerStateChangeHandler = (panel, state) => {
        if (panel.rootEl !== this._fixedEl) {
            return;
        }

        if (state === HeadersHeightManager.STATE_ACTIVATED && !this._isHeaderActive) {
            const options = { passive: true };

            _on(this._el, 'click', `.js-${blockName}__search-button`, this._searchButtonClickHandler, options);
            _on(this._el, 'click', `.js-${blockName}__menu-button`, this._menuButtonClickHandler, options);

            this._isHeaderActive = true;
            this._updateHeadroom();
        } else if (state === HeadersHeightManager.STATE_DEACTIVATED && this._isHeaderActive) {
            this._isHeaderActive = false;

            _off(this._el, 'click', `.js-${blockName}__search-button`, this._searchButtonClickHandler);
            _off(this._el, 'click', `.js-${blockName}__menu-button`, this._menuButtonClickHandler);

            this._closeSearch();
            this._updateHeadroom();
        }
    }

    _closeSearch = () => {
        _off(document, 'pointerdown', this._documentHandlerForSearch);

        if (this._layoutSearch) {
            this._layoutSearch.hideResults();
        }

        this._el.classList.remove(searchMod);
    }

    _documentHandlerForSearch = (e) => {
        const searchButtonEl = this._el.querySelector(`.js-${blockName}__search-button`);
        const searchEl = this._el.querySelector(`.js-${blockName}__search`);
        const targetEl = e.target;

        if (!searchButtonEl.contains(targetEl)
            && searchButtonEl !== targetEl
            && !searchEl.contains(targetEl)
            && searchEl !== targetEl) {
            this._closeSearch();
        }
    }

    _menuButtonClickHandler = () => {
        _trigger(document, 'menu-mobile_opened');
    }

    _searchButtonClickHandler = () => {
        if (this._el.classList.contains(searchMod)) {
            this._closeSearch();
        } else {
            if (!this._layoutSearch) {
                this._layoutSearch = this._getLayoutSearch();
            }

            this._el.classList.add(searchMod);

            this._layoutSearch.emptyInput();
            this._layoutSearch.focusInput();

            _on(document, 'pointerdown', this._documentHandlerForSearch, { passive: true });
        }
    }

    _onSearchInputFocus = () => {
        this._isSearchInputFocused = true;
        this._updateHeadroom();
    }

    _onSearchInputBlur = () => {
        this._isSearchInputFocused = false;
        this._updateHeadroom();
    }

    _updateHeadroom = () => {
        if (this._isHeaderActive && !this._isSearchInputFocused && !this._headroom) {
            this._headroom = new Headroom(this._fixedEl, {
                offset: 60,
                tolerance: 10,
                classes: {
                    pinned: `${blockName}__fixed_pinned`,
                    unpinned: wrapperUnpinnedMod,
                },
                onPin: () => {
                    HeadersHeightManager.dispatchUpdate(HEADERS_HEIGHT_STATE_PINNED);
                },
                onUnpin: () => {
                    this._closeSearch();
                    HeadersHeightManager.dispatchUpdate(HEADERS_HEIGHT_STATE_UNPINNED);
                },
            });

            this._headroom.init();
        } else if (this._headroom) {
            const headroom = this._headroom;

            headroom.destroy();
            /* Fixing lost clearing timeout in Headroom`s destroy */
            setTimeout(() => headroom.destroy(), 100);

            this._headroom = null;
        }
    }

    _getLayoutSearch = () => {
        const buttonLabel = _getData(this._el, 'searchButtonLabel');
        const searchEl = this._el.querySelector(`.js-${blockName}__search-inner`);
        const getInputEl = (...args) => {
            const inputEl = layoutSearchInput(...args);

            _on(inputEl, 'focus', this._onSearchInputFocus);
            _on(inputEl, 'blur', this._onSearchInputBlur);

            return inputEl;
        };

        return new LayoutSearch(searchEl, {
            formElements: [
                { role: LayoutSearch.ELEMENT_ROLE_INPUT, getEl: getInputEl },
                { role: LayoutSearch.ELEMENT_ROLE_SUBMIT, getEl: () => layoutSearchButtonSearch(buttonLabel) },
            ],
            placement: LayoutSearch.PLACEMENT_HEADER_MOBILE,
            onReset: this._closeSearch,
        });
    }
}

components.add(`js-${blockName}`, el => new LayoutHeaderMobile(el));
