import { _sendRequest } from '../../common/_ajax';
import components from '../../common/_components';
import { _getData, _reflow, _detachEl, _parseHTML, _isElementVisible, _closest } from '../../common/_core';
import { _on, _off, _onTransitionEnd } from '../../common/_events';
import { _isStatePushed } from '../../common/_history';
import { _copyText, _getSelectedText, _selectEl } from '../../common/_selection';
import { scroll } from '../../common/_scroll-page';
import Overlay from '../overlay/overlay';
import { _extractActionsFromHash, ACTION_OPEN } from '../clickout/clickout';

import './page-offers-view.scss';

const blockName = 'page-offers-view';
const directLinkClass = 'direct-link';

let buttonResetTimeout;
let selectionTimout = null;

function _clearSelectionTimeout() {
    if (selectionTimout !== null) {
        clearTimeout(selectionTimout);
        selectionTimout = null;
    }
}

function _hasSelectionTimout() {
    return selectionTimout === null;
}

function _startSelectionTimeout(elToSelect) {
    selectionTimout = setTimeout(() => {
        _clearSelectionTimeout();
        _selectEl(elToSelect);
    }, 700);
}

function _onShareButtonsClick(e) {
    const buttonE = e._caughtTarget_;
    const width = _getData(buttonE, 'popupWidth') || '600';
    const height = _getData(buttonE, 'popupHeight') || '500';

    window.open(buttonE.getAttribute('href'), 'share', `top=100,left=100,width=${width},height=${height}`);
    e.preventDefault();
}

function _processCodeEl(codeEl) {
    const interactionEndHandler = (e) => {
        const successClassName = 'success';
        const isFromButton = _closest(e.target, `.js-${blockName}__code-button`) !== null;

        if (e.touches && e.touches.length > 0) {
            return;
        }

        if (isFromButton || !_getSelectedText()) {
            _copyText(_getData(codeEl, 'code'), {
                onSuccess: () => {
                    if (!codeEl.classList.contains(successClassName)) {
                        codeEl.classList.add(successClassName);
                    } else {
                        clearTimeout(buttonResetTimeout);
                    }

                    buttonResetTimeout = setTimeout(() => {
                        codeEl.classList.remove(successClassName);
                    }, 2000);
                },
            });
        }
    };
    const getTextEl = () => codeEl.querySelector(`.js-${blockName}__code-text`);

    _on(codeEl, 'mouseup', interactionEndHandler);
    _on(codeEl, 'touchend', interactionEndHandler);

    _on(codeEl, 'mousedown', () => {
        _clearSelectionTimeout();
        _on(document, 'mouseup', _clearSelectionTimeout);

        _startSelectionTimeout(getTextEl());
    });

    _on(codeEl, 'touchstart', (touchstartEvent) => {
        if (_hasSelectionTimout()) {
            const id = touchstartEvent.changedTouches[0].identifier;

            _on(document, 'touchend', (touchendEnd) => {
                if (touchendEnd.changedTouches[0].identifier === id) {
                    _clearSelectionTimeout();
                }
            });

            _startSelectionTimeout(getTextEl());
        }
    });
}

components.add(`js-${blockName}`, (element) => {
    const overlay = new Overlay();
    const codeEl = element.querySelector(`.js-${blockName}__code`);

    function _hidePopup() {
        element.classList.remove('shown');

        _onTransitionEnd(element, () => {
            _detachEl(element);
            overlay.hide();
        });
    }

    function _clickHandler(e) {
        if (e.target === e.currentTarget) {
            _hidePopup(element);
            _off(element, 'click', _clickHandler);
        }
    }

    overlay.show();

    element.style.display = 'block';
    element.scrollTop = 0;

    _reflow(element);
    element.classList.add('shown');

    _onTransitionEnd(element, () => {
        _on(element, 'click', _clickHandler);
        element.focus();
    });

    _on(element.querySelector(`.js-${blockName}__close`), 'click', _hidePopup);
    _on(element, 'click', `.js-${blockName}__share-link`, _onShareButtonsClick);

    if (codeEl) {
        _processCodeEl(codeEl);
    }
});

function openOffer(offerEl) {
    _sendRequest({
        url: _getData(offerEl, 'offerModalUrl'),
        data: { '_': Date.now() },
        success: (html) => {
            const origin = `${window.location.protocol}//${window.location.host}`;
            const ref = document.referrer;
            /* In most cases the Referrer looks like "gutscheinpony-de.cdn.ampproject.org?...(gutscheinpony.de)...",
             * but in one moment on iPhones in Germany it became "https://ampproject.org/".
             *
             * /https?:\/\/ampproject.org\/?/.test(ref) - quick fix
             */
            const isAMPOrigin = (ref.indexOf('ampproject.org') > -1 && ref.indexOf(window.location.host) > -1)
                || /https?:\/\/ampproject.org\/?/.test(ref);
            const isSameOrigin = document.referrer.indexOf(origin) > -1 || isAMPOrigin;

            _parseHTML(html).forEach((node) => {
                node.classList[isSameOrigin || _isStatePushed() ? 'remove' : 'add'](directLinkClass);
                document.body.appendChild(node);
            });

            components.init();
        },
    });
}

const hashData = _extractActionsFromHash(window.location.hash);

if (hashData.offerEl && hashData.action === ACTION_OPEN) {
    openOffer(hashData.offerEl);

    if (_isElementVisible(hashData.offerEl)) {
        const windowHeight = document.documentElement.clientHeight;
        const elRect = hashData.offerEl.getBoundingClientRect();

        scroll(window.pageYOffset + elRect.top - (windowHeight - elRect.height) / 2, { duration: 0 });
    }
}
