import components from '../../common/_components';
import { _sendRequest } from '../../common/_ajax';
import { _detachEl, _getCSSProp, _getData, _setData, _parseHTML, _reflow, _removeData } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { _off, _on, _onTransitionEnd } from '../../common/_events';
import Overlay from '../../components.theme/overlay/overlay';
import { setAuthCloseAction, setAuthDoneAction } from '../auth-modal-body/auth-modal-body';
import './auth-modal.scss';

const blockName = 'auth-modal';

class AuthModal {
    constructor(url) {
        this._modalUrl = url;

        this._overlay = new Overlay();
        this._modalBodyEl = _createEl('div', 'container');
        this._modalEl = _createEl('div', blockName, [
            _createEl('div', `${blockName}__inner`, [
                this._modalBodyEl,
            ]),
        ]);

        _on(this._modalEl, 'touchstart', this._onModalClickHandler);
        _on(this._modalEl, 'click', this._onModalClickHandler);
    }

    open = () => {
        if (!this._modalEl.classList.contains('shown')) {
            this._modalBodyRequest = _sendRequest({
                url: this._modalUrl,
                success: this._onModalBodySuccess,
                error: this.close,
            });

            this._overlay.show();
            this._setModalContent(this._getLoadingEl());

            _on(document, this._overlay.getCloseEvent(), this.close, { once: true });
            document.body.appendChild(this._modalEl);

            _reflow(this._modalEl);
            this._modalEl.classList.add('shown');
        }
    }

    close = () => {
        if (this._modalEl.classList.contains('shown')) {
            _off(document, this._overlay.getCloseEvent(), this.close);
            _onTransitionEnd(this._modalEl, this._onCloseTransitionEnd);

            if (this._modalBodyRequest) {
                this._modalBodyRequest.abort();
            }

            this._modalEl.classList.remove('shown');
        }
    }

    closeForAnotherModal = () => {
        this.close();
        this._overlay.hide();
    }

    _onCloseTransitionEnd = () => {
        this._overlay.hide();
        _detachEl(this._modalEl);
    }

    _onModalClickHandler = (e) => {
        const targetEl = e.target;
        const isTransparent = this._isTransparent(this._modalEl);

        if (isTransparent && (!this._modalBodyEl.contains(targetEl) || targetEl === this._modalBodyEl)) {
            this.close();
        }
    }

    _isTransparent = (el) => {
        const bgColor = _getCSSProp(el, 'backgroundColor');
        const rgbaRE = /rgba\(\d+,\s*\d+,\s*\d+,\s*(\d+)\)/i;
        const hexRE = /#[a-z0-9]{6}([a-z0-9]{2})/i;

        if (rgbaRE.test(bgColor)) {
            const rawValue = bgColor.match(rgbaRE)[1];
            const value = parseFloat(rawValue);

            return value < 1;
        }

        if (hexRE.test(bgColor)) {
            return bgColor.match(hexRE)[1].toUpperCase() !== 'FF';
        }

        return bgColor === 'transparent';
    }

    _onModalBodySuccess = (responseHTML) => {
        this._setModalContent(_parseHTML(responseHTML)[0]);
        components.init();
    }

    _setModalContent = (el) => {
        this._modalBodyEl.innerHTML = '';
        this._modalBodyEl.appendChild(el);
    }

    _getLoadingEl = () => {
        const loadingEl = _parseHTML('<i class="fa fa-spin fa-circle-notch"></i>')[0];

        return _createEl('div', `${blockName}__loading`, [
            loadingEl,
        ]);
    }

    static setUp(url, callback) {
        return new AuthModal(url, callback);
    }
}

let authModal;
let successCallbacks = [];

function ensureLogin(successCallback) {
    const dataEl = document.querySelector(`.js-${blockName}__data`);

    if (_getData(dataEl, 'authPassed') === 'true') {
        successCallback();
    } else {
        if (!authModal) {
            authModal = new AuthModal(_getData(dataEl, 'authModalUrl'));

            setAuthCloseAction(() => {
                authModal.close();
                successCallbacks = [];
            });

            setAuthDoneAction(() => {
                authModal.closeForAnotherModal();

                successCallbacks.forEach(cb => cb());
                successCallbacks = [];

                _setData(dataEl, 'authPassed', 'true');
                _removeData(dataEl, 'authModalUrl');
            });
        }

        successCallbacks.push(successCallback);
        authModal.open();
    }
}

export { ensureLogin };
