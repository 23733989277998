import components from '../../common/_components';
import { _on } from '../../common/_events';
import { _getData, _toArray } from '../../common/_core';
import * as scrollPage from '../../common/_scroll-page';
import ScrollHorizontal from '../../common/_scroll-horizontal';
import HeadersHeightManager from '../../common/_headers-height-manager';
import { _replaceState } from '../../common/_history';
import { debounce, throttle } from '../../vendors/_throttle-debounce';

import './page-shops-index.scss';

const blockName = 'page-shops-index';
const carouselInterval = 3500;
const carouselsData = {};

let carouselTimeout = null;

function _processLinkEL(selector, offset) {
    const targetEl = document.querySelector(selector);

    if (targetEl && !scrollPage.isInProgress()) {
        const targetOffsetTop = targetEl.getBoundingClientRect().top + window.pageYOffset;

        scrollPage.scroll(targetOffsetTop - HeadersHeightManager.getHeight() - offset, { jump: 100 });
    }
}

function _linkClickHandler(e) {
    const linkEl = e._caughtTarget_;
    const selector = linkEl.getAttribute('href');

    e.preventDefault();

    _processLinkEL(selector, _getData(linkEl, 'linkOffset'));
    _replaceState(`${document.location.pathname}${document.location.search}${selector}`);
}

const _scheduleCarouselStep = debounce(200, () => {
    const hasVisible = Object.keys(carouselsData)
        .some(letterSymbol => carouselsData[letterSymbol].isActive);

    if (carouselTimeout !== null) {
        clearTimeout(carouselTimeout);
    }

    if (hasVisible) {
        carouselTimeout = setTimeout(_carouselStep, carouselInterval);
    }
});

function _carouselStep() {
    Object.keys(carouselsData).forEach((letterSymbol) => {
        const carouselData = carouselsData[letterSymbol];

        if (carouselData.isActive && !carouselData.onHold) {
            carouselData.carousel.moveRight();
        }
    });
}

function _scrollHandler(listEls) {
    const windowHeight = document.documentElement.clientHeight;
    const headerHeight = HeadersHeightManager.getHeight();
    let hasVisibleItems = false;

    listEls.forEach((listEl) => {
        const letterSymbol = _getData(listEl, 'relatedLetter');
        const elRect = listEl.getBoundingClientRect();
        const listElWidth = Math.round(elRect.width);

        if (elRect.bottom > headerHeight && elRect.top < windowHeight && listElWidth < listEl.scrollWidth) {
            if (!carouselsData[letterSymbol]) {
                const scrollHorizontal = new ScrollHorizontal(listEl, `.js-${blockName}__featured-shops-item`, {
                    listEl: listEl.querySelector(`.js-${blockName}__featured-shops-list`),
                    isInfinity: true,
                    onDragStart: () => {
                        carouselsData[letterSymbol].onHold = true;
                    },
                    onSlideChangeDone: () => {
                        carouselsData[letterSymbol].onHold = false;
                        _scheduleCarouselStep();
                    },
                });

                carouselsData[letterSymbol] = {
                    isActive: true,
                    onHold: false,
                    carousel: scrollHorizontal,
                };

                _on(listEl, 'contextmenu', () => {
                    scrollHorizontal.stopAnimation();
                    carouselsData[letterSymbol].onHold = true;
                });
            } else {
                carouselsData[letterSymbol].isActive = true;
            }

            hasVisibleItems = true;
        } else if (carouselsData[letterSymbol]) {
            carouselsData[letterSymbol].isActive = false;
            carouselsData[letterSymbol].onHold = false;
        }
    });

    if (hasVisibleItems && carouselTimeout === null) {
        carouselTimeout = setTimeout(_carouselStep, carouselInterval);
    } else if (!hasVisibleItems && carouselTimeout !== null) {
        clearTimeout(carouselTimeout);
        carouselTimeout = null;
    }
}

components.add(`js-${blockName}`, (element, api) => {
    const featuredShopsEls = _toArray(element.querySelectorAll(`.js-${blockName}__tab-featured-shops`));
    const decoratedScrollHandler = throttle(200, () => {
        _scrollHandler(featuredShopsEls);
    });

    _on(element, 'click', `.js-${blockName}__links-item`, _linkClickHandler);
    _on(element, 'click', `.js-${blockName}__tab-header-link`, _linkClickHandler);

    _on(window, 'scroll', decoratedScrollHandler);
    _on(window, 'resize', decoratedScrollHandler);

    api.afterAll(() => {
        const locationHash = window.location.hash;
        const linkEl = document.querySelector(`[href="${locationHash}"]`);

        if (linkEl) {
            _processLinkEL(locationHash, _getData(linkEl, 'linkOffset'));
        }

        _scrollHandler(featuredShopsEls);
    });
});
