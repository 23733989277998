import components from '../../common/_components';
import HeadersHeightManager from '../../common/_headers-height-manager';
import { _on, _off } from '../../common/_events';
import { _getData } from '../../common/_core';
import LayoutSearch from '../../components.theme/layout-search/layout-search';
import { layoutSearchInput } from '../layout-search/__input';
import { layoutSearchButtonSearch } from '../layout-search/__button-search';
import './layout-header-desktop.scss';

const blockName = 'layout-header-desktop';

class LayoutHeaderDesktop {
    constructor(el) {
        this._el = el;
        this._fixedEl = el.querySelector(`.js-${blockName}__fixed`);

        HeadersHeightManager.addStateChangeHandler(this._headerStateChangeHandler);
        HeadersHeightManager.setupHeader(this._fixedEl, parseFloat(_getData(el, 'mobileWidthLimit')), Infinity);

        _on(document, 'layout-menu_item-expanded', () => {
            this._closeDropdown();
            this._closeSearch();
        });
    }

    _headerStateChangeHandler = (panel, state) => {
        if (panel.rootEl === this._fixedEl) {
            if (state === HeadersHeightManager.STATE_DEACTIVATED) {
                this._closeDropdown();
                this._closeSearch();

                _off(this._el, 'click', `.js-${blockName}__search-button`, this._searchButtonClickHandler);
                _off(this._el, 'click', `.js-${blockName}__profile-button`, this._profileButtonClickHandler);
            } else if (state === HeadersHeightManager.STATE_ACTIVATED) {
                const options = { passive: true };

                _on(this._el, 'click', `.js-${blockName}__search-button`, this._searchButtonClickHandler, options);
                _on(this._el, 'click', `.js-${blockName}__profile-button`, this._profileButtonClickHandler, options);
            }
        }
    }

    _closeDropdown = () => {
        this._getProfileEl().classList.remove('active');
        _off(document, 'pointerdown', this._documentHandlerForDropdown);
    }

    _documentHandlerForDropdown = (e) => {
        const profileEl = this._getProfileEl();

        if (!profileEl.contains(e.target) && e.target !== profileEl) {
            this._closeDropdown();
        }
    }

    _closeSearch = () => {
        this._el.classList.remove('search-mod');
        _off(document, 'pointerdown', this._documentHandlerForSearch);
    }

    _documentHandlerForSearch = (e) => {
        const searchButtonEl = this._el.querySelector(`.js-${blockName}__search-button`);
        const searchEl = this._el.querySelector(`.js-${blockName}__search`);
        const targetEl = e.target;

        if (!searchButtonEl.contains(targetEl)
            && searchButtonEl !== targetEl
            && !searchEl.contains(targetEl)
            && searchEl !== targetEl) {
            this._closeSearch();
        }
    }

    _getProfileEl = () => {
        if (!this._profileEl) {
            this._profileEl = this._el.querySelector(`.js-${blockName}__profile`);
        }

        return this._profileEl;
    }

    _profileButtonClickHandler = () => {
        const profileEl = this._getProfileEl();

        if (profileEl.classList.contains('active')) {
            this._closeDropdown();
        } else {
            profileEl.classList.add('active');
            _on(document, 'pointerdown', this._documentHandlerForDropdown, { passive: true });
        }
    }

    _searchButtonClickHandler = () => {
        if (this._el.classList.contains('search-mod')) {
            this._closeSearch();
        } else {
            if (!this._layoutSearch) {
                this._layoutSearch = this._getLayoutSearch();
            }

            this._el.classList.add('search-mod');

            this._layoutSearch.emptyInput();
            this._layoutSearch.focusInput();

            _on(document, 'pointerdown', this._documentHandlerForSearch, { passive: true });
        }
    }

    _getLayoutSearch = () => {
        const buttonLabel = _getData(this._el, 'searchButtonLabel');
        const searchInnerEl = this._el.querySelector(`.js-${blockName}__search-inner`);

        return new LayoutSearch(searchInnerEl, {
            formElements: [
                { role: LayoutSearch.ELEMENT_ROLE_INPUT, getEl: layoutSearchInput },
                { role: LayoutSearch.ELEMENT_ROLE_SUBMIT, getEl: () => layoutSearchButtonSearch(buttonLabel) },
            ],
            placement: LayoutSearch.PLACEMENT_HEADER_DESKTOP,
            onReset: this._closeSearch,
        });
    }
}

components.add(`js-${blockName}`, el => new LayoutHeaderDesktop(el));
