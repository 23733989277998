import components from '../../common/_components';
import { _reflow } from '../../common/_core';
import { _on, _onTransitionEnd } from '../../common/_events';
import Cookies from '../../vendors.theme/_cookies';
import './promo-black-friday.scss';

const COOKIE_NAME = 'BLACK_FRIDAY_MOBILE_HIDDEN';

const blockName = 'promo-black-friday';
const componentsList = [];

let hasHandlerAttached = false;

components.add(`js-${blockName}`, (el) => {
    if (!hasHandlerAttached) {
        hasHandlerAttached = true;

        _on(document, 'click', `.js-${blockName}__close`, (e) => {
            Cookies.set(COOKIE_NAME, 'true', {
                expires: 5,
                path: '/',
            });

            _onTransitionEnd(componentsList[0], () => {
                componentsList.forEach((componentEl) => {
                    componentEl.parentNode.removeChild(componentEl);
                });
            });

            componentsList.forEach((componentEl) => {
                componentEl.style.height = `${componentEl.offsetHeight}px`;
                componentEl.classList.add('animating');

                _reflow(componentEl);
                componentEl.style.height = '0px';
            });

            e.preventDefault();
        }, { once: true, passive: true });
    }

    componentsList.push(el);

    _on(el, 'done.promo-countdown', () => {
        const countdownEl = el.querySelector(`.js-${blockName}__countdown`);

        if (countdownEl) {
            countdownEl.parentNode.removeChild(countdownEl);
        }
    }, { once: true, passive: true });
});
