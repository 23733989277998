import { _on } from '../../common/_events';
import Cookies from '../../vendors/_cookies';

const ACTION_JUMP = 'jump';
const ACTION_OPEN = 'open';

function _extractHashFromUrl(url) {
    if (url) {
        const index = url.indexOf('#');

        if (index > 0) {
            return url.slice(index);
        }
    }

    return '';
}

function extractActionsFromHash(hash) {
    const parsedHash = hash.match(new RegExp(`^#(${ACTION_OPEN}|${ACTION_JUMP})-([0-9]+)$`));
    const action = parsedHash ? parsedHash[1] : null;
    const offerEl = parsedHash ? document.querySelector(`[data-offer-id="${parsedHash[2]}"]`) : null;

    return {
        action,
        offerEl,
    };
}

function openTab(url) {
    const linkEl = document.createElement('a');

    linkEl.href = url;
    linkEl.target = '_blank';
    linkEl.style.position = 'absolute';
    linkEl.style.top = '-100px';

    document.body.appendChild(linkEl);
    linkEl.click();
}

function getCookieID() {
    const hasLocalStorage = (typeof window.Storage !== 'undefined');
    let clid = null;

    if (hasLocalStorage) {
        try {
            clid = window.localStorage.getItem('clid');
        } catch (e) {}
    }

    if (!clid) {
        clid = Cookies.get('clid');
    }

    if (!clid) {
        let isSaved = false;

        clid = Date.now().toString() + Math.random();

        if (hasLocalStorage) {
            try {
                window.localStorage.setItem('clid', clid);
                isSaved = true;
            } catch (e) {}
        }

        if (!isSaved) {
            Cookies.set('clid', clid, { expires: 30 * 365, path: '/' });
        }
    }

    return clid;
}

_on(document, 'click', '.js-clickout', (e) => {
    const targetEl = e._caughtTarget_;
    const offerUrl = targetEl.getAttribute('data-offer-url');
    const clickoutUrl = targetEl.getAttribute('data-clickout-url');
    const shopName = targetEl.getAttribute('data-shop-name');
    const offerName = targetEl.getAttribute('data-offer-name');
    const isCompetitor = targetEl.getAttribute('data-is-competitor');
    const isHighlighted = targetEl.getAttribute('data-is-highlighted');

    e.preventDefault();

    const cookieID = getCookieID();
    const date = Date.now();
    const gaRef = window.ga;

    if (gaRef && gaRef instanceof Function) {
        gaRef('send', 'event', 'clickout', shopName, offerName, 1);

        if (isHighlighted === '1') {
            gaRef('send', 'event', 'Click', 'Highlight Coupon', window.location.pathname);
        }

        if (isCompetitor === '1') {
            gaRef('send', 'event', 'Click', 'Competitor Coupon', window.location.pathname);
        }

        gaRef('require', 'ecommerce');

        gaRef('ecommerce:addTransaction', {
            'id': `${date}${cookieID}`,
            'affiliation': 'GutscheinPony',
            'revenue': '1.00',
        });

        gaRef('ecommerce:addItem', {
            'id': `${date}${cookieID}`,
            'name': shopName,
            'sku': offerName,
            'category': '',
            'price': '1.00',
            'quantity': '1',
        });

        gaRef('ecommerce:send');
    }

    const hash = _extractHashFromUrl(offerUrl);
    const hashData = extractActionsFromHash(hash);

    if (hashData.action === ACTION_OPEN && hashData.offerEl) {
        openTab(`${window.location.pathname}${window.location.search}${hash}`);

        setTimeout(() => {
            window.location = clickoutUrl;
        }, 100);
    }
});

export {
    extractActionsFromHash as _extractActionsFromHash,
    ACTION_JUMP,
    ACTION_OPEN,
};
