import bugsnag from './_bugsnag-client';

const initFunctions = {};
const createdComponents = {};

const validClassNameRe = /^js-[a-z][a-z0-9-_]+$/g;
const componentAttr = 'data-component';
const initedComponentAttr = 'data-component-ready';

const scriptMessage = `__script-components-${+new Date()}`;
const classNameSplitRE = /\s+/;

let isFontsLoaded = false;
let isJSReady = false;

const components = {
    add: (className, initFn) => {
        if (Object.prototype.hasOwnProperty.call(initFunctions, className)) {
            bugsnag.notify(`We have registered init function for className "${className}"`);
            return;
        }

        if (!className.match(validClassNameRe)) {
            bugsnag.notify(`All classNames should be in format "js-[a-z][a-z0-9-_]", 
                    but "${className}" was provided`);
            return;
        }

        initFunctions[className] = initFn;
    },

    init: () => {
        if (isJSReady && isFontsLoaded) {
            const elements = Array.prototype.slice.call(document.querySelectorAll(`[${componentAttr}]`));
            const afterAllQueue = [];
            const api = {
                afterAll: (cb) => {
                    afterAllQueue.push(cb);
                },
            };

            elements.forEach((element) => {
                const classList = element.className.split(classNameSplitRE);
                let wereComponentsInited = false;

                classList.forEach((className) => {
                    if (className && Object.prototype.hasOwnProperty.call(initFunctions, className)) {
                        let result;

                        try {
                            result = initFunctions[className].call(null, element, api);
                        } catch (e) {
                            bugsnag.notify(e);
                        }

                        if (!Object.prototype.hasOwnProperty.call(createdComponents, className)) {
                            createdComponents[className] = [];
                        }

                        createdComponents[className].push({
                            element,
                            result,
                        });

                        wereComponentsInited = true;
                    }
                });

                if (wereComponentsInited) {
                    element.removeAttribute(componentAttr);
                    element.setAttribute(initedComponentAttr, '');
                }
            });

            afterAllQueue.forEach((cb) => {
                try {
                    cb.call();
                } catch (e) {
                    bugsnag.notify(e);
                }
            });
        }
    },

    markFontsAsLoaded: () => {
        isFontsLoaded = true;

        components.init();
    },

    markJsAsReady: () => {
        isJSReady = true;

        components.init();
    },
};

function on(msg, fn) {
    const onMessage = (e) => {
        if (e.data === msg) {
            if (e.stopPropagation) {
                e.stopPropagation();
            }

            fn();
        }
    };

    if (window.addEventListener) {
        window.addEventListener('message', onMessage, true);
    } else {
        window.attachEvent('onmessage', onMessage);
    }
}

on(scriptMessage, () => components.markJsAsReady());
components.markFontsAsLoaded();

window.postMessage(scriptMessage, '*');

export default {
    add: components.add,
    init: () => {
        const msg = `__script-components-init-${+new Date()}`;

        on(msg, () => components.init());
        window.postMessage(msg, '*');
    },
};
