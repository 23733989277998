const privateProp = `__event-handlers__${Date.now()}__`;
const delegatePropPrefix = 'delegate|';
const fakeTransitionEndEvent = 'fake-transition-end';
const fakeAnimationEndEvent = 'fake-animation-end';

let isPassiveEventsSupported;
let mouseWheelEvent;
let transitionEndEvent;
let animationEndEvent;

function _getEvent(stylePropToEventMap) {
    const el = document.createElement('div');
    const styleProps = Object.keys(stylePropToEventMap);
    let testProp;

    do {
        testProp = styleProps.shift();

        if (el.style[testProp] !== undefined) {
            return stylePropToEventMap[testProp];
        }
    } while (styleProps.length > 0);

    return null;
}

function _getTransitionEndEvent() {
    if (!transitionEndEvent) {
        transitionEndEvent = _getEvent({
            'transition': 'transitionend',
            'OTransition': 'oTransitionEnd',
            'MozTransition': 'transitionend',
            'WebkitTransition': 'webkitTransitionEnd',
        });
    }

    return transitionEndEvent;
}

function _getAnimationEndEvent() {
    if (!animationEndEvent) {
        animationEndEvent = _getEvent({
            'animation': 'animationend',
            'OAnimation': 'oAnimationEnd',
            'MozAnimation': 'animationend',
            'WebkitAnimation': 'webkitAnimationEnd',
        });
    }

    return animationEndEvent;
}

function _isPassiveEventsSupported() {
    if (isPassiveEventsSupported === undefined) {
        try {
            const opts = Object.defineProperty({}, 'passive', {
                get: () => {
                    isPassiveEventsSupported = true;
                    return isPassiveEventsSupported;
                },
            });
            window.addEventListener('testPassive', null, opts);
            window.removeEventListener('testPassive', null, opts);
        } catch (e) {}
    }

    return isPassiveEventsSupported;
}

function _isMatch(handlerObj, type, className, handler) {
    return handlerObj.type === type
        && handlerObj.className === className
        && handlerObj.handler === handler;
}

function _getHandlers(el, type) {
    if (el[privateProp] && el[privateProp][type]) {
        return el[privateProp][type];
    }

    return [];
}

function _callHandler(e, currentTarget, handlerObj, origHandlersQueue) {
    e._caughtTarget_ = currentTarget;
    const result = handlerObj.handler.call(currentTarget, e);

    if (handlerObj.once) {
        const index = origHandlersQueue.indexOf(handlerObj);

        if (index > -1) {
            origHandlersQueue.splice(index, 1);
        }
    }

    if (result === false) {
        e.preventDefault();
        e.stopPropagation();
    }
}

function _eventHandler(e) {
    const { type, currentTarget } = e;
    const regularHandlers = _getHandlers(currentTarget, type);
    const delegateHandlers = _getHandlers(currentTarget, `${delegatePropPrefix}${type}`);
    const delegateHandlersCopy = delegateHandlers.slice();
    const matchedHandlers = [];

    let testEl = e.target;

    if (testEl.nodeType && !(type === 'click' && e.button >= 1)) {
        while (testEl !== this && delegateHandlersCopy.length) {
            if (testEl.nodeType === Node.ELEMENT_NODE && !(type === 'click' && testEl.disabled === true)) {
                const usedHandlers = [];
                const testElRef = testEl;

                delegateHandlersCopy.forEach((handlerObj, i) => {
                    if (testElRef.classList.contains(handlerObj.className)) {
                        matchedHandlers.push({ el: testElRef, handlerObj });
                        usedHandlers.unshift(i);
                    }
                });

                usedHandlers.forEach(handlerIndex => delegateHandlersCopy.splice(handlerIndex, 1));
            }

            testEl = testEl.parentNode || this;
        }
    }

    matchedHandlers.forEach(({ el, handlerObj }) => _callHandler(e, el, handlerObj, delegateHandlers));
    regularHandlers.slice().forEach(handlerObj => _callHandler(e, currentTarget, handlerObj, regularHandlers));

    if (!delegateHandlers.length && !regularHandlers.length) {
        currentTarget.removeEventListener(type, _eventHandler);
    }
}

/**
 * @return {String} mousewheel event name
 */
function getMouseWheelEvent() {
    if (!mouseWheelEvent) {
        if ('onwheel' in document.createElement('div')) {
            mouseWheelEvent = 'wheel';
        } else if (document.onmousewheel !== undefined) {
            mouseWheelEvent = 'mousewheel';
        } else {
            mouseWheelEvent = 'DOMMouseScroll';
        }
    }

    return mouseWheelEvent;
}

/**
 * Function to trigger event with custom data
 *
 * @param {EventTarget} el
 * @param {String} eventName
 * @param {Object} [data]
 */
function trigger(el, eventName, data) {
    const event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventName, true, true, data || {});
    el.dispatchEvent(event);
}

/**
 *
 * @param {Node|Window} el Element to attach the handler
 * @param {String} type Type of event to be attached
 * @param {String} [className] ClassName for delegation. ClassName only is supported. Starts with dot.
 *                  The matched element which be in "_caughtTarget_" property
 * @param {Function} handler Handler of event
 * @param {Object} [options]
 * @param {Boolean} [options.once] Call handler just once
 * @param {Boolean} [options.passive] Add handler as "passive"
 */
function on(el, type, className, handler, options) {
    if (!el.addEventListener || typeof type !== 'string') {
        return; // throw bugsnag exception
    }

    if (!options && handler && typeof handler === 'object' && Object.getPrototypeOf(handler) === Object.prototype) {
        options = handler;
        handler = null;
    }

    if (!handler && className instanceof Function) {
        handler = className;
        className = null;
    }

    options = options || {};

    if (className) {
        className = className[0] === '.' ? className.slice(1) : className;
    }

    const delegateQueueName = `${delegatePropPrefix}${type}`;
    const handlersQueueName = className ? delegateQueueName : type;

    if (!el[privateProp]) {
        el[privateProp] = { [handlersQueueName]: [] };
    } else if (!el[privateProp][handlersQueueName]) {
        el[privateProp][handlersQueueName] = [];
    }

    const handlers = el[privateProp][handlersQueueName];
    const once = options.once === true;
    const isPassive = options.passive === true && _isPassiveEventsSupported();

    if (!handlers.some(handlerObj => _isMatch(handlerObj, type, className, handler))) {
        if ((_getHandlers(el, type).length + _getHandlers(el, delegateQueueName).length) === 0) {
            el.addEventListener(type, _eventHandler, isPassive ? { passive: true } : undefined);
        }

        handlers.push({ type, className, handler, once });
    }
}

/**
 * Helper to make transitioned work for all of browsers
 *
 * @param {Node|Window} el
 * @param {Function} handler
 */
function onTransitionEnd(el, handler) {
    const eventName = _getTransitionEndEvent() || fakeTransitionEndEvent;

    on(el, eventName, handler, { once: true });

    if (eventName === fakeTransitionEndEvent) {
        setTimeout(() => trigger(el, fakeTransitionEndEvent), 10);
    }
}

/**
 * Helper to make animationend work for all of browsers
 *
 * @param {Node|Window} el
 * @param {Function} handler
 */
function onAnimationEnd(el, handler) {
    const eventName = _getAnimationEndEvent() || fakeAnimationEndEvent;

    on(el, eventName, handler, { once: true });

    if (eventName === fakeAnimationEndEvent) {
        setTimeout(() => trigger(el, fakeAnimationEndEvent), 10);
    }
}

/**
 *
 * @param {Node|Window} el Element to detach the handler
 * @param {String} type Type of event to be attached
 * @param {String} [className] ClassName for delegation
 * @param {Function} handler Handler of event
 */
function off(el, type, className, handler) {
    if (!el.addEventListener || typeof type !== 'string') {
        return; // throw bugsnag exception
    }

    if (!handler && className instanceof Function) {
        handler = className;
        className = null;
    }

    if (className) {
        className = className[0] === '.' ? className.slice(1) : className;
    }

    const handlersQueue = _getHandlers(el, className ? `${delegatePropPrefix}${type}` : type);

    for (let i = 0; i < handlersQueue.length; i += 1) {
        if (_isMatch(handlersQueue[i], type, className, handler)) {
            handlersQueue.splice(i, 1);
            break;
        }
    }
}

/**
 * Helper to remove cross browser transitionend handler
 *
 * @param {Node|Window} el
 * @param {Function} handler
 */
function offTransitionEnd(el, handler) {
    off(el, _getTransitionEndEvent() || fakeTransitionEndEvent, handler);
}

/**
 * EventEmitter is useful to process non-DOM events
 */
class EventEmitter {
    constructor() {
        this._handlers = {};
    }

    emit(eventName, data) {
        const eventHandlers = this._handlers[eventName];

        if (eventHandlers) {
            eventHandlers.forEach(fn => fn.call(null, data));
        }
    }

    subscribe(eventName, fn) {
        if (!this._handlers[eventName]) {
            this._handlers[eventName] = [];
        }

        this._handlers[eventName].push(fn);
    }

    unsubscribe(eventName, fn) {
        if (Array.isArray(this._handlers[eventName])) {
            this._handlers[eventName] = this._handlers[eventName].filter(currentFn => currentFn !== fn);
        }
    }
}

export {
    on as _on,
    off as _off,
    trigger as _trigger,
    onTransitionEnd as _onTransitionEnd,
    offTransitionEnd as _offTransitionEnd,
    getMouseWheelEvent as _getMouseWheelEvent,
    onAnimationEnd as _onAnimationEnd,
    EventEmitter as _EventEmitter,
};
