export const _getSvgIcon = (name, className) => {
    const svgEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    svgEl.classList.add(className);
    svgEl.setAttribute('width', '20');
    svgEl.setAttribute('height', '20');
    svgEl.setAttribute('viewBox', '0 0 20 20');

    svgEl.innerHTML = `<use xlink:href="#${name}">`;

    return svgEl;
};
