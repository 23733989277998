/*
 * WARNING!
 * Content of this file was for automatically generated (with `./bin/build-entries.js`).
 * All changes will be replaced.
 */
/* Libraries */
import './vendors/_bootstrap.scss';
import './vendors/_font-awesome.scss';

import './vendors/_lazysizes';
import './vendors/_picturefill';

/* Common components */
import './components/stat/stat.js';
import './components/clickout/clickout.js';

import './../../Schnaeppchenfuchs/assets/components/buttons/buttons.js';
import './../../Schnaeppchenfuchs/assets/components/entity-anchor/entity-anchor.js';

/* Layout */

import './../../Schnaeppchenfuchs/assets/components/layout-header-admin/layout-header-admin.scss';
import './../../Schnaeppchenfuchs/assets/components/layout-header-desktop/layout-header-desktop.js';
import './../../Schnaeppchenfuchs/assets/components/layout-header-mobile/layout-header-mobile.js';
import './../../Schnaeppchenfuchs/assets/components/layout-menu/layout-menu.js';
import './../../Schnaeppchenfuchs/assets/components/layout-menu-shops/layout-menu-shops.scss';
import './../../Schnaeppchenfuchs/assets/components/layout-menu-categories/layout-menu-categories.scss';
import './../../Schnaeppchenfuchs/assets/components/layout-menu-tiles/layout-menu-tiles.scss';

import './../../Schnaeppchenfuchs/assets/components/layout-breadcrumb/layout-breadcrumb.scss';
import './../../Schnaeppchenfuchs/assets/components/layout-sidebar/layout-sidebar.js';
import './../../Schnaeppchenfuchs/assets/components/layout-footer/layout-footer.scss';
import './../../Schnaeppchenfuchs/assets/components/layout-description-note/layout-description-note.scss';

import './../../Schnaeppchenfuchs/assets/components/promo/promo.js';

import './components/page-header/page-header.scss';



import './../../Schnaeppchenfuchs/assets/components/flash-message/flash-message.js';

/* Pages */


import './../../Schnaeppchenfuchs/assets/components/page-shops-index/page-shops-index.js';
import './../../Schnaeppchenfuchs/assets/components/page-shops-view/page-shops-view.js';
import './../../Schnaeppchenfuchs/assets/components/page-deals-index/page-deals-index.scss';
import './../../Schnaeppchenfuchs/assets/components/page-deals-view/page-deals-view.js';
import './../../Schnaeppchenfuchs/assets/components/page-deals-add/page-deals-add.js';
import './../../Schnaeppchenfuchs/assets/components/page-deal-categories-view/page-deal-categories-view.scss';


import './../../Schnaeppchenfuchs/assets/components/page-landing-pages-view/page-landing-pages-view.js';

import './../../Schnaeppchenfuchs/assets/components/page-dashboard-home/page-dashboard-home.scss';

import './../../Schnaeppchenfuchs/assets/components/page-posts-view/page-posts-view.scss';

import './../../Schnaeppchenfuchs/assets/components/page-posts-index/page-posts-index.scss';
import './components/page-error/page-error.scss';

import './components/page-offers-view/page-offers-view.js';



import './../../Schnaeppchenfuchs/assets/components/page-guides-index/page-guides-index.scss';
import './../../Schnaeppchenfuchs/assets/components/page-guides-view/page-guides-view.scss';
import './../../Schnaeppchenfuchs/assets/components/page-users-add/page-users-add.js';
import './../../Schnaeppchenfuchs/assets/components/page-users-edit/page-users-edit.js';
import './../../Schnaeppchenfuchs/assets/components/page-users-login/page-users-login.scss';
import './../../Schnaeppchenfuchs/assets/components/page-search-search/page-search-search.js';

/* Components */
import './components/badge/badge.scss';
import './../../Schnaeppchenfuchs/assets/components/dots/dots.js';

import './../../Schnaeppchenfuchs/assets/components/lightbox/lightbox.js';
import './../../Schnaeppchenfuchs/assets/components/full-screen-modal/full-screen-modal.js';
import './components/show-all-link/show-all-link.scss';


import './../../Schnaeppchenfuchs/assets/components/posts-list-item-primary/posts-list-item-primary.scss';
import './components/offers-voting/offers-voting.js';
import './../../Schnaeppchenfuchs/assets/components/offers-label/offers-label.scss';

import './../../Schnaeppchenfuchs/assets/components/offers-label/_type_secondary/offers-label_type_secondary.scss';

import './../../Schnaeppchenfuchs/assets/components/offers-list-item-secondary/offers-list-item-secondary.scss';
import './components/offers-list-item-tertiary/offers-list-item-tertiary.scss';


import './components/offers-list-tertiary/offers-list-tertiary.scss';

import './../../Schnaeppchenfuchs/assets/components/shops-list-item-primary/shops-list-item-primary.scss';


import './../../Schnaeppchenfuchs/assets/components/shops-list-primary/shops-list-primary.scss';






import './components/rating/rating.js';
import './../../Schnaeppchenfuchs/assets/components/wysiwyg-content/wysiwyg-content.scss';
import './../../Schnaeppchenfuchs/assets/components/wysiwyg-accordion/wysiwyg-accordion.js';
import './../../Schnaeppchenfuchs/assets/components/wysiwyg-text-box/wysiwyg-text-box.js';
import './../../Schnaeppchenfuchs/assets/components/wysiwyg-rating/wysiwyg-rating.scss';
import './../../Schnaeppchenfuchs/assets/components/wysiwyg-newsletter/wysiwyg-newsletter.js';
import './../../Schnaeppchenfuchs/assets/components/layout-description/layout-description.js';



import './components/load-more-page/load-more-page.js';
import './../../Schnaeppchenfuchs/assets/components/teaser/teaser.js';
import './../../Schnaeppchenfuchs/assets/components/teaser-content-post/teaser-content-post.scss';
import './../../Schnaeppchenfuchs/assets/components/teaser-content-guide/teaser-content-guide.scss';

import './../../Schnaeppchenfuchs/assets/components/teaser-content-landing-page/teaser-content-landing-page.js';
import './components/shops-description-headlines/shops-description-headlines.js';





import './components/no-js-notification/no-js-notification.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-category/deals-category.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-like-button/deals-like-button.js';
import './../../Schnaeppchenfuchs/assets/components/deals-sharing/deals-sharing.js';
import './../../Schnaeppchenfuchs/assets/components/deals-coupon-code/deals-coupon-code.js';

import './../../Schnaeppchenfuchs/assets/components/deals-list-item-secondary/deals-list-item-secondary.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-list-item-tertiary/deals-list-item-tertiary.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-list-item-quaternary/deals-list-item-quaternary.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-filtered-list/deals-filtered-list.js';
import './../../Schnaeppchenfuchs/assets/components/deals-filters-panel/deals-filters-panel.js';
import './../../Schnaeppchenfuchs/assets/components/deals-view-body/deals-view-body.js';
import './components/shops-card/shops-card.scss';
import './../../Schnaeppchenfuchs/assets/components/shops-filters-panel/shops-filters-panel.js';
import './components/shops-offers-list/shops-offers-list.js';


import './components/shops-top-offers-table/shops-top-offers-table.scss';


import './../../Schnaeppchenfuchs/assets/components/shops-deals/shops-deals.scss';
import './../../Schnaeppchenfuchs/assets/components/range-slider/range-slider.js';




import './../../Schnaeppchenfuchs/assets/components/scroll-up-button/scroll-up-button.js';
import './../../Schnaeppchenfuchs/assets/components/privacy-button/privacy-button.js';
import './components/offers-statistics/offers-statistics.scss';



import './../../Schnaeppchenfuchs/assets/components/ads-banner/ads-banner.js';
import './../../Schnaeppchenfuchs/assets/components/yieldlove-ad/yieldlove-ad.js';
import './../../Schnaeppchenfuchs/assets/components/guides-list-item-primary/guides-list-item-primary.scss';
import './../../Schnaeppchenfuchs/assets/components/comments-widget/comments-widget.js';
import './../../Schnaeppchenfuchs/assets/components/comments-list-item/comments-list-item.js';
import './../../Schnaeppchenfuchs/assets/components/comments-rules/comments-rules.scss';

import './../../Schnaeppchenfuchs/assets/components/placements-slideshow/placements-slideshow.js';
import './../../Schnaeppchenfuchs/assets/components/placements-editors-choice/placements-editors-choice.js';
import './../../Schnaeppchenfuchs/assets/components/placements-editors-choice-item/placements-editors-choice-item.scss';
import './../../Schnaeppchenfuchs/assets/components/guides-editors-choice-item/guides-editors-choice-item.scss';
import './../../Schnaeppchenfuchs/assets/components/posts-editors-choice-item/posts-editors-choice-item.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-editors-choice-item/deals-editors-choice-item.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-similar-deals/deals-similar-deals.js';

import './../../Schnaeppchenfuchs/assets/components/sidebar-telegram/sidebar-telegram.scss';
import './../../Schnaeppchenfuchs/assets/components/sidebar-newsletter/sidebar-newsletter.js';
import './../../Schnaeppchenfuchs/assets/components/sidebar-whatsapp/sidebar-whatsapp.scss';
import './../../Schnaeppchenfuchs/assets/components/deals-sidebar/deals-sidebar.js';
import './../../Schnaeppchenfuchs/assets/components/shops-sidebar/shops-sidebar.js';
import './../../Schnaeppchenfuchs/assets/components/guides-sidebar/guides-sidebar.js';
import './../../Schnaeppchenfuchs/assets/components/user-input-form/user-input-form.scss';
import './../../Schnaeppchenfuchs/assets/components/user-input-page/user-input-page.scss';
import './../../Schnaeppchenfuchs/assets/components/input-type-password/input-type-password.js';
import './../../Schnaeppchenfuchs/assets/components/input-type-date/input-type-date.js';

import './../../Schnaeppchenfuchs/assets/components/keyboard-navigation/keyboard-navigation.js';
