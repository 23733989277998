import components from '../../common/_components';
import { _getData, _toArray } from '../../common/_core';
import { _on } from '../../common/_events';
import ScrollHorizontal from '../../common/_scroll-horizontal';
import { Dots } from '../dots/dots';

import './placements-editors-choice.scss';

const interval = 3500;
const blockName = 'placements-editors-choice';
const innerSelector = `.js-${blockName}__inner`;
const listSelector = `.js-${blockName}__list`;
const listItemSelector = `.js-${blockName}__list-item`;
const dotsSelector = `.js-${blockName}__dots`;

class PlacementsEditorsChoice {
    constructor(el) {
        this._listEl = el.querySelector(listSelector);
        this._slides = this._listEl.querySelectorAll(listItemSelector);

        if (this._slides.length > 1) {
            this._listWrapper = el.querySelector(innerSelector);
            this._scroll = new ScrollHorizontal(this._listWrapper, this._listEl, listItemSelector, {
                onDragStart: this._dragStartHandler,
                onSlideChange: this._slideChangeHandler,
                onSlideChangeDone: this._slideChangeDoneHandler,
            });

            this._buildDots(el.querySelector(dotsSelector));
            this._slideChangeDoneHandler();

            _on(el, 'click', `.js-${blockName}__arrow`, this._onArrowClick, { passive: true });

            el.classList.add('ready');
        }
    }

    _dragStartHandler = () => {
        this._stopCarousel();
    };

    _slideChangeHandler = (currentSlideEl) => {
        if (this._dots) {
            this._dots.select(ScrollHorizontal.getSlideId(currentSlideEl));
        }
    };

    _slideChangeDoneHandler = () => {
        this._stopCarousel();

        if (this._needSliding()) {
            this._timeout = setTimeout(() => {
                if (this._needSliding()) {
                    this._scroll.moveRight();
                } else {
                    this._stopCarousel();
                }
            }, interval);
        }
    };

    _stopCarousel = () => clearTimeout(this._timeout);

    _needSliding = () => this._listWrapper.scrollWidth - this._listWrapper.getBoundingClientRect().width > 3;

    _onArrowClick = (e) => {
        const arrowEl = e._caughtTarget_;
        const direction = _getData(arrowEl, 'direction');

        if (direction === 'prev') {
            this._scroll.moveLeft();
        } else if (direction === 'next') {
            this._scroll.moveRight();
        }
    };

    _buildDots = (dotsEl) => {
        const currentSlideId = this._scroll.getCurrentSlideId();

        this._dots = new Dots(dotsEl, this._onDotActive);

        _toArray(this._slides).forEach((slideEl) => {
            const slideId = ScrollHorizontal.getSlideId(slideEl);
            this._dots.addDot(slideId, _getData(slideEl, 'itemLabel'), slideId === currentSlideId);
        });

        this._dots.build();
    };

    _onDotActive = (id, indexDiff) => {
        this._stopCarousel();

        if (indexDiff === 1) {
            this._scroll.moveRight();
        } else {
            this._scroll.moveTo(id);
        }
    };
}

components.add(`js-${blockName}`, element => new PlacementsEditorsChoice(element));
