import HeadersHeightManager from './_headers-height-manager';
import { getConfigured } from '../../../../assets/common/_sticky-element';

export default getConfigured(
    {
        getHeaderHeight: HeadersHeightManager.getHeight,
        addHeaderStateChangeHandler: HeadersHeightManager.addStateChangeHandler,
        removeHeaderStateChangeHandler: HeadersHeightManager.removeStateChangeHandler,
    },
);
