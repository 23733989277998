import components from '../../common/_components';
import { _parseQueryString, _stringify } from '../../common/_ajax';
import { _getData } from '../../common/_core';
import { _on } from '../../common/_events';

import './page-search-search.scss';

const blockName = 'page-search-search';
const activeMod = 'active';

components.add(`js-${blockName}`, (el) => {
    const tabsEl = el.querySelector(`.js-${blockName}__tabs`);
    const tabsParam = _getData(el, 'tabParam');

    if (!tabsEl) {
        return;
    }

    _on(tabsEl, 'click', `.js-${blockName}__tab-heading`, (e) => {
        const targetTabEl = e._caughtTarget_;

        if (!targetTabEl.classList.contains(activeMod)) {
            const targetTabName = _getData(targetTabEl, 'tabName');
            const targetBodyEl = el.querySelector(`.js-${blockName}__tab-body[data-tab-name=${targetTabName}]`);
            const currentTabEl = tabsEl.querySelector(`.js-${blockName}__tab-heading.${activeMod}`);
            const currentBodyEl = el.querySelector(`.js-${blockName}__tab-body.${activeMod}`);
            const queryData = _parseQueryString(window.location.search.slice(1));
            let queryString = '';

            targetTabEl.classList.add(activeMod);
            targetBodyEl.classList.add(activeMod);

            currentTabEl.classList.remove(activeMod);
            currentBodyEl.classList.remove(activeMod);

            queryData[tabsParam] = targetTabName;
            queryString = _stringify(queryData);

            if (queryString) {
                queryString = `?${queryString}`;
            }

            if (window.location.search !== queryString) {
                const targetUrl = `${window.location.pathname}${queryString}${window.location.hash}`;
                const currentState = window.history.state;

                window.history.replaceState(currentState, document.title, targetUrl);
            }
        }

        e.preventDefault();
    });
});
