import { _forIn } from './_core';
import { _isNil, _isNumber, _isString } from './_types';

/**
 * Helper to easy create html element
 *
 * @param {String} tagName
 * @param {String} [className]
 * @param {Object} [attrs]
 * @param {Array} [content]
 *
 * @return {HTMLElement}
 */
function createEl(tagName, className, attrs, content) {
    const el = document.createElement(tagName);
    const processAttrs = (attributes) => {
        _forIn(attributes, (value, name) => el.setAttribute(name, value));
    };
    const processContent = (contentElements) => {
        contentElements.forEach((element) => {
            if (element) {
                el.appendChild(_isString(element) || _isNumber(element)
                    ? document.createTextNode(element)
                    : element);
            }
        });
    };

    if (_isString(className)) {
        el.className = className;
    } else if (Array.isArray(className)) {
        processContent(className);
    } else if (!_isNil(className)) {
        processAttrs(className);
    }

    if (Array.isArray(attrs)) {
        processContent(attrs);
    } else if (!_isNil(attrs)) {
        processAttrs(attrs);
    }

    if (content) {
        processContent(content);
    }

    return el;
}

export { createEl as _createEl };
