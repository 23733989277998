import components from '../../common/_components';
import { _off, _on } from '../../common/_events';
import { _getData } from '../../common/_core';
import { _sendRequest } from '../../common/_ajax';

import './rating.scss';

const componentName = 'rating';
const starSelector = `.js-${componentName}__star`;
const ratingsCountSelector = `.js-${componentName}__count`;
const ratingModifier = `${componentName}_is_`;
const ratingCanModifyModifier = `${componentName}_can-modify`;

class Rating {
    constructor(containerEl) {
        this._rootEl = containerEl;

        if (this._rootEl.classList.contains(ratingCanModifyModifier)) {
            _on(this._rootEl, 'click', starSelector, this._starClickListener);
        }
    }

    _starClickListener = (e) => {
        const starEl = e._caughtTarget_;
        const ratingValue = parseFloat(_getData(starEl, 'ratingValue'));
        const url = _getData(this._rootEl, 'ratingUrl');

        if (this._request) {
            this._request.abort();
        }

        this._request = _sendRequest({
            method: 'POST',
            url,
            data: {
                rating: ratingValue,
            },
            success: () => {
                this._applyRatingDiff(ratingValue);

                _off(this._rootEl, 'click', starSelector, this._starClickListener);
                this._rootEl.classList.remove(ratingCanModifyModifier);
            },
        });
    };

    _applyRatingDiff = (selectedRating) => {
        const ratingModRE = new RegExp(`${ratingModifier}\\d+`, 'g');
        const ratingsCountEl = this._rootEl.querySelector(ratingsCountSelector);
        const currentValue = parseFloat(_getData(this._rootEl, 'ratingValue'));
        const ratingsCount = parseInt(ratingsCountEl.textContent, 10);

        const newRatingCount = ratingsCount + 1;
        const newRating = Math.round(((currentValue * ratingsCount) + selectedRating) / newRatingCount);

        const cleanClassName = this._rootEl.className.replace(ratingModRE, '');

        // eslint-disable-next-line no-param-reassign
        this._rootEl.className = `${cleanClassName} ${ratingModifier}${newRating}`.replace(/\s+/g, ' ');
        ratingsCountEl.textContent = String(newRatingCount);
    }
}

components.add(`js-${componentName}`, element => new Rating(element));
