import { _on } from '../../common/_events';
import { _createEl } from '../../common/_create-el';
import { _toArray } from '../../common/_core';
import { _isNumber } from '../../common/_types';
import { throttle } from '../../vendors.theme/_throttle-debounce';
import './dots.scss';

const instances = [];
const GRID_BREAKPOINTS = [
    [1200, 'xl'],
    [992, 'lg'],
    [768, 'md'],
    [544, 'sm'],
    [0, 'xs'],
];

let currentScreenIndex;

function getCurrentScreenIndex() {
    const windowWidth = document.documentElement.clientWidth;

    for (let i = 0; i < GRID_BREAKPOINTS.length; i += 1) {
        if (windowWidth > GRID_BREAKPOINTS[i][0]) {
            return i;
        }
    }

    return GRID_BREAKPOINTS.length - 1;
}

function addInstance(instance) {
    if (instances.length === 0) {
        currentScreenIndex = getCurrentScreenIndex();

        _on(window, 'resize', throttle(250, () => {
            const screenIndex = getCurrentScreenIndex();

            if (screenIndex !== currentScreenIndex) {
                currentScreenIndex = screenIndex;
                instances.forEach(inst => inst.update());
            }
        }), { passive: true });
    }

    instances.push(instance);
}

const blockName = 'dots';
const dotClassName = `${blockName}__dot`;
const dotSelectedMod = 'selected';
const dotIdAttributeName = 'data-dot-id';

/**
 * @param el {Element}
 * @param onActivate {Function}
 *
 * @return {Dots}
 */
class Dots {
    constructor(el, onActivate) {
        this._el = el;
        this._onActivate = onActivate;
        this._dots = [];
        this._dotsPerScreen = GRID_BREAKPOINTS.reduce((data, [, breakpointName]) => {
            const attrValue = el.getAttribute(`data-dots-${breakpointName}`);

            if (attrValue) {
                data[breakpointName] = Number(attrValue);
            }

            return data;
        }, {});

        addInstance(this);
    }

    addDot = (id, label, isCurrent) => {
        this._dots.push({ id, label });

        if (isCurrent) {
            this._currentDotId = id;
        }
    };

    build = () => {
        if (this._dots.length > 0) {
            const dotsFragment = document.createDocumentFragment();
            const currentIds = this._getAllIds(this._currentDotId);

            this._dots.forEach(({ id, label }) => {
                const isCurrent = currentIds.indexOf(id) > -1;

                dotsFragment.appendChild(_createEl('div', `${dotClassName}${isCurrent ? ` ${dotSelectedMod}` : ''}`, {
                    [dotIdAttributeName]: id,
                    'aria-label': label,
                }));
            });

            this._el.appendChild(dotsFragment);
            _on(this._el, 'click', dotClassName, this._dotClickHandler, { passive: true });
        }
    };

    update = () => {
        this.select(this._currentDotId);
    };

    select = (id) => {
        const currentDotEls = this._el.querySelectorAll(`.${dotClassName}.${dotSelectedMod}`);
        const targetIds = this._getAllIds(id);
        const targetDotEls = targetIds.map(
            dotId => this._el.querySelector(`.${dotClassName}[${dotIdAttributeName}="${dotId}"]`),
        );

        _toArray(currentDotEls).forEach((currentDotEl) => {
            if (targetDotEls.indexOf(currentDotEl) === -1) {
                currentDotEl.classList.remove(dotSelectedMod);
            }
        });

        targetDotEls.forEach((targetDotEl) => {
            targetDotEl.classList.add(dotSelectedMod);
        });

        this._currentDotId = id;
    };

    _getAllIds = (leftId) => {
        const quantity = this._getItemsQuantity();
        const idsList = [];
        let hasMetId = false;

        for (let i = 0; i < this._dots.length; i += 1) {
            const currentId = this._dots[i].id;

            if (currentId === leftId) {
                hasMetId = true;
            }

            if (hasMetId) {
                idsList.push(currentId);
            }

            if (idsList.length === quantity) {
                break;
            }

            if (hasMetId && i === this._dots.length - 1) {
                i = -1;
            }
        }

        return idsList;
    };

    _getItemsQuantity = () => {
        const dotsPerScreen = this._dotsPerScreen;

        for (let i = currentScreenIndex; i < GRID_BREAKPOINTS.length; i += 1) {
            const key = GRID_BREAKPOINTS[i][1];

            if (_isNumber(dotsPerScreen[key])) {
                return dotsPerScreen[key];
            }
        }

        return 1;
    };

    _dotClickHandler = (e) => {
        const clickedDotEl = e._caughtTarget_;
        const isSelected = dotEl => dotEl && dotEl.classList.contains(dotSelectedMod);

        if (!isSelected(clickedDotEl)) {
            const dots = _toArray(this._el.querySelectorAll(`.${dotClassName}`));
            const clickedDotId = clickedDotEl.getAttribute(dotIdAttributeName);
            const clickedDotIndex = dots.indexOf(clickedDotEl);
            const dotsLen = dots.length;

            let radius = 1;
            let selectedDotIndex;
            let targetDotId;

            while (clickedDotIndex + radius < dotsLen || clickedDotIndex - radius >= 0) {
                if (isSelected(dots[clickedDotIndex + radius])) {
                    selectedDotIndex = clickedDotIndex + radius;
                    break;
                } else if (isSelected(dots[clickedDotIndex - radius])) {
                    selectedDotIndex = clickedDotIndex - radius;
                    break;
                } else {
                    radius += 1;
                }
            }

            if (clickedDotIndex > selectedDotIndex) {
                targetDotId = this._shiftToLeft(clickedDotId);
            } else if (clickedDotIndex < selectedDotIndex) {
                targetDotId = clickedDotId;
            }

            if (targetDotId) {
                this.select(targetDotId);
                this._onActivate(targetDotId, clickedDotIndex - selectedDotIndex);
            }
        }
    };

    _shiftToLeft = (id) => {
        const quantity = this._getItemsQuantity();
        const dotsQuantity = this._dots.length;

        for (let i = 0; i < this._dots.length; i += 1) {
            if (this._dots[i].id === id) {
                const targetIndex = i + 1 - quantity;
                return this._dots[(targetIndex + dotsQuantity) % dotsQuantity].id;
            }
        }

        return id;
    };
}

export { Dots };
