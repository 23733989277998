import lazySizes from 'lazysizes';
import './_lazysizes.scss';

document.addEventListener('lazybeforeunveil', (e) => {
    const bg = e.target && e.target.getAttribute('data-bg');

    if (bg) {
        e.target.style.backgroundImage = `url("${bg}")`;
    }
});

lazySizes.init();
document.documentElement.classList.remove('no-js');

export default lazySizes;
