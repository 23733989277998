import components from '../../common/_components';
import { _toArray } from '../../common/_core';
import { _parseQueryString, _stringify } from '../../common/_ajax';
import { DealsFiltersPanelBlockForCategories } from './deals-filters-panel__block_for_categories';
import { DealsFiltersPanelBlockForPrice } from './deals-filters-panel__block_for_price';
import { DealsFiltersPanelBlockForExpired } from './deals-filters-panel__block_for_expired';

import './deals-filters-panel.scss';

const blockName = 'deals-filters-panel';
const blockSelector = `.js-${blockName}__block`;

const _filtersChangeCallback = [];

class DealsFiltersPanel {
    constructor(el) {
        const rawUrlData = this._getUrlData();

        this._blocks = [];

        _toArray(el.querySelectorAll(blockSelector)).forEach((blockEl) => {
            if (blockEl.classList.contains(`${blockName}__block_for_categories`)) {
                this._blocks.push(new DealsFiltersPanelBlockForCategories(el, rawUrlData, this._updateUrl));
            } else if (blockEl.classList.contains(`${blockName}__block_for_price`)) {
                this._blocks.push(new DealsFiltersPanelBlockForPrice(el, rawUrlData, this._updateUrl));
            } else if (blockEl.classList.contains(`${blockName}__block_for_expired`)) {
                this._blocks.push(new DealsFiltersPanelBlockForExpired(el, rawUrlData, this._updateUrl));
            }
        });
    }

    reset = () => {
        this._blocks.forEach(block => block.resetToDefault());
        this._updateUrl();
    };

    _getUrlData = () => _parseQueryString(window.location.search.slice(1));

    _updateUrl = () => {
        const rawUrlData = this._getUrlData();
        let queryString;

        this._blocks.forEach(block => block.fulfillUrlData(rawUrlData));

        queryString = _stringify(rawUrlData);

        if (queryString) {
            queryString = `?${queryString}`;
        }

        if (window.location.search !== queryString) {
            const loc = window.location;
            const targetUrl = `${loc.protocol}//${loc.host}${loc.pathname}${queryString}${loc.hash}`;
            const currentState = window.history.state;

            window.history.replaceState(currentState, document.title, targetUrl);
            this._urlChangeHandler();
        }
    };

    _urlChangeHandler = () => {
        const rawUrlData = this._getUrlData();
        const requestData = {};

        this._blocks.forEach(block => block.fulfillRequestData(requestData, rawUrlData));
        _filtersChangeCallback.forEach(cb => cb(requestData));
    };
}

let instance;

components.add(`js-${blockName}`, (el) => {
    instance = new DealsFiltersPanel(el);
});

function onFiltersChange(cb) {
    _filtersChangeCallback.push(cb);
}

function resetFiltersToDefault() {
    if (instance) {
        instance.reset();
    }
}

export { onFiltersChange, resetFiltersToDefault };
