import { _getData, _setData, _toArray } from './_core';
import { _on, _off } from './_events';
import { Animation } from './_scroll-page';

const slideIdDataKey = 'horizontalScrollSlideId';

class ScrollHorizontal {
    /**
     * Component for horizontal scrolling with mouse and touch events.
     *
     * Example of HTML
     * <div class="...">
     *     <div class="...__list"> (optional wrapper)
     *         <div class="...__item"> ... </div>
     *         <div class="...__item"> ... </div>
     *         ...
     *     </div>
     * </div>
     *
     * @param {Element} el Root element. It has to have "overflow: hidden;" and "position: relative;"
     * for proper work
     * @param {String} itemSelector Selector of list item
     *
     * @param {Object} [options]
     * @param {Element} [options.scrollEl] In some cases root element can not be scrolled.
     * With this option a child which should be scrolled (with "overflow:hidden") can be provided.
     * By default: el.
     * @param {Element} [options.listEl] In some cases we need an additional element to build a line of items.
     * With this option a child which should be used to insert additional items can be provided. Works with
     * `isInfinity=true` only.
     * By default: options.scrollEl or el.
     * @param {Boolean} [options.isInfinity=false] If true, the list items are looped up to be able to scroll set of
     * the items infinitely. This mode means that at "mousedown"/"touchstart" event the component  copy the items
     * to insert them before and after source ones.
     * @param {Boolean} [options.scrollToLeftItem=true] If true, the list is to be scrolled up to left side of
     * the nearest item  when scrolling by user is finished
     * @param {Number} [options.changeItemOffset=15] Option is used when `scrollToLeftItem` is `true`. It means
     * minimal distance which is to be scrolled to change left element
     * @param {Function} [options.onDragStart] Callback for "drag start" event
     * @param {Function} [options.onSlideChange] Callback to call when current element is changed
     * @param {Function} [options.onSlideChangeDone] Callback for end of slide change animation
     */
    constructor(el, itemSelector, options) {
        const scrollOptions = {
            ...{
                changeItemOffset: 15,
                isInfinity: false,
                scrollEl: null,
                listEl: null,
                onDragStart: () => {},
                onSlideChange: () => {},
                onSlideChangeDone: () => {},
                scrollToLeftItem: true,
            },
            ...options,
        };

        const items = _toArray(el.querySelectorAll(itemSelector));

        items.forEach((itemEl, index) => {
            _setData(itemEl, slideIdDataKey, `item_${index}`);
        });

        this._el = el;
        this._scrollEl = scrollOptions.scrollEl || el;
        this._listEl = scrollOptions.listEl || this._scrollEl;

        this._itemSelector = itemSelector;
        this._options = scrollOptions;

        this._leftItemEl = items[0];
        this._itemsLength = items.length;
        this._isTouchable = true;
        this._isDragging = false;

        this._bindListeners();
    }

    getCurrentSlideId = () => ScrollHorizontal.getSlideId(this._leftItemEl);

    stopAnimation = () => {
        if (this._scrollElAnimation) {
            this._scrollElAnimation.stop();
        }
    };

    moveRight = (duration = 200) => {
        this._setupFakeItems();

        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
        const leftItemElIndex = items.indexOf(this._leftItemEl);
        const nextItemsLength = leftItemElIndex > -1 ? items.length - (leftItemElIndex + 1) : 0;

        if (nextItemsLength >= this._itemsLength) {
            this._setLeftItem(items[leftItemElIndex + 1]);
            this._scrollToCurrent(duration);
        }
    };

    moveLeft = (duration = 200) => {
        this._setupFakeItems();

        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
        const leftItemElIndex = items.indexOf(this._leftItemEl);
        const prevItemEl = items[leftItemElIndex - 1];

        if (prevItemEl) {
            this._setLeftItem(prevItemEl);
            this._scrollToCurrent(duration);
        }
    };

    moveTo = (id, duration = 200) => {
        const currentSlideId = ScrollHorizontal.getSlideId(this._leftItemEl);
        const currentSlideIndex = this._getIndexFromId(currentSlideId);
        const targetSlideIndex = this._getIndexFromId(id);
        let itemEl;

        if (targetSlideIndex < 0 || targetSlideIndex >= this._itemsLength) {
            return;
        }

        if (targetSlideIndex < currentSlideIndex) {
            this._setupFakeItems();

            const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
            itemEl = this._getItemById(id, items.slice(0, items.indexOf(this._leftItemEl)).reverse());
        } else if (targetSlideIndex > currentSlideIndex) {
            const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
            itemEl = this._getItemById(id, items.slice(items.indexOf(this._leftItemEl)));
        }

        if (itemEl) {
            this._setLeftItem(itemEl);
            this._scrollToCurrent(duration);
        }
    };

    _bindListeners = () => {
        _on(this._el, 'touchstart', this._dragStartHandler, { passive: true });
        _on(this._el, 'mousedown', (e) => {
            this._dragStartHandler(e);
            e.preventDefault();
        });
    };

    _getIndexFromId = (id) => {
        const indexRE = /item_(\d+)/;
        const match = id.match(indexRE);

        return match ? parseInt(match[1], 10) : -1;
    };

    _getItemById = (id, items) => {
        for (let i = 0; i < items.length; i += 1) {
            if (ScrollHorizontal.getSlideId(items[i]) === id) {
                return items[i];
            }
        }

        return null;
    };

    _dragStartHandler = (e) => {
        const isMouse = this._isMouse(e);
        const needsScroll = this._scrollEl.getBoundingClientRect().width < this._scrollEl.scrollWidth;

        if (needsScroll && this._isTouchable && this._isMainPointer(e)) {
            this._options.onDragStart();
            this.stopAnimation();

            this._isDragging = true;

            _on(document, isMouse ? 'mousemove' : 'touchmove', this._dragMoveHandler);
            _on(document, isMouse ? 'mouseup' : 'touchend', this._dragEndHandler);
        }
    };

    _isMainPointer = e => (this._isMouse(e) && e.which === 1) || (e.touches && e.touches.length === 1);

    _isMouse = e => e.type.indexOf('mouse') === 0;

    _getClientX = e => (e.touches && e.touches.length ? e.touches[0].clientX : e.clientX);

    _dragMoveHandler = (e) => {
        if (this._isDragging === true && !this._startX) {
            this._setupFakeItems();

            this._startX = this._getClientX(e);
            this._startScrollLeft = this._scrollEl.scrollLeft;
        }

        if (this._startX !== undefined && this._startScrollLeft !== undefined) {
            const clientX = this._getClientX(e);
            const prevClientX = this._clientX || this._startX;
            const scrollLeft = this._startScrollLeft - (clientX - this._startX);

            this._direction = clientX - prevClientX;
            this._clientX = clientX;

            this._scrollEl.scrollLeft = scrollLeft;
        }
    };

    _dragEndHandler = (e) => {
        const isMouse = this._isMouse(e);
        const wasDragged = (this._clientX !== undefined);

        if (isMouse && e.which !== 1) {
            return;
        }

        _off(document, isMouse ? 'mousemove' : 'touchmove', this._dragMoveHandler);
        _off(document, isMouse ? 'mouseup' : 'touchend', this._dragEndHandler);

        this._setLeftItem(this._getLeftElement(this._direction));

        if (isMouse && Math.abs(this._clientX - this._startX) > 5) {
            const parentLinkEl = this._closestLinkEl(e.target);
            const preventDefaultFn = (clickEvent) => {
                clickEvent.preventDefault();
                clickEvent.stopPropagation();
            };

            if (parentLinkEl) {
                _on(parentLinkEl, 'click', preventDefaultFn, { once: true });

                setTimeout(() => {
                    _off(parentLinkEl, 'click', preventDefaultFn);
                }, 100);
            }
        }

        this._isDragging = false;

        this._startX = undefined;
        this._startScrollLeft = undefined;
        this._clientX = undefined;
        this._direction = undefined;

        if (this._options.scrollToLeftItem && wasDragged) {
            this._isTouchable = false;
            this._scrollToCurrent();
        }
    };

    _closestLinkEl = (el) => {
        while (el && el !== this._el && el.nodeType === Node.ELEMENT_NODE) {
            if (el.tagName.toLowerCase() === 'a') {
                return el;
            }

            el = el.parentNode;
        }

        return null;
    };

    _getLeftElement = (direction) => {
        const scrollElRect = this._scrollEl.getBoundingClientRect();
        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
        const isDirectionLeft = direction < 0;
        const isDirectionRight = direction > 0;

        let index = -1;
        let minOffset = Infinity;

        items.forEach((item, i) => {
            const itemOffset = item.getBoundingClientRect().left - scrollElRect.left;

            if (Math.abs(itemOffset) < Math.abs(minOffset)) {
                minOffset = itemOffset;
                index = i;
            }
        });

        let item = items[index];

        if (Math.abs(minOffset) > this._options.changeItemOffset) {
            if (isDirectionRight && minOffset > 0) {
                item = items[index - 1];
            } else if (isDirectionLeft && minOffset < 0) {
                item = items[index + 1];
            }
        }

        return item;
    };

    _scrollToCurrent = (duration = 200) => {
        const scrollElLeft = this._scrollEl.getBoundingClientRect().left;
        const positionLeft = this._leftItemEl.getBoundingClientRect().left - scrollElLeft;
        const done = () => {
            this._removeFakeItems();
            this._isTouchable = true;

            this._options.onSlideChangeDone();
        };

        if (Math.round(Math.abs(positionLeft)) < 1) {
            done();
            return;
        }

        this._setupFakeItems();

        this.stopAnimation();
        this._scrollElAnimation = new Animation(this._scrollEl, {
            scrollLeft: this._scrollEl.scrollLeft + positionLeft,
        }, {
            duration,
            done,
        });
    };

    _setupFakeItems = () => {
        if (!this._options.isInfinity) {
            return;
        }

        const scrollEl = this._scrollEl;
        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));

        if (items.length === this._itemsLength) {
            const prevScrollLeft = scrollEl.scrollLeft;
            const prevScrollWidth = scrollEl.scrollWidth;
            const parentEl = items[0].parentNode;
            let fragment;

            fragment = document.createDocumentFragment();
            items.forEach(itemEl => fragment.appendChild(itemEl.cloneNode(true)));
            parentEl.insertBefore(fragment, items[0]);

            scrollEl.scrollLeft = prevScrollLeft + (scrollEl.scrollWidth - prevScrollWidth);

            fragment = document.createDocumentFragment();
            items.forEach(itemEl => fragment.appendChild(itemEl.cloneNode(true)));
            parentEl.appendChild(fragment);
        }
    };

    _removeFakeItems = () => {
        if (!this._options.isInfinity) {
            return;
        }

        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));

        if (items.length !== this._itemsLength) {
            const startIndex = items.indexOf(this._leftItemEl);
            const prevScrollLeft = this._scrollEl.scrollLeft;
            const prevScrollWidth = this._scrollEl.scrollWidth;
            const parentEl = items[0].parentNode;

            for (let i = 0; i < startIndex; i += 1) {
                parentEl.removeChild(items[i]);
            }

            this._scrollEl.scrollLeft = prevScrollLeft - (prevScrollWidth - this._scrollEl.scrollWidth);

            for (let i = startIndex + this._itemsLength; i < items.length; i += 1) {
                parentEl.removeChild(items[i]);
            }
        }
    };

    _setLeftItem = (itemEl) => {
        if (this._leftItemEl !== itemEl) {
            this._leftItemEl = itemEl;
            this._options.onSlideChange(ScrollHorizontal.getSlideId(itemEl));
        }
    };

    static setUp(el, itemSelector, options) {
        return new ScrollHorizontal(el, itemSelector, options);
    }

    static getSlideId(itemEl) {
        return _getData(itemEl, slideIdDataKey);
    }
}

export default ScrollHorizontal;
