import { _createEl } from '../../common/_create-el';

export default (data) => {
    const buttonClassName = 'deals-sharing__button';
    const overlayClassNames = [
        'deals-sharing__overlay',
        'js-deals-sharing__overlay',
        data.uniqueMod,
    ];
    const modalClassName = [
        'deals-sharing__modal',
        'js-deals-sharing__modal',
    ];

    if (data.overlayClassName) {
        overlayClassNames.push(data.overlayClassName);
    }

    if (data.modalClassName) {
        modalClassName.push(data.modalClassName);
    }

    return _createEl('div', overlayClassNames.join(' '), [
        _createEl('div', modalClassName.join(' '), {
            'data-sharing-selector': data.uniqueMod,
        }, [
            _createEl('div', 'deals-sharing__buttons', data.buttons.map((buttonData) => {
                const attrs = {
                    ...{ href: buttonData.href },
                    ...buttonData.attrs || {},
                };

                return _createEl('a', `${buttonClassName} js-${buttonClassName} ${buttonData.buttonClass}`, attrs, [
                    _createEl('i', `deals-sharing__button-icon ${buttonData.iconClass}`),
                ]);
            })),
            _createEl('div', 'btn deals-sharing__copy-button js-deals-sharing__copy-button', {
                'role': 'button',
                'data-clipboard-text': data.copyClipboardText,
                'data-success-text': data.copySuccessText,
            }, [data.copyButtonLabel]),
        ]),
    ]);
};
