import components from '../../common/_components';
import { _on } from '../../common/_events';
import { scrollToCommentsWidget } from '../comments-widget/comments-widget';

import './page-deals-view.scss';

const blockName = 'page-deals-view';

components.add(`js-${blockName}__comments-button`, (toCommentsButton) => {
    _on(toCommentsButton, 'click', (e) => {
        scrollToCommentsWidget();
        e.preventDefault();
    });
});
