import components from '../../common/_components';
import { _getCSSProp, _getData, _setData, _reflow, _toArray } from '../../common/_core';
import { _isNumber } from '../../common/_types';
import { _serialize, _sendRequest } from '../../common/_ajax';
import { _on, _off, _onTransitionEnd } from '../../common/_events';
import Tooltip from '../../components.theme/tooltip/tooltip';
import Cookies from '../../vendors.theme/_cookies';

import './promo-telegram.scss';

const ANIMATION_MOD = 'animation';
const SLIDE_SHOWN_MOD = 'shown';
const COOKIE_NAME = 'TELEGRAM_NEWSLETTER_HIDDEN';
const COOKIE_VALUE = 'true';

const blockName = 'promo-telegram';
const hiddenMod = `${blockName}__container_hidden`;
const slidesSelector = `.js-${blockName}__slides`;
const slideSelector = `.js-${blockName}__slide`;
const buttonSelector = `.js-${blockName}__button`;
const backButtonSelector = `.js-${blockName}__back`;
const closeButtonSelector = `.js-${blockName}__close`;
const newsletterFormSelector = `.js-${blockName}__newsletter-form`;

const instances = [];

class PromoBanner {
    constructor(el) {
        this._hasApproved = false;
        this._isFilled = false;

        this._el = el;
        this._bindHandlers();

        instances.push(this);
    }

    _bindHandlers = () => {
        _on(this._el, 'pointerdown', buttonSelector, this._onNavigationBtnClick, { passive: true });
        _on(this._el, 'pointerdown', closeButtonSelector, this._closeButtonHandler, { passive: true });
    };

    _onNavigationBtnClick = (e) => {
        const buttonEl = e._caughtTarget_;
        const destinationSlideNum = parseInt(_getData(buttonEl, 'destSlide'), 10);

        if (!buttonEl.classList.contains('disabled') && _isNumber(destinationSlideNum)) {
            instances.forEach(instance => instance.showStep(destinationSlideNum));
        }
    };

    _closeButtonHandler = () => {
        Cookies.set(COOKIE_NAME, COOKIE_VALUE, {
            expires: 365,
            path: '/',
        });

        instances.forEach(instance => instance.hide());
    };

    _backButtonHandler = (e) => {
        instances.forEach(instance => instance.showStep(1));
        e.preventDefault();
    };

    _onNewsletterSubmit = (e) => {
        const formEl = e.target;
        const inputEl = this._getFromInput();

        this._tooltip = new Tooltip(inputEl, {
            availablePositions: ['bottom-center'],
        });

        e.preventDefault();

        this._tooltip.hide();

        if (!inputEl.disabled) {
            const formData = _serialize(formEl);

            instances.forEach(instance => instance.setFormDisableForm(true));

            _sendRequest({
                method: 'POST',
                url: _getData(formEl, 'ajaxUrl'),
                data: formData,
                success: responseJSON => instances.forEach(instance => instance.applyResponse(responseJSON)),
                error: (responseJSON) => {
                    this._tooltip.show(responseJSON.data.message);

                    _on(window, 'scroll', this._hideTooltip, { once: true });
                    _on(window, 'resize', this._hideTooltip, { once: true });

                    instances.forEach(instance => instance.setFormDisableForm(false));
                },
            });
        }
    }

    _fillSteps = () => {
        if (this._isFilled) {
            return;
        }

        const otherInstances = instances.filter(instance => instance !== this);
        const currentInstanceSlidesEl = this._getSlidesEl();
        const classNameSplitRE = /\s+/g;

        otherInstances.forEach((instance) => {
            instance.getSlidesList().forEach((slideEl) => {
                const slideClassNames = slideEl.className
                    .split(classNameSplitRE)
                    .filter(className => className !== SLIDE_SHOWN_MOD);
                const selector = `.${slideClassNames.join('.')}`;

                if (!currentInstanceSlidesEl.querySelector(selector)) {
                    currentInstanceSlidesEl.appendChild(slideEl.cloneNode(true));
                }
            });
        });

        _on(this._el, 'pointerdown', backButtonSelector, this._backButtonHandler);
        _on(this._el, 'submit', newsletterFormSelector, this._onNewsletterSubmit);

        this._isFilled = true;
    };

    _hideTooltip = () => {
        if (this._tooltip) {
            _off(window, 'scroll', this._hideTooltip);
            _off(window, 'resize', this._hideTooltip);

            this._tooltip.hide();
        }
    };

    _getSlideSelector = (slideNum) => {
        const slideModBase = slideSelector.replace('js-', '');

        return `${slideSelector}${slideModBase}_num_${slideNum}`;
    };

    _getFromInput = () => {
        if (!this._formInputEl) {
            this._formInputEl = this._el.querySelector(`.js-${blockName}__newsletter-form-input`);
        }

        return this._formInputEl;
    }

    _getTranslateY = (slidesEl) => {
        const transformValue = _getCSSProp(slidesEl, 'transform');
        const translateX = /matrix\(-?[.0-9]+, -?[.0-9]+, -?[.0-9]+, -?[.0-9]+, -?[.0-9]+, (-?[.0-9]+)\)/
            .exec(transformValue);

        if (translateX) {
            return parseFloat(translateX[1]);
        }

        return 0;
    };

    _setTranslateY = (slidesEl, value) => {
        slidesEl.style.transform = `translateY(${value}px)`;
    };

    _getClientTop = el => el.getBoundingClientRect().top;

    _getSlidesEl = () => {
        if (!this._slidesEl) {
            this._slidesEl = this._el.querySelector(slidesSelector);
        }

        return this._slidesEl;
    };

    _onTransitionEnd = () => {
        const slidesEl = this._getSlidesEl();

        this._el.classList.remove(ANIMATION_MOD);
        _toArray(slidesEl.querySelectorAll(slideSelector))
            .forEach((slideEl) => {
                if (slideEl !== this._currentSlideEl) {
                    slideEl.classList.remove(SLIDE_SHOWN_MOD);
                }
            });

        slidesEl.style.transform = '';
        this._el.style.height = '';
    };

    getSlidesList = () => {
        const slidesEl = this._getSlidesEl();
        return _toArray(slidesEl.querySelectorAll(slideSelector));
    };

    applyResponse = (responseJSON) => {
        const inputEl = this._getFromInput();

        inputEl.placeholder = responseJSON.data.message;
        inputEl.value = '';
    };

    setFormDisableForm = (isDisabled) => {
        const inputEl = this._getFromInput();
        const submitButtonEl = this._el.querySelector(`.js-${blockName}__newsletter-form-submit`);

        inputEl.disabled = isDisabled === true;
        submitButtonEl.disabled = isDisabled === true;
    };

    showStep = (slideNum) => {
        if (this._el.classList.contains(ANIMATION_MOD)) {
            return;
        }

        this._fillSteps();

        const slidesEl = this._getSlidesEl();
        const currentSlideEl = slidesEl.querySelector(`${slideSelector}.${SLIDE_SHOWN_MOD}`);
        const targetSlideEl = slidesEl.querySelector(this._getSlideSelector(slideNum));
        const prevClientTop = this._getClientTop(currentSlideEl);

        this._el.style.height = `${this._el.offsetHeight}px`;
        targetSlideEl.classList.add(SLIDE_SHOWN_MOD);

        this._setTranslateY(slidesEl, this._getTranslateY(slidesEl)
            - (this._getClientTop(currentSlideEl) - prevClientTop));

        _reflow(this._el);

        this._currentSlideEl = targetSlideEl;

        _onTransitionEnd(slidesEl, this._onTransitionEnd);

        this._el.querySelector(backButtonSelector)
            .classList[slideNum === 1 ? 'remove' : 'add'](SLIDE_SHOWN_MOD);

        this._el.classList.add(ANIMATION_MOD);

        this._setTranslateY(slidesEl, this._getClientTop(slidesEl) - this._getClientTop(targetSlideEl));
        this._el.style.height = `${targetSlideEl.offsetHeight}px`;

        if (!this._hasApproved && slideNum !== 1) {
            const disclaimerButtonEl = this._el.querySelector(`${buttonSelector}[data-dest-disclamer]`);
            const disclaimerSlideNum = parseInt(_getData(disclaimerButtonEl, 'destSlide'), 10);
            const disclaimerSlideSelector = this._getSlideSelector(disclaimerSlideNum);
            const isDisclaimerSlideShown = this._el.querySelector(`${disclaimerSlideSelector}.${SLIDE_SHOWN_MOD}`);

            if (isDisclaimerSlideShown && slideNum !== disclaimerSlideNum) {
                _setData(disclaimerButtonEl, 'destSlide', slideNum);
                this._hasApproved = true;
            }
        }
    };

    hide = () => {
        const height = this._el.offsetHeight;

        if (height > 0) {
            _onTransitionEnd(this._el, () => {
                this._el.classList.add(hiddenMod);
                this._el.classList.remove(ANIMATION_MOD);
                this._el.style.height = '';
            });

            this._el.style.height = `${height}px`;
            this._el.classList.add(ANIMATION_MOD);

            _reflow(this._el);
            this._el.style.height = '0px';
        }
    };
}

components.add(`js-${blockName}__container`, el => new PromoBanner(el));
