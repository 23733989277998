import components from '../../common/_components';
import { _getCSSProp, _isElementVisible, _reflow, _toArray } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { _off, _on, _onTransitionEnd } from '../../common/_events';
import HeadersHeightManager from '../../common/_headers-height-manager';
import { FixedPanelsManager } from '../../common/_fixed-panels-manager';
import { scroll as scrollPage } from '../../common/_scroll-page';
import StickyElement from '../../common/_sticky-element';
import { throttle } from '../../vendors.theme/_throttle-debounce';
import { FullScreenModal } from '../full-screen-modal/full-screen-modal';

import './layout-description.scss';

const ELEMENT_OFFSET = 15;
const HANDLERS_STATE_DESKTOP = 'HANDLERS_STATE_DESKTOP';
const HANDLERS_STATE_MOBILE = 'HANDLERS_STATE_MOBILE';

const blockName = 'layout-description';
const linkActiveClassName = 'active';
const buttonAvailableMod = 'enabled';
const buttonShownMod = 'shown';
const buttonVisibleMod = 'visible';

class LayoutTextIndex {
    constructor(el) {
        this._el = el;
        this._asideEl = el.querySelector(`.js-${blockName}__aside`);
        this._asideFixedEl = el.querySelector(`.js-${blockName}__aside-fixed`);
        this._asideListEl = this._asideFixedEl.querySelector('ul');
        this._buttonEl = el.querySelector(`.js-${blockName}__button`);

        this._modal = new FullScreenModal({
            forMobileOnly: true,
            onClose: this._onModalClose,
        });

        let targetScrollTop;
        this._list = _toArray(this._asideListEl.querySelectorAll('a')).map((linkEl) => {
            const selector = linkEl.getAttribute('href');
            const headerEl = document.getElementById(selector.replace('#', ''));

            _on(linkEl, 'click', (e) => {
                const performScroll = () => {
                    const scrollTop = this._getScrollTop(headerEl);

                    if (scrollTop !== targetScrollTop) {
                        targetScrollTop = scrollTop;

                        scrollPage(targetScrollTop, {
                            onDone: (isCanceled) => {
                                if (!isCanceled) {
                                    HeadersHeightManager.removeStateChangeHandler(performScroll);
                                    targetScrollTop = null;
                                }
                            },
                        });
                    }
                };

                HeadersHeightManager.addStateChangeHandler(performScroll);
                performScroll();

                e.preventDefault();
            });

            return {
                linkEl,
                headerEl,
            };
        });

        FixedPanelsManager.setupPanel(10, false, this._getButtonHeight, this._bottomOffsetChangeHandler);
        FixedPanelsManager.subscribeOnChange(this._onFixedPanelsHeightChange);

        _on(window, 'resize', throttle(200, this._resizeHandler));
        _on(window, 'scroll', throttle(200, this._scrollHandler));

        this._updateHandlersState();
        this._checkHash();
    }

    _resizeHandler = () => {
        this._updateHandlersState();
        this._updateListState();
    };

    _scrollHandler = () => {
        if (this._handlersState === HANDLERS_STATE_DESKTOP) {
            this._updateListState();
        } else if (this._handlersState === HANDLERS_STATE_MOBILE) {
            this._checkButtonVisibility();
        }
    };

    _checkButtonVisibility = () => {
        const buttonEl = this._buttonEl;

        if (!buttonEl.classList.contains(buttonAvailableMod)) {
            buttonEl.classList.remove(buttonShownMod);
            buttonEl.classList.remove(buttonVisibleMod);

            return;
        }

        const elRect = this._el.getBoundingClientRect();
        const windowHeight = document.documentElement.clientHeight;
        const hasVisibleMod = buttonEl.classList.contains(buttonVisibleMod);
        const shouldBeVisible = (elRect.top < windowHeight / 2 && elRect.top + elRect.height > windowHeight / 2);

        if (shouldBeVisible && !hasVisibleMod) {
            if (!buttonEl.classList.contains(buttonShownMod)) {
                buttonEl.classList.add(buttonShownMod);
                _reflow(buttonEl);
            }

            buttonEl.classList.add(buttonVisibleMod);
            FixedPanelsManager.dispatchUpdate();
        } else if (!shouldBeVisible && hasVisibleMod) {
            buttonEl.classList.remove(buttonVisibleMod);
            _onTransitionEnd(buttonEl, this._onButtonTransitionEnd);
        }
    };

    _onButtonTransitionEnd = () => {
        if (!this._buttonEl.classList.contains(buttonVisibleMod)) {
            this._buttonEl.classList.remove(buttonShownMod);
            FixedPanelsManager.dispatchUpdate();
        }
    };

    _getButtonHeight = () => {
        const buttonEl = this._buttonEl;

        if (buttonEl.classList.contains(buttonAvailableMod) && buttonEl.classList.contains(buttonShownMod)) {
            return buttonEl.offsetHeight + parseFloat(_getCSSProp(buttonEl, 'bottom'));
        }

        return 0;
    };

    _bottomOffsetChangeHandler = (offset) => {
        this._buttonEl.style.transform = `translateY(-${offset}px)`;
    };

    _onFixedPanelsHeightChange = (data) => {
        this._updateHandlersState();

        if (this._handlersState === HANDLERS_STATE_DESKTOP) {
            this._asideFixedEl.style.maxHeight = data.compensationHeight > 0
                ? `calc(100vh - ${data.compensationHeight}px - 24px)`
                : '';
        } else {
            this._asideFixedEl.style.maxHeight = '';
        }
    };

    _updateHandlersState = () => {
        const asideElRect = this._asideEl.getBoundingClientRect();

        if (asideElRect.width > 0 && asideElRect.height > 0) {
            if (this._handlersState !== HANDLERS_STATE_DESKTOP) {
                const asideHolderEl = this._asideEl.querySelector(`.js-${blockName}__aside-holder`);
                let fixedElScrollTop;

                this._asideSticky = new StickyElement(this._asideFixedEl, asideHolderEl, {
                    restrictionAreaEl: this._asideEl,
                    pushWithHeader: true,
                    pushedHeaderOffset: 15,
                    beforeStateChange: () => {
                        fixedElScrollTop = this._asideListEl.scrollTop;
                    },
                    afterStateChange: () => {
                        if (fixedElScrollTop) {
                            this._asideListEl.scrollTop = fixedElScrollTop;
                            fixedElScrollTop = null;
                        }
                    },
                });

                _on(window, 'lazyloaded', this._updateListState);
                _on(this._asideFixedEl, '_ads-banner-loaded', this._onAdsBannerLoaded);
                _off(this._buttonEl, 'click', this._buttonClickHandler);

                this._buttonEl.classList.remove(buttonAvailableMod);
                this._handlersState = HANDLERS_STATE_DESKTOP;

                this._checkButtonVisibility();
            }
        } else if (this._handlersState !== HANDLERS_STATE_MOBILE) {
            if (this._asideSticky) {
                this._asideSticky.destroy();
                this._asideSticky = null;
            }

            _on(this._buttonEl, 'click', this._buttonClickHandler);
            _off(this._asideFixedEl, '_ads-banner-loaded', this._onAdsBannerLoaded);
            _off(window, 'lazyloaded', this._updateListState);

            this._buttonEl.classList.add(buttonAvailableMod);
            this._handlersState = HANDLERS_STATE_MOBILE;

            this._checkButtonVisibility();
        }
    };

    _buttonClickHandler = () => {
        if (!this._isOpened) {
            this._open();
        }
    };

    _onAdsBannerLoaded = () => {
        if (this._asideSticky) {
            this._asideSticky.update();
        }
    };

    _open = () => {
        if (!this._isOpened) {
            if (!this._modalBodyEl) {
                this._baseParentEl = this._asideFixedEl.parentNode;
                this._modalBodyInnerEl = _createEl('div', 'container');
                this._modalBodyEl = _createEl('div', `${blockName}__modal-body`, [
                    this._modalBodyInnerEl,
                ]);

                _on(this._modalBodyEl, 'click', this._modalBodyClickHandler);
            }

            this._updateListState();

            this._modalBodyInnerEl.appendChild(this._asideFixedEl);
            this._modal.open(this._modalBodyEl);

            this._isOpened = true;
        }
    };

    _modalBodyClickHandler = (e) => {
        const parentLinkEl = this._closestLinkEl(e.target);

        if (parentLinkEl) {
            this._modal.close();
        }
    };

    _closestLinkEl = (el) => {
        while (el && el !== this._asideFixedEl && el.nodeType === Node.ELEMENT_NODE) {
            if (el.tagName.toLowerCase() === 'a') {
                return el;
            }

            el = el.parentNode;
        }

        return null;
    };

    _onModalClose = () => {
        if (this._isOpened) {
            _toArray(this._modalBodyInnerEl.childNodes).forEach((filtersChildEl) => {
                this._baseParentEl.appendChild(filtersChildEl);
            });

            this._isOpened = false;
        }
    }

    _updateListState = () => {
        const headerHeight = HeadersHeightManager.getHeight();

        let currentIndexItem = this._list[0];

        for (let i = 0; i < this._list.length; i += 1) {
            const indexItem = this._list[i];
            const headerRect = indexItem.headerEl.getBoundingClientRect();

            if (headerRect.top - (ELEMENT_OFFSET + 1) - headerHeight > 0) {
                break;
            }

            currentIndexItem = indexItem;
        }

        if (this._currentActiveItem !== currentIndexItem) {
            this._currentActiveItem = currentIndexItem;

            this._list.forEach((indexItem) => {
                if (indexItem === currentIndexItem) {
                    indexItem.linkEl.classList.add(linkActiveClassName);
                } else {
                    indexItem.linkEl.classList.remove(linkActiveClassName);
                }
            });

            this._scrollToItem(currentIndexItem);
        }
    };

    _scrollToItem = (currentIndexItem) => {
        const fixedPanelScrollTop = this._asideListEl.scrollTop;
        const fixedPanelScrollHeight = this._asideListEl.scrollHeight;
        const fixedPanelRect = this._asideListEl.getBoundingClientRect();

        if (fixedPanelScrollHeight > fixedPanelRect.height) {
            const linkRect = currentIndexItem.linkEl.getBoundingClientRect();
            const centerOfLink = fixedPanelScrollTop + (linkRect.top - fixedPanelRect.top) + linkRect.height / 2;

            let scrollTo = centerOfLink - fixedPanelRect.height / 2;

            if (fixedPanelScrollTop < 0) {
                scrollTo = 0;
            } else if (fixedPanelScrollTop > fixedPanelScrollHeight - fixedPanelRect.height) {
                scrollTo = fixedPanelScrollHeight - fixedPanelRect.height;
            }

            if (scrollTo !== fixedPanelScrollTop) {
                this._asideListEl.scrollTo({ top: scrollTo, behavior: 'smooth' });
            }
        }
    };

    _getScrollTop = (el) => {
        const elOffsetTop = window.pageYOffset + el.getBoundingClientRect().top;
        return elOffsetTop - ELEMENT_OFFSET - HeadersHeightManager.getHeight();
    };

    _checkHash = () => {
        const hash = window.location.hash.slice(1);

        if (!hash) {
            return;
        }

        const targetEl = document.getElementById(hash);

        if (!targetEl || !_isElementVisible(targetEl)) {
            return;
        }

        this._hashScrollOption = {
            targetEl,
            targetScrollTop: this._getScrollTop(targetEl),
            isDone: false,
        };

        _on(window, 'lazyloaded', this._fixTargetScrollTop);

        scrollPage(this._hashScrollOption.targetScrollTop, {
            jump: 100,
            onDone: this._onHashScrollStop,
        });
    };

    _fixTargetScrollTop = () => {
        if (this._hashScrollOption && !this._hashScrollOption.isDone) {
            const elScrollTop = this._getScrollTop(this._hashScrollOption.targetEl);

            if (elScrollTop !== this._hashScrollOption.targetScrollTop) {
                this._hashScrollOption.targetScrollTop = elScrollTop;

                scrollPage(elScrollTop, {
                    onDone: this._onHashScrollStop,
                });
            }
        }
    };

    _onHashScrollStop = (isAborted) => {
        if (!isAborted) {
            _off(window, 'lazyloaded', this._fixTargetScrollTop);

            this._hashScrollOption = null;
        }
    };

    static installTo(asideFixedEl, buttonEl) {
        return new LayoutTextIndex(asideFixedEl, buttonEl);
    }
}

components.add(`js-${blockName}`, el => new LayoutTextIndex(el));
