import components from '../../common/_components';
import { _sendRequest } from '../../common/_ajax';
import { _on } from '../../common/_events';
import { _toArray, _getData } from '../../common/_core';
import './offers-voting.scss';

const blockName = 'offers-voting';
const hasNoVotesMod = `${blockName}_has-no-votes`;

components.add('js-offers-voting', (element) => {
    _on(element, 'click', `.js-${blockName}__button`, (e) => {
        const clickedButtonEl = e._caughtTarget_;

        if (clickedButtonEl.classList.contains('active') || clickedButtonEl.classList.contains('disabled')) {
            return;
        }

        const buttonsEls = _toArray(element.querySelectorAll(`.js-${blockName}__button`));
        const url = _getData(element, 'url');
        const vote = _getData(clickedButtonEl, 'vote');
        const resetButtons = () => {
            buttonsEls.forEach((buttonEl) => {
                const clsList = buttonEl.classList;

                clsList.remove('active');
                clsList.remove('disabled');
            });
        };

        const updateResults = () => {
            const successRateEl = element.querySelector(`.js-${blockName}__success-rate`);
            const votesAmountEl = element.querySelector(`.js-${blockName}__votes-amount`);

            if (!successRateEl || !votesAmountEl) {
                return;
            }

            const votesAmount = parseInt(votesAmountEl.textContent, 10);
            const successAmount = parseInt(successRateEl.textContent, 10) / 100 * votesAmount;
            const successIncrement = Math.max(parseInt(vote, 10), 0);
            const newRateValue = Math.round((successAmount + successIncrement) / (votesAmount + 1) * 100);

            votesAmountEl.textContent = String(votesAmount + 1);
            successRateEl.textContent = String(newRateValue);

            element.classList.remove(hasNoVotesMod);
        };

        _sendRequest({
            method: 'POST',
            url,
            data: {
                vote,
            },
            success: (response) => {
                if (response.success === true) {
                    updateResults();
                } else {
                    resetButtons();
                }
            },
            error: resetButtons,
        });

        buttonsEls.forEach((buttonEl) => {
            buttonEl.classList.add(buttonEl === clickedButtonEl ? 'active' : 'disabled');
        });
    });
});
