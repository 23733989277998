import components from '../../common/_components';
import { _sendRequest } from '../../common/_ajax';
import { _getData, _parseHTML, _toArray } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { _on, _trigger, _off } from '../../common/_events';
import { _getSvgIcon } from '../../common/_svg-icon';
import StickyElement from '../../common/_sticky-element';
import HeadersHeightManager from '../../common/_headers-height-manager';
import { scroll as scrollPage } from '../../common/_scroll-page';
import { FullScreenModal } from '../full-screen-modal/full-screen-modal';
import Tooltip from '../../components.theme/tooltip/tooltip';
import { ensureLogin } from '../auth-modal/auth-modal';
import { debounce } from '../../vendors.theme/_throttle-debounce';
import './comments-widget.scss';

const blockName = 'comments-widget';
const emptyMod = `${blockName}_empty`;
const modalClassName = `js-${blockName}__modal`;
const closeEvent = `${blockName}:modal-close`;

const SCROLL_ELEMENT_OFFSET = 15;

let commentsWidget;
let targetScrollTop;

class CommentsWidget {
    constructor(el) {
        this._el = el;
        this._bodyEl = el.querySelector(`.js-${blockName}__body`);
        this._hasHandlerAttached = false;

        if (this._el.classList.contains(emptyMod)) {
            _on(el.querySelector(`.js-${blockName}__empty-form-submit`), 'click', this._onEmptyFormSubmit);
        }

        HeadersHeightManager.addStateChangeHandler(() => {
            if (Math.abs(this._el.offsetWidth - this._bodyEl.offsetWidth) > 5) {
                if (!this._sticky) {
                    const adSlotEl = el.querySelector(`.js-${blockName}__aside-adslot`);

                    this._sticky = new StickyElement(
                        adSlotEl,
                        el.querySelector(`.js-${blockName}__aside`),
                        { restrictionAreaEl: this._bodyEl },
                    );

                    if (!this._hasHandlerAttached) {
                        _on(adSlotEl, '_ads-banner-loaded', this._updateSticky);
                        _on(this._bodyEl, '_nodes-inserted', this._updateSticky);

                        this._hasHandlerAttached = true;
                    }
                }
            } else if (this._sticky) {
                this._sticky.destroy();
                this._sticky = null;
            }
        });
    }

    /**
     *
     * @param [options]
     * @param [options.parentId]
     * @param [options.parentUsername]
     * @param [options.parentCommentHTML]
     * @param [options.url]
     * @param [options.modalTitle]
     * @param [options.sendLabel]
     * @param [options.commentHTM]
     * @param {Function} [options.onClose]
     * @param {Function} [options.onPositingSuccess]
     */
    showModal = (options = {}) => {
        ensureLogin(() => this._showModal({
            ...{
                modalTitle: _getData(this._el, 'modalTitle'),
                commentLabel: _getData(this._el, 'commentLabel'),
                sendLabel: _getData(this._el, 'modalSend'),
                url: _getData(this._el, 'commentUrl'),
                parentId: null,
                parentUsername: null,
                parentCommentHTML: null,
                commentHTML: null,
                onClose: () => {},
                onPositingSuccess: this._addComment,
            },
            ...options,
        }));
    };

    removeComment = commentEl => this._removeComment(commentEl);

    getEl = () => this._el;

    _updateSticky = () => {
        if (this._sticky) {
            this._sticky.update();
        }
    }

    _showModal = (options) => {
        const submitButtonEl = _createEl('span', `btn btn_size_lg ${blockName}__modal-submit`, [options.sendLabel]);
        const commentInputEl = _createEl(
            'textarea',
            `form-control ${blockName}__comment-input`,
            { name: 'comment' },
            [options.commentHTML],
        );
        const modalEl = _createEl('div', `${blockName}__modal ${modalClassName}`, [
            _createEl('div', `${blockName}__modal-container`, [
                _createEl('div', `${blockName}__modal-heading`, [
                    _createEl('span', `${blockName}__modal-title`, [options.modalTitle]),
                ]),
                _createEl('div', `${blockName}__modal-row`, [
                    this._getParentEl({
                        parentId: options.parentId,
                        parentUsername: options.parentUsername,
                        parentCommentHTML: options.parentCommentHTML,
                    }),
                    _createEl('div', `${blockName}__modal-label`, [options.commentLabel]),
                    commentInputEl,
                ]),
                _createEl('div', `${blockName}__modal-controls`, [submitButtonEl]),
            ]),
        ]);

        _on(modalEl, closeEvent, options.onClose);
        _on(submitButtonEl, 'click', () => {
            if (!submitButtonEl.classList.contains('disabled')) {
                submitButtonEl.classList.add('disabled');

                this._sendForm(this._modal.getBody(), {
                    url: options.url,
                    onSuccess: (commentEl) => {
                        options.onPositingSuccess(commentEl);
                        this._modal.close();
                    },
                    onError: () => submitButtonEl.classList.remove('disabled'),
                });
            }
        });

        if (!this._modal) {
            this._modal = new FullScreenModal({
                onClose: this._onModalClose,
            });
        }

        this._modal.open(modalEl);
        setTimeout(() => commentInputEl.focus(), 0);
    };

    _onModalClose = () => {
        const modalBodyEl = this._modal.getBody();
        const currentModalEl = this._modal.getBody().querySelector(`.${modalClassName}`);

        this._hideTooltips(modalBodyEl);

        if (currentModalEl) {
            _trigger(currentModalEl, closeEvent);
        }
    };

    _onEmptyFormSubmit = (e) => {
        ensureLogin(() => {
            const submitButtonEl = e._caughtTarget_;
            const formEl = this._el.querySelector(`.${blockName}__empty-form`);

            if (!submitButtonEl.classList.contains('disabled')) {
                submitButtonEl.classList.add('disabled');

                this._sendForm(formEl, {
                    url: _getData(this._el, 'commentUrl'),
                    onSuccess: (commentEl) => {
                        this._addComment(commentEl);
                        this._el.classList.remove(emptyMod);
                    },
                    onError: () => {
                        if (!this._hideTooltipsDebounced) {
                            this._hideTooltipsDebounced = debounce(150, false, () => {
                                this._hideTooltips(formEl);
                                _off(window, 'scroll', this._hideTooltipsDebounced);
                            });
                        }

                        _on(window, 'scroll', this._hideTooltipsDebounced);
                        submitButtonEl.classList.remove('disabled');
                    },
                });
            }
        });
    }

    _sendForm = (formEl, { url, onSuccess, onError }) => {
        const inputs = _toArray(formEl.querySelectorAll('input, textarea'));
        const data = {};

        inputs.forEach((inputEl) => {
            if (this._isUserInput(inputEl)) {
                const tooltip = new Tooltip(inputEl);

                tooltip.hide();
                inputEl.disabled = true;
            }

            data[inputEl.name] = inputEl.value;
        });

        if (!Object.values(data).join('').replace(/[\s\r\n\t]/g, '').length) {
            let firstInputEl;

            inputs.forEach((inputEl) => {
                if (this._isUserInput(inputEl)) {
                    inputEl.disabled = false;

                    if (!firstInputEl) {
                        firstInputEl = inputEl;
                    }
                }
            });

            if (firstInputEl) {
                setTimeout(() => firstInputEl.focus(), 100);
            }

            onError();
            return;
        }

        _sendRequest({
            method: 'POST',
            url,
            data,
            always: (isSuccess, response) => {
                if (isSuccess && response.success === true) {
                    const commentEl = document.createDocumentFragment();

                    _parseHTML(response.comment).forEach(childEl => commentEl.appendChild(childEl));

                    onSuccess(commentEl);
                } else {
                    const errors = response.errors || {};

                    inputs.forEach((inputEl) => {
                        if (errors[inputEl.name] && this._isUserInput(inputEl)) {
                            const tooltip = new Tooltip(inputEl);

                            const key = Object.keys(errors[inputEl.name])[0];
                            tooltip.show(errors[inputEl.name][key]);
                            _on(inputEl, 'input', () => tooltip.hide(), { once: true });
                        }

                        inputEl.disabled = false;
                    });

                    onError();
                }
            },
        });
    };

    _isUserInput = inputEl => inputEl.type !== 'hidden';

    _getParentEl = (options) => {
        if (options.parentUsername && options.parentCommentHTML) {
            const parentBodyEl = _createEl('div', `${blockName}__parent-body js-${blockName}__parent-body`);
            parentBodyEl.innerHTML = options.parentCommentHTML;

            return _createEl('div', `${blockName}__parent`, [
                options.parentId ? _createEl('input', {
                    name: 'parentId',
                    value: options.parentId,
                    type: 'hidden',
                }) : null,
                _getSvgIcon('quote', `${blockName}__parent-quote-left`),
                _createEl(
                    'div',
                    `${blockName}__parent-heading js-${blockName}__parent-heading`,
                    [options.parentUsername],
                ),
                parentBodyEl,
            ]);
        }

        return null;
    };

    _addComment = (commentEl) => {
        const listEl = this._el.querySelector(`.js-${blockName}__list`);

        listEl.insertBefore(commentEl, listEl.firstChild);
        this._updateTitle(1);

        components.init();
    };

    _removeComment = (commentEl) => {
        if (this._bodyEl.contains(commentEl)) {
            commentEl.parentNode.removeChild(commentEl);
            this._updateTitle(-1);
        }
    }

    _updateTitle = (diff) => {
        const titleValueEl = this._el.querySelector(`.js-${blockName}__heading-title-value`);
        titleValueEl.textContent = String(parseInt(titleValueEl.textContent, 10) + diff);
    };

    _hideTooltips = (areaEl) => {
        const inputs = _toArray(areaEl.querySelectorAll('input, textarea'));

        inputs.forEach((inputEl) => {
            if (this._isUserInput(inputEl)) {
                const tooltip = new Tooltip(inputEl);
                tooltip.hide();
            }
        });
    };
}

function commentsWidgetModalShow(options) {
    if (commentsWidget) {
        commentsWidget.showModal(options);
    }
}

function commentsWidgetRemoveComment(commentEl) {
    if (commentsWidget) {
        commentsWidget.removeComment(commentEl);
    }
}

function scrollToCommentsWidget() {
    const commentsEl = commentsWidget ? commentsWidget.getEl() : null;
    const performScroll = () => {
        const elOffsetTop = window.pageYOffset + commentsEl.getBoundingClientRect().top;
        const scrollTop = elOffsetTop - SCROLL_ELEMENT_OFFSET - HeadersHeightManager.getHeight();

        if (scrollTop !== targetScrollTop) {
            targetScrollTop = scrollTop;

            scrollPage(targetScrollTop, {
                onDone: (isCanceled) => {
                    if (!isCanceled) {
                        HeadersHeightManager.removeStateChangeHandler(performScroll);
                        targetScrollTop = null;
                    }
                },
            });
        }
    };

    if (!commentsEl) {
        return;
    }

    targetScrollTop = null;

    HeadersHeightManager.addStateChangeHandler(performScroll);
    performScroll();
}


components.add(`js-${blockName}`, (el) => {
    const hashChunks = window.location.hash.slice(1).split(/[&=]/);

    commentsWidget = new CommentsWidget(el);

    _on(el.querySelector(`.js-${blockName}__add-link`), 'click', () => {
        commentsWidget.showModal();
    });

    if (hashChunks.indexOf('comments') > -1) {
        scrollToCommentsWidget();
    }
});

export { commentsWidgetModalShow, commentsWidgetRemoveComment, scrollToCommentsWidget };
