import { _detachEl, _getCSSProp } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { _on, _off } from '../../common/_events';
import { throttle } from '../../vendors/_throttle-debounce';
import HeadersHeightManager from '../../common/_headers-height-manager';
import './tooltip.scss';

const BOTTOM_LEFT = 'bottom-left';
const BOTTOM_RIGHT = 'bottom-right';
const BOTTOM_CENTER = 'bottom-center';
const TOP_LEFT = 'top-left';
const TOP_RIGHT = 'top-right';
const TOP_CENTER = 'top-center';
const LEFT_MIDDLE = 'left-middle';
const RIGHT_MIDDLE = 'right-middle';

const blockName = 'tooltip';

const positionRegExp = new RegExp(`(^|\\s)${blockName}_position_\\S+`, 'g');
const dataKey = `${blockName}--instance`;

class Tooltip {
    constructor(element, config) {
        const instance = element[dataKey];

        if (instance && instance instanceof Tooltip) {
            return instance;
        }

        this._config = {
            ...{
                availablePositions: [
                    TOP_CENTER,
                    BOTTOM_CENTER,
                    TOP_LEFT,
                    TOP_RIGHT,
                    BOTTOM_LEFT,
                    BOTTOM_RIGHT,
                    LEFT_MIDDLE,
                    RIGHT_MIDDLE,
                    TOP_CENTER,
                ],
                horizontalOffset: 6,
                arrowWidth: 12,
                arrowHeight: 6,
            },
            ...typeof config === 'object' && config ? config : {},
        };

        this._element = element;
        this._scrollableElement = [];
        this._tooltipEl = _createEl('div', `${blockName} ${blockName}_color_red`);
        this._arrowEl = _createEl('div', `${blockName}__arrow`);
        this._innerEl = _createEl('div', `${blockName}__inner`);

        this._tooltipEl.appendChild(this._arrowEl);
        this._tooltipEl.appendChild(this._innerEl);

        this._resizeHandler = throttle(150, this._resizeHandler);

        element[dataKey] = this;
    }

    show = (message) => {
        if (!this._isShown) {
            this._setContent(message);
            this._scrollableElement = this._getScrollableElements();

            this._attachTooltip();
            this._checkPosition();

            _on(window, 'resize', this._resizeHandler);
            this._scrollableElement.forEach(scrollableEl => _on(scrollableEl, 'scroll', this._resizeHandler));

            this._isShown = true;
        }
    };

    hide = () => {
        _off(window, 'resize', this._resizeHandler);
        this._scrollableElement.forEach(scrollableEl => _off(scrollableEl, 'scroll', this._resizeHandler));

        this._detachTooltip();

        this._isShown = false;
    };

    _getScrollableElements = () => {
        const scrollableElements = [document];
        let el = this._element.parentNode;

        while (el && el.nodeType === Node.ELEMENT_NODE) {
            if (['auto', 'scroll'].indexOf(_getCSSProp(el, 'overflow')) !== -1) {
                scrollableElements.push(el);
            }

            el = el.parentNode;
        }

        return scrollableElements;
    };

    _attachTooltip = () => {
        const containerEL = document.body;

        if (!containerEL.contains(this._tooltipEl)) {
            containerEL.appendChild(this._tooltipEl);
        }
    };

    _detachTooltip = () => {
        _detachEl(this._tooltipEl);
    };

    _setContent = (content) => {
        this._innerEl.innerText = content;
    };

    _getPositionValidator = () => {
        const headerHeight = HeadersHeightManager.getHeight();
        const pageScrollTop = window.pageYOffset;
        const pageScrollLeft = window.pageXOffset;
        const pageWidth = document.documentElement.clientWidth;
        const pageHeight = document.documentElement.clientHeight;

        return (left, top, width, height) => {
            const minTop = pageScrollTop + headerHeight;
            const maxTop = pageScrollTop + pageHeight;
            const minLeft = pageScrollLeft;
            const maxLeft = pageScrollLeft + pageWidth;

            return top > minTop && top + height < maxTop && left > minLeft && left + width < maxLeft;
        };
    };

    _checkPosition = () => {
        this._setPosition();

        const elemRect = this._element.getBoundingClientRect();
        const elemOffset = { top: elemRect.top + window.pageYOffset, left: elemRect.left + window.pageXOffset };
        const tooltipRect = this._innerEl.getBoundingClientRect();
        const { arrowHeight, arrowWidth, horizontalOffset } = this._config;
        const positions = this._config.availablePositions.slice();
        const isAtScreen = this._getPositionValidator();
        const arrowOffset = elemRect.width / 2 - arrowWidth / 2 + horizontalOffset;

        let top;
        let left;
        let position;
        let arrowPosition;

        while (positions.length) {
            position = positions.shift();

            switch (position) {
                case BOTTOM_LEFT:
                    top = elemOffset.top + elemRect.height + arrowHeight;
                    left = elemOffset.left - horizontalOffset;
                    arrowPosition = arrowOffset;
                    break;
                case BOTTOM_RIGHT:
                    top = elemOffset.top + elemRect.height + arrowHeight;
                    left = elemOffset.left + elemRect.width - tooltipRect.width + horizontalOffset;
                    arrowPosition = -arrowOffset;
                    break;
                case BOTTOM_CENTER:
                    top = elemOffset.top + elemRect.height + arrowHeight;
                    left = elemOffset.left + elemRect.width / 2 - tooltipRect.width / 2;
                    arrowPosition = 0;
                    break;
                case TOP_LEFT:
                    top = elemOffset.top - tooltipRect.height - arrowHeight;
                    left = elemOffset.left - horizontalOffset;
                    arrowPosition = arrowOffset;
                    break;
                case TOP_RIGHT:
                    top = elemOffset.top - tooltipRect.height - arrowHeight;
                    left = elemOffset.left + elemRect.width - tooltipRect.width + horizontalOffset;
                    arrowPosition = -arrowOffset;
                    break;
                case TOP_CENTER:
                    top = elemOffset.top - tooltipRect.height - arrowHeight;
                    left = elemOffset.left + elemRect.width / 2 - tooltipRect.width / 2;
                    arrowPosition = 0;
                    break;
                case LEFT_MIDDLE:
                    top = elemOffset.top + elemRect.height / 2 - tooltipRect.height / 2;
                    left = elemOffset.left - tooltipRect.width - arrowHeight;
                    arrowPosition = 0;
                    break;
                case RIGHT_MIDDLE:
                    top = elemOffset.top + elemRect.height / 2 - tooltipRect.height / 2;
                    left = elemOffset.left + elemRect.width + arrowHeight;
                    arrowPosition = 0;
                    break;
                default:
                    break;
            }

            top = Math.round(top);
            left = Math.round(left);

            if (isAtScreen(left, top, tooltipRect.width, tooltipRect.height)) {
                break;
            }
        }

        this._setPosition(position, top, left, arrowPosition);
    };

    _setPosition = (position = null, top = '', left = '', arrowPosition = 0) => {
        const key = `${position}--${top}x${left}`;

        if (this._position !== key) {
            const positionClass = position ? `${blockName}_position_${position}` : '';
            const classes = this._tooltipEl.className;

            if (` ${classes} `.indexOf(` ${positionClass} `) === -1) {
                this._tooltipEl.className = classes.replace(positionRegExp, '');

                if (positionClass) {
                    this._tooltipEl.classList.add(positionClass);
                }
            }

            this._tooltipEl.style.top = top ? `${top}px` : '';
            this._tooltipEl.style.left = left ? `${left}px` : left;

            this._arrowEl.style.left = arrowPosition > 0 ? `${arrowPosition}px` : '';
            this._arrowEl.style.right = arrowPosition < 0 ? `-${arrowPosition}px` : '';

            this._position = key;
        }
    };

    _resizeHandler = () => {
        if (this._isShown) {
            this._checkPosition();
        }
    };
}

export default Tooltip;
