import components from '../../common/_components';
import bugsnag from '../../common/_bugsnag-client';
import { _getData } from '../../common/_core';

const blockName = 'queries-promotion';

const cursorChar = 'l';
const delayChar = '||';

class QueriesPromotion {
    setInput = (inputEl) => {
        if (this._inputEl !== inputEl) {
            if (this._inputEl) {
                inputEl.setAttribute('placeholder', this._inputEl.getAttribute('placeholder'));
            }

            this._inputEl = inputEl;
        }
    };

    setQueries = (queries) => {
        const sequence = [delayChar];

        if (queries.length && !this._sequence) {
            queries.forEach((query) => {
                const regularSequence = [''];

                let i = 1;

                while (i <= query.length) {
                    regularSequence.push(query.substr(0, i));
                    i += 1;
                }

                Array.prototype.push.apply(sequence, regularSequence.slice(1));
                Array.prototype.push.apply(sequence, [delayChar]);
                Array.prototype.push.apply(sequence, regularSequence.reverse().slice(1));
            });

            this._sequence = sequence;
        }
    };

    start = () => {
        if (this._isStarted) {
            return;
        }

        if (!this._sequence || this._sequence.length === 0) {
            return;
        }

        if (!this._inputEl) {
            bugsnag.notify('QueriesPromotion has not input element');
            return;
        }

        if (this._inputEl.value === '') {
            this.stop();
            this._step();

            this._isStarted = true;
        }
    };

    stop = () => {
        if (!this._sequence || this._sequence.length === 0) {
            return;
        }

        this._pointer = 0;
        this._isStarted = false;

        clearTimeout(this._stepTimeout);
        clearTimeout(this._cursorBlinkingTimeout);

        if (this._inputEl) {
            this._inputEl.setAttribute('placeholder', '');
        }
    };

    _step = () => {
        const placeholder = this._sequence[this._pointer];

        this._pointer += 1;

        if (this._pointer >= this._sequence.length) {
            this._pointer = 0;
        }

        clearTimeout(this._cursorBlinkingTimeout);

        if (placeholder === delayChar) {
            this._cursorBlinkingStep(true);
            this._stepTimeout = setTimeout(this._step, 3000);
        } else {
            this._inputEl.setAttribute('placeholder', `${placeholder}${cursorChar}`);
            this._stepTimeout = setTimeout(this._step, 80);
        }
    };

    _cursorBlinkingStep = (isFirst) => {
        let value = this._inputEl.getAttribute('placeholder');

        if (value.charAt(value.length - 1) === cursorChar) {
            if (isFirst !== true) {
                value = value.slice(0, -1);
            }
        } else {
            value += cursorChar;
        }

        this._inputEl.setAttribute('placeholder', value);
        this._cursorBlinkingTimeout = setTimeout(this._cursorBlinkingStep, 500);
    };
}

const queriesPromotion = new QueriesPromotion();

components.add(`js-${blockName}__queries`, (componentEl) => {
    queriesPromotion.setQueries(JSON.parse(_getData(componentEl, 'promotionQueries')) || []);
});

export default queriesPromotion;
