import bugsnag from '../../common/_bugsnag-client';
import components from '../../common/_components';
import { _isNil, _isElement, _isString, _isNumber } from '../../common/_types';
import { _on, _trigger } from '../../common/_events';
import { _replaceState } from '../../common/_history';
import {
    _closest,
    _detachEl,
    _forIn,
    _getCSSProp,
    _getData,
    _isElementVisible,
    _parseHTML,
    _toArray,
} from '../../common/_core';
import { _parseQueryString, _sendRequest, _stringify } from '../../common/_ajax';
import { scroll } from '../../common/_scroll-page';
import HeadersHeightManager from '../../common/_headers-height-manager';
import { throttle } from '../../vendors/_throttle-debounce';

import './load-more-page.scss';

const PAGE_NEXT = 'NEXT';
const PAGE_PREV = 'PREV';

const PAGINATION_QUERY_PARAMETER = 'page';
const PARAM_JUMP = 'jump';

const blockName = 'load-more-page';
const loadingMod = `${blockName}_loading`;
const preloadNextMod = `${blockName}_preload_next`;

const sideClassName = `${blockName}__side`;
const sideLoadingMod = `${blockName}__side_loading`;
const sidePositionTopMod = `${blockName}__side_pos_top`;
const sidePositionBottomMod = `${blockName}__side_pos_bottom`;

const pages = {};
const loadingPages = {};
const currentPage = {};

function _addLoader(sideEl) {
    if (sideEl && !sideEl.classList.contains(sideLoadingMod)) {
        const loadingEl = _parseHTML(`<i class="fa fa-spin fa-circle-notch ${blockName}__spinner"></i>`)[0];
        const sideHeight = parseFloat(_getCSSProp(sideEl, 'height'));

        sideEl.style.height = `${sideHeight}px`;

        sideEl.classList.add(sideLoadingMod);
        sideEl.appendChild(loadingEl);
    }
}

function _removeLoader(sideEl) {
    if (sideEl && sideEl.classList.contains(sideLoadingMod)) {
        const loadingEl = sideEl.querySelector(`.${blockName}__spinner`);

        if (loadingEl && loadingEl.parentNode) {
            loadingEl.parentNode.removeChild(loadingEl);
        }

        sideEl.classList.remove(sideLoadingMod);
        sideEl.style.height = '';
    }
}

function _getPaginationQueryParam(scope) {
    if (scope) {
        return `${scope}[${PAGINATION_QUERY_PARAMETER}]`;
    }

    return PAGINATION_QUERY_PARAMETER;
}

function _load(pageEl, pageToLoad) {
    const basePageNum = _getData(pageEl, 'loadMorePage');
    const paginatorName = _getData(pageEl, 'loadMoreParam');
    const pagesUrl = _getData(pageEl, 'loadMoreUrl');
    const paginationQueryParam = _getPaginationQueryParam(_getData(pageEl, 'loadMoreScope'));

    let sideEl;
    let queryData;

    if (!_isValidPageNum(basePageNum)) {
        return;
    }

    if (pageToLoad === PAGE_NEXT) {
        sideEl = pageEl.querySelector(`.js-${sideClassName}.${sidePositionBottomMod}`);

        if (sideEl) {
            queryData = {
                ...{ [paginationQueryParam]: parseInt(basePageNum, 10) + 1 },
                ...JSON.parse(_getData(sideEl, 'loadMoreQuery')),
            };
        }
    } else if (pageToLoad === PAGE_PREV) {
        sideEl = pageEl.querySelector(`.js-${sideClassName}.${sidePositionTopMod}`);

        if (sideEl) {
            queryData = {
                ...{ [paginationQueryParam]: parseInt(basePageNum, 10) - 1 },
                ...JSON.parse(_getData(sideEl, 'loadMoreQuery')),
            };
        }
    } else if (_isValidPageNum(pageToLoad)) {
        queryData = {
            [paginationQueryParam]: parseInt(pageToLoad, 10),
        };
    }

    const newPageNum = queryData && queryData[paginationQueryParam];

    if (_isNumber(newPageNum) && !loadingPages[newPageNum]) {
        if (pageToLoad === PAGE_PREV || pageToLoad === PAGE_NEXT) {
            _addLoader(sideEl);
            loadingPages[newPageNum] = sideEl;
        } else {
            pageEl.classList.add(loadingMod);
            _toArray(pageEl.querySelectorAll(`.js-${sideClassName}`))
                .forEach(el => _addLoader(el));

            loadingPages[newPageNum] = pageEl;
        }

        _sendRequest({
            url: pagesUrl,
            data: queryData,
            success: (data) => {
                const pageParentEl = pageEl.parentNode;
                const documentFragment = document.createDocumentFragment();

                let eventTargetEl = pageEl;

                if (!pageParentEl) {
                    return;
                }

                _parseHTML(data).forEach(contentNode => documentFragment.appendChild(contentNode));

                if (pageToLoad === PAGE_PREV) {
                    _detachEl(loadingPages[newPageNum]);
                    pageParentEl.insertBefore(documentFragment, pageEl);
                } else if (pageToLoad === PAGE_NEXT) {
                    _detachEl(loadingPages[newPageNum]);

                    if (pageEl.nextSibling) {
                        pageParentEl.insertBefore(documentFragment, pageEl.nextSibling);
                    } else {
                        pageParentEl.appendChild(documentFragment);
                    }
                } else {
                    pageParentEl.replaceChild(documentFragment, pageEl);
                    delete pages[paginatorName][basePageNum];

                    eventTargetEl = pageParentEl;
                }

                delete loadingPages[newPageNum];
                components.init();

                _trigger(eventTargetEl, '_nodes-inserted');
            },
            error: () => {
                if (loadingPages[newPageNum]) {
                    if (pageToLoad === PAGE_PREV || pageToLoad === PAGE_NEXT) {
                        _removeLoader(loadingPages[newPageNum]);
                    } else {
                        pageEl.classList.remove(loadingMod);
                        _toArray(pageEl.querySelectorAll(`.js-${sideClassName}`))
                            .forEach(el => _removeLoader(el));
                    }

                    delete loadingPages[newPageNum];
                }
            },
        });
    }
}

function _isValidPageNum(pageNum) {
    return /^[1-9][0-9]*$/g.test(pageNum);
}

function _isHashString(testStr) {
    return _isString(testStr) && testStr[0] === '#';
}

function _isHashPaginator(paginatorName) {
    return _isHashString(paginatorName);
}

function _sanitizeHashString(hashString) {
    return hashString.replace(/^#/, '');
}

function _getPageNumFromSearch(paginatorName) {
    const pageNum = _parseQueryString(window.location.search.slice(1))[paginatorName];

    if (_isValidPageNum(pageNum)) {
        const pageEl = pages[paginatorName][pageNum];

        if (!_isNil(pageEl) && _isElement(pageEl) && _isElementVisible(pageEl)) {
            return pageNum;
        }
    }

    return undefined;
}

function _getPageNumFromHash(paginatorName) {
    const viewPaginatorName = _sanitizeHashString(paginatorName);
    const pageNum = _parseQueryString(_sanitizeHashString(window.location.hash))[viewPaginatorName];

    if (_isValidPageNum(pageNum)) {
        return pageNum;
    }

    return undefined;
}

function _getUpdatedHash(paginatorName, changesString) {
    const hashData = _parseQueryString(_sanitizeHashString(window.location.hash));
    const changesData = _parseQueryString(_sanitizeHashString(changesString));
    const viewPaginatorName = _sanitizeHashString(paginatorName);

    if (_isValidPageNum(changesData[viewPaginatorName])) {
        hashData[viewPaginatorName] = changesData[viewPaginatorName];
    } else {
        delete hashData[viewPaginatorName];
    }

    const stringifiedHash = _stringify(hashData);

    if (stringifiedHash) {
        return `#${stringifiedHash}`;
    }

    return '';
}

function _checkQueryPaginator(paginatorPages, paginatorName, queryData) {
    const windowHeight = document.documentElement.clientHeight;

    let distance = Infinity;
    let nearestPageNum = null;
    let needsPreloadNext = false;

    _forIn(paginatorPages, (pageEl, pageNum) => {
        if (!_isElementVisible(pageEl)) {
            delete paginatorPages[pageNum];
            return;
        }

        const rect = pageEl.getBoundingClientRect();
        const currentDistance = Math.abs(windowHeight / 2 - (rect.top + rect.height / 2));

        if (currentDistance < distance) {
            distance = currentDistance;
            nearestPageNum = pageNum;
            needsPreloadNext = pageEl.classList.contains(preloadNextMod)
                && rect.bottom - 100 < windowHeight
                && rect.bottom + 50 > 0;
        }
    });

    if (nearestPageNum !== currentPage[paginatorName] && _isValidPageNum(nearestPageNum)) {
        currentPage[paginatorName] = nearestPageNum;
        queryData[paginatorName] = nearestPageNum === '1' ? null : nearestPageNum;
    }

    if (needsPreloadNext) {
        const latestLoadedPageNum = Object.keys(paginatorPages)
            .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
            .pop();

        if (nearestPageNum === latestLoadedPageNum) {
            _load(paginatorPages[latestLoadedPageNum], PAGE_NEXT);
        }
    }
}

function _checkHashPaginator(paginatorPages, paginatorName, hashData) {
    const hashPageNum = _getPageNumFromHash(paginatorName);
    const windowHalfHeight = document.documentElement.clientHeight / 2;
    const loadedPageNums = Object.keys(paginatorPages);

    let pageBelowTheScreen;

    while (loadedPageNums.length) {
        const pageNum = loadedPageNums.shift();
        const rect = paginatorPages[pageNum].getBoundingClientRect();

        if (windowHalfHeight > rect.top && windowHalfHeight < (rect.top + rect.height)) {
            pageBelowTheScreen = pageNum;
            break;
        }
    }

    if (pageBelowTheScreen !== currentPage[paginatorName]
        || (!loadingPages[hashPageNum] && pageBelowTheScreen !== hashPageNum)) {
        currentPage[paginatorName] = pageBelowTheScreen;
        hashData[_sanitizeHashString(paginatorName)] = pageBelowTheScreen === '1' ? null : pageBelowTheScreen;
    }
}

function _check() {
    const queryData = {};
    const hashData = {};
    const locationSearch = window.location.search;
    const locationHash = window.location.hash;

    let considerableSearch = locationSearch;
    let considerableHash = locationHash;

    _forIn(pages, (paginatorPages, paginatorName) => {
        if (_isHashPaginator(paginatorName)) {
            _checkHashPaginator(paginatorPages, paginatorName, hashData);
        } else {
            _checkQueryPaginator(paginatorPages, paginatorName, queryData);
        }
    });

    if (Object.keys(queryData).length > 0) {
        considerableSearch = _stringify({
            ..._parseQueryString(locationSearch.slice(1)),
            ...queryData,
        });

        if (considerableSearch !== '') {
            considerableSearch = `?${considerableSearch}`;
        }
    }

    if (Object.keys(hashData).length > 0) {
        considerableHash = _stringify({
            ..._parseQueryString(_sanitizeHashString(locationHash)),
            ...hashData,
        });

        if (considerableHash !== '') {
            considerableHash = `#${considerableHash}`;
        }
    }

    if (locationSearch !== considerableSearch || locationHash !== considerableHash) {
        _replaceState(`${window.location.pathname}${considerableSearch}${considerableHash}`);
    }
}

function _hashChangeHandler() {
    _forIn(pages, (paginatorPages, paginatorName) => {
        if (_isHashPaginator(paginatorName)) {
            const pageNum = _getPageNumFromHash(paginatorName);

            if (pageNum && !paginatorPages[pageNum] && !loadingPages[pageNum]) {
                const pageNumInt = parseInt(pageNum, 10);
                const loadedPageNums = Object.keys(paginatorPages)
                    .sort((a, b) => parseInt(a, 10) - parseInt(b, 10));

                if ((pageNumInt + 1) === parseInt(loadedPageNums[0], 10)) {
                    _load(paginatorPages[loadedPageNums[0]], PAGE_PREV);
                } else if ((pageNumInt - 1) === parseInt(loadedPageNums[loadedPageNums.length - 1], 10)) {
                    _load(paginatorPages[loadedPageNums[loadedPageNums.length - 1]], PAGE_NEXT);
                } else if (loadedPageNums.length === 1) {
                    _load(paginatorPages[loadedPageNums[0]], pageNum);
                } else {
                    bugsnag.notify('Invalid page number');
                }
            }
        }
    });
}

let isScrollHandlersAttached = false;
let isButtonHandlerAttached = false;
let isHashChangeHandlerAttached = false;

function _attachScrollHandler() {
    if (!isScrollHandlersAttached) {
        const scrollHandler = throttle(200, _check);

        _on(window, 'scroll', scrollHandler);
        _on(window, 'resize', scrollHandler);

        isScrollHandlersAttached = true;
    }
}

function _attachHashChangeHandler() {
    if (!isHashChangeHandlerAttached) {
        _on(window, 'hashchange', _hashChangeHandler);
        isHashChangeHandlerAttached = true;
    }
}

function _attachButtonClickHandler() {
    if (!isButtonHandlerAttached) {
        _on(document, 'click', `.js-${blockName}__button`, (e) => {
            const targetEl = e._caughtTarget_;
            const sideEl = _closest(targetEl, `.js-${sideClassName}`);
            const componentEl = _closest(sideEl, `.js-${blockName}`);

            if (targetEl.hasAttribute('href') && _isHashString(targetEl.getAttribute('href'))) {
                const paginatorName = _getData(componentEl, 'loadMoreParam');

                if (_isHashPaginator(paginatorName)) {
                    const updatedHashString = _getUpdatedHash(paginatorName, targetEl.getAttribute('href'));

                    if (updatedHashString !== window.location.hash) {
                        const locationObj = window.location;

                        locationObj.hash = updatedHashString;
                        _replaceState(`${locationObj.pathname}${locationObj.search}${locationObj.hash}`);
                    }

                    e.preventDefault();
                    return;
                }
            }

            if (sideEl.classList.contains(sidePositionTopMod)) {
                _load(componentEl, PAGE_PREV);
            } else if (sideEl.classList.contains(sidePositionBottomMod)) {
                _load(componentEl, PAGE_NEXT);
            }

            e.preventDefault();
        });

        isButtonHandlerAttached = true;
    }
}

components.add(`js-${blockName}`, (elementEl, api) => {
    const pageNum = _getData(elementEl, 'loadMorePage');
    const paginatorName = _getData(elementEl, 'loadMoreParam');
    const isHashPaginator = _isHashPaginator(paginatorName);

    _attachButtonClickHandler();

    if (!_isString(paginatorName) || !_isValidPageNum(pageNum)) {
        return;
    }

    if (_isNil(pages[paginatorName])) {
        pages[paginatorName] = {};
        pages[paginatorName][pageNum] = elementEl;

        if (isHashPaginator) {
            const viewPaginatorName = _sanitizeHashString(paginatorName);
            const parsedData = _parseQueryString(_sanitizeHashString(window.location.hash));

            currentPage[paginatorName] = _isValidPageNum(parsedData[viewPaginatorName])
                ? parsedData[viewPaginatorName]
                : undefined;

            if (!isHashChangeHandlerAttached) {
                api.afterAll(() => {
                    if (parsedData[blockName] === PARAM_JUMP) {
                        const currentPages = pages[paginatorName];
                        const pageEl = currentPages[Object.keys(currentPages)[0]];

                        if (!_isNil(pageEl) && _isElement(pageEl) && _isElementVisible(pageEl)) {
                            const scrollTop = pageEl.getBoundingClientRect().top
                                + window.pageYOffset - HeadersHeightManager.getHeight();

                            scroll(scrollTop, { jump: 100 });
                        }
                    }

                    _hashChangeHandler();
                });
            }

            _attachHashChangeHandler();
        } else {
            currentPage[paginatorName] = _getPageNumFromSearch(paginatorName);
        }
    } else {
        pages[paginatorName][pageNum] = elementEl;

        if (!isHashPaginator || _getPageNumFromHash(paginatorName) !== pageNum) {
            _check();
        }
    }

    _attachScrollHandler();
});

function getPaginationSideEl(page) {
    const paginatorNames = Object.keys(pages);

    if (paginatorNames.length > 1) {
        throw new Error('Can not get page to load more: Too many paginators at the page');
    } else if (paginatorNames.length === 0) {
        return null;
    }

    const paginatorPages = pages[paginatorNames[0]];
    const availablePages = Object.keys(paginatorPages)
        .sort((leftPageNum, rightPageNum) => parseInt(leftPageNum, 10) - parseInt(rightPageNum, 10));

    let pageEl;
    let positionMod;

    if (page === PAGE_PREV) {
        pageEl = paginatorPages[availablePages[0]];
        positionMod = sidePositionTopMod;
    } else if (page === PAGE_NEXT) {
        pageEl = paginatorPages[availablePages[availablePages.length - 1]];
        positionMod = sidePositionBottomMod;
    }

    if (pageEl && positionMod) {
        return pageEl.querySelector(`.js-${sideClassName}.${positionMod}`);
    }

    return null;
}

function loadThePage(pageSideEl) {
    if (!pageSideEl || !pageSideEl.classList.contains(sideClassName)) {
        return;
    }

    const buttonEl = pageSideEl.querySelector(`.js-${blockName}__button`);

    if (buttonEl) {
        buttonEl.click();
    }
}

export {
    getPaginationSideEl,
    loadThePage,
    PAGE_NEXT,
    PAGE_PREV,
};
