import components from '../../common/_components';
import { _getData } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import { _on } from '../../common/_events';
import { Search, searchHighlightQuery, SearchSection,
    SearchStates, searchSuggestionClassName } from '../../common/_search';
import queriesPromotion from '../queries-promotion/queries-promotion';

import './layout-search.scss';

const minLength = 2;

const blockName = 'layout-search';
const hasValueMod = `${blockName}_has-value`;

const sharedData = {};
const instances = [];

class LayoutSearch {
    constructor(parentEl, options) {
        this._options = {
            ...{
                placement: '',
                queriesPromotion: false,
                formElements: [],
                resultsMarkupFactory: {},
                onReset: () => {},
            },
            ...options,
        };

        if (!this._options.formElements.some(({ role }) => role === LayoutSearch.ELEMENT_ROLE_INPUT)) {
            throw new Error('Can not construct LayoutSearch with no input element');
        }

        this._isActive = false;
        this._renderTo(parentEl);

        if (sharedData.sections && Object.keys(sharedData.sections).length > 0) {
            this._search = this._getSearch();
        } else {
            this._alternativeHandlersBuild();
        }

        this._inputEl.value = sharedData.inputValue;
        this._onValueChange();

        instances.push(this);
    }

    activate = () => {
        if (!this._isActive) {
            this._isActive = true;

            this._inputEl.value = sharedData.inputValue;
            this._onValueChange();

            instances.forEach((layoutSearch) => {
                if (layoutSearch !== this) {
                    layoutSearch.deactivate();
                }
            });

            if (this._options.queriesPromotion) {
                queriesPromotion.setInput(this._inputEl);
                queriesPromotion.start();
            } else {
                queriesPromotion.stop();
            }
        }
    };

    deactivate = () => {
        this._isActive = false;
        this.hideResults();
    };

    emptyInput = () => {
        this._inputEl.value = '';
        this._onValueChange();
    };

    focusInput = () => {
        this._inputEl.focus();
    };

    hideResults = () => {
        this._inputEl.blur();
    };

    _renderTo = (parentEl) => {
        const rootEl = _createEl('div', `${blockName} ${blockName}_placement_${this._options.placement}`);
        const formEl = _createEl('form', `${blockName}__form`, {
            method: 'GET',
            action: sharedData.searchUrl,
            role: 'search',
        });

        this._options.formElements.forEach(({ role, getEl }) => {
            let el = null;

            if (role === LayoutSearch.ELEMENT_ROLE_INPUT) {
                el = getEl(sharedData.searchParam, sharedData.placeholder);
                this._inputEl = el;
            } else if (role === LayoutSearch.ELEMENT_ROLE_RESET) {
                el = getEl();
                _on(el, 'click', this._resetButtonClickHandler);
            } else if (role === LayoutSearch.ELEMENT_ROLE_SUBMIT) {
                el = getEl();
                _on(el, 'click', this._submitButtonClickHandler);
            }

            if (el) {
                formEl.appendChild(el);
            }
        });

        _on(formEl, 'submit', this._formSubmitHandler);

        this._rootEl = rootEl;
        this._formEl = formEl;

        rootEl.appendChild(formEl);
        parentEl.appendChild(rootEl);
    };

    _getSearch = () => {
        const sectionsData = sharedData.sections || [];
        const markupFactory = this._options.resultsMarkupFactory;
        const resultsEl = markupFactory.getResultsEl();
        const formParentEl = this._formEl.parentNode;

        formParentEl.appendChild(resultsEl);

        return new Search({
            inputEl: this._inputEl,
            resultsEl,
            onInputFocus: this._onInputFocus,
            onInputBlur: this._onInputBlur,
            onInputKeyDown: this._onInputKeyDown,
            onValueChange: this._onValueChange,
            onSelect: (suggestionEl) => {
                const url = suggestionEl.getAttribute('data-suggestion-url');

                if (url) {
                    window.location = url;
                }
            },
            getValueFn: suggestionEl => suggestionEl.getAttribute('data-suggestion-name'),
            sections: Object.keys(sectionsData).map(sectionName => new SearchSection({
                url: sectionsData[sectionName].searchUrl,
                wildcard: '{{query}}',
                render: (state, query, suggestions) => {
                    const { title, showAllUrl, showAllTitle, notFoundMessage } = sectionsData[sectionName];
                    const commonData = {
                        title,
                        showAllLabel: showAllTitle,
                        showAllUrl: state === SearchStates.REGULAR && suggestions.length === 5
                            ? decodeURI(showAllUrl).replace('{{query}}', query)
                            : null,
                    };

                    if (state === SearchStates.PENDING) {
                        markupFactory.renderPending(sectionName, commonData);
                    } else if (state === SearchStates.NOT_FOUND) {
                        markupFactory.renderNotFound(sectionName, commonData, notFoundMessage);
                    } else {
                        markupFactory.renderSuggestions(sectionName, commonData, {
                            query,
                            searchSuggestionClassName,
                            suggestions,
                            highlightFn: window.layoutSearchHighlight === false
                                ? (_, text) => text
                                : searchHighlightQuery,
                        });
                    }
                },
            })),
        });
    };

    _alternativeHandlersBuild = () => {
        _on(this._inputEl, 'keydown', this._onInputKeyDown);
        _on(this._inputEl, 'input', this._onValueChange);
    };

    _onInputFocus = () => {
        if (this._isActive && this._options.queriesPromotion) {
            queriesPromotion.stop();
        }
    };

    _onInputBlur = () => {
        if (this._isActive && this._options.queriesPromotion) {
            queriesPromotion.start();
        }
    };

    _onInputKeyDown = (keydownEvent) => {
        if (keydownEvent.which === 27) {
            this._inputEl.blur();
            setTimeout(this._reset.bind(this, LayoutSearch.RESET_TYPE_ESC_KEY), 0);
        }
    };

    _onValueChange = () => {
        const inputValue = sharedData.inputValue = this._inputEl.value;
        this._rootEl.classList[Search.processQuery(inputValue).length >= minLength ? 'add' : 'remove'](hasValueMod);
    };

    _submitButtonClickHandler = () => {
        if (!this._rootEl.classList.contains(hasValueMod)) {
            this._inputEl.focus();
        } else {
            this._submitForm();
        }
    };

    _resetButtonClickHandler = () => {
        this._reset(LayoutSearch.RESET_TYPE_BUTTON_CLICK);
    };

    _formSubmitHandler = (e) => {
        this._submitForm();
        e.preventDefault();
    };

    _submitForm = () => {
        this._inputEl.value = this._inputEl.value.toLowerCase();
        this._formEl.submit();
    };

    _reset = (type) => {
        this.emptyInput();

        if (this._isActive && this._options.queriesPromotion) {
            queriesPromotion.start();
        }

        this._options.onReset(type);
    };

    static hideResults = () => {
        instances.forEach(layoutSearch => layoutSearch.hideResults());
    };

    static PLACEMENT_HEADER_DESKTOP = 'header-desktop';

    static PLACEMENT_HEADER_MOBILE = 'header-mobile';

    static PLACEMENT_HEADER_MENU = 'menu';

    static RESET_TYPE_BUTTON_CLICK = 'RESET_TYPE_BUTTON_CLICK';

    static RESET_TYPE_ESC_KEY = 'RESET_TYPE_ESC_KEY';

    static ELEMENT_ROLE_SUBMIT = 'ELEMENT_ROLE_SUBMIT';

    static ELEMENT_ROLE_RESET = 'ELEMENT_ROLE_RESET';

    static ELEMENT_ROLE_INPUT = 'ELEMENT_ROLE_INPUT';
}

components.add(`js-${blockName}__data`, (componentsEl) => {
    const searchSections = _getData(componentsEl, 'searchSections');

    sharedData.searchUrl = _getData(componentsEl, 'searchUrl');
    sharedData.searchParam = _getData(componentsEl, 'searchParam');
    sharedData.placeholder = _getData(componentsEl, 'searchPlaceholder');
    sharedData.sections = searchSections ? JSON.parse(searchSections) : null;
    sharedData.inputValue = '';
});

export default LayoutSearch;
