import { _on } from '../../common/_events';
import { _getData } from '../../common/_core';

import './deals-filters-panel__block_for_expired.scss';

const blockName = 'deals-filters-panel';
const containerSelector = `.js-${blockName}__expired`;
const switchSelector = `.js-${blockName}__expired-switch`;
const switchCheckedMod = 'checked';

const expiredKey = 'unexpired';
const disabledValue = '1';
const enabledValue = '0';

class DealsFiltersPanelBlockForExpired {
    constructor(el, urlData, changeHandler) {
        this._el = el.querySelector(containerSelector);
        this._switchEl = this._el.querySelector(switchSelector);
        this._enabledByDefault = _getData(this._el, 'showExpired') === 'true';

        this._switchEl.classList[this._getSwitchAction(urlData) ? 'add' : 'remove'](switchCheckedMod);

        _on(this._el, 'click', () => {
            this._switchEl.classList.toggle(switchCheckedMod);

            if (changeHandler) {
                changeHandler();
            }
        });
    }

    resetToDefault = () => {
        this._switchEl.classList[this._enabledByDefault ? 'add' : 'remove'](switchCheckedMod);
    }

    fulfillUrlData = (currentUrlData) => {
        const isChecked = this._switchEl.classList.contains(switchCheckedMod);

        if (this._enabledByDefault ? !isChecked : isChecked) {
            currentUrlData[expiredKey] = isChecked ? enabledValue : disabledValue;
        } else {
            delete currentUrlData[expiredKey];
        }
    };

    fulfillRequestData = (requestData, currentUrlData) => {
        const urlValue = currentUrlData[expiredKey];

        if (urlValue === enabledValue || urlValue === disabledValue) {
            requestData[expiredKey] = urlValue;
        }
    };

    _getSwitchAction = (urlData) => {
        if (this._enabledByDefault) {
            return urlData[expiredKey] !== disabledValue;
        }

        return urlData[expiredKey] === enabledValue;
    }
}

export { DealsFiltersPanelBlockForExpired };
