import components from '../../common/_components';
import { _sendRequest } from '../../common/_ajax';
import { _on, _off } from '../../common/_events';
import { _closest, _getData, _reflow, _toArray } from '../../common/_core';
import { _createEl } from '../../common/_create-el';
import './page-users-edit.scss';

const blockName = 'page-users-edit';
const radioSelectedMod = 'selected';

components.add(`js-${blockName}`, (el) => {
    const headingEl = el.querySelector(`.js-${blockName}__heading`);
    const imageUploadLinkEl = el.querySelector(`.${blockName}__image-update-link`);
    const radioInputs = _toArray(el.querySelectorAll(`.js-${blockName}__radio-input`));
    const updateRadioLabels = () => {
        radioInputs.forEach((currentInputEl) => {
            const labelEl = _closest(currentInputEl, `.js-${blockName}__radio-label`);

            labelEl.classList[currentInputEl.checked ? 'add' : 'remove'](radioSelectedMod);
        });
    };

    let fileInputEl;

    function deleteAvatar() {
        const imageEl = headingEl.querySelector(`.js-${blockName}__heading-image-img`);
        const inputEl = headingEl.querySelector(`.js-${blockName}__heading-image-input`);

        imageEl.removeAttribute('src');
        inputEl.value = null;

        headingEl.classList.remove('has-image');
    }

    function updateHeadingImage(data) {
        const imageEl = headingEl.querySelector(`.js-${blockName}__heading-image-img`);
        const inputEl = headingEl.querySelector(`.js-${blockName}__heading-image-input`);
        const template = _getData(headingEl, 'imageUrl');
        const placeholder = _getData(headingEl, 'imageUrlPlaceholder');

        imageEl.removeAttribute('src');
        _reflow(imageEl);

        imageEl.setAttribute('src', template.replace(placeholder, data.path));
        inputEl.value = data.id;

        headingEl.classList.add('has-image');
    }

    function processUploadError() {
        const errorClassName = `${blockName}__heading-upload-error js-${blockName}__heading-upload-error`;
        const errorMessage = _getData(headingEl, 'imageUploadingError');

        headingEl.appendChild(_createEl('div', errorClassName, [errorMessage]));
    }

    function uploadFile(file) {
        const uploadingErrorEl = headingEl.querySelector(`.js-${blockName}__heading-upload-error`);
        const url = _getData(headingEl, 'imageUploadUrl');
        const formData = new window.FormData();

        formData.append('file', file);
        imageUploadLinkEl.classList.add('disabled');

        if (uploadingErrorEl) {
            uploadingErrorEl.parentNode.removeChild(uploadingErrorEl);
        }

        _sendRequest({
            method: 'POST',
            url,
            data: formData,
        })
            .done((response) => {
                if (response.success === true) {
                    updateHeadingImage(response.data);
                } else {
                    processUploadError();
                }
            })
            .fail(processUploadError)
            .always(() => {
                imageUploadLinkEl.classList.remove('disabled');
            });
    }

    function setupFileInput() {
        if (!fileInputEl) {
            const changeHandler = () => {
                if (fileInputEl && fileInputEl.files.length > 0) {
                    uploadFile(fileInputEl.files[0]);

                    _off(fileInputEl, 'change', changeHandler);

                    fileInputEl.parentElement.removeChild(fileInputEl);
                    fileInputEl = null;

                    setupFileInput();
                }
            };

            fileInputEl = _createEl('input', `${blockName}__file-input`, {
                type: 'file',
                accept: 'image/jpeg, image/png, image/gif',
            });

            _on(fileInputEl, 'change', changeHandler);
            el.appendChild(fileInputEl);
        }
    }

    _on(el, 'click', `.js-${blockName}__heading-delete-image`, deleteAvatar);
    _on(imageUploadLinkEl, 'mousedown', setupFileInput, { once: true });
    _on(imageUploadLinkEl, 'click', () => {
        if (!imageUploadLinkEl.classList.contains('disabled')) {
            fileInputEl.click();
        }
    });

    radioInputs.forEach(inputEl => _on(inputEl, 'change', (e) => {
        if (e.target.checked) {
            updateRadioLabels();
        }
    }));

    updateRadioLabels();
});
