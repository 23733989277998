import { _getData, _setData, _reflow, _toArray, _getCSSProp } from './_core';
import { _on, _off, _onTransitionEnd } from './_events';

const slideIdDataKey = 'horizontalScrollSlideId';
const DIRECTION_LEFT = 'left';
const DIRECTION_RIGHT = 'right';

class ScrollHorizontal {
    /**
     * Component for horizontal scrolling with mouse and touch events.
     *
     * Example of HTML
     * <div class="...">
     *     <div class="...__list"> (optional wrapper)
     *         <div class="...__item"> ... </div>
     *         <div class="...__item"> ... </div>
     *         ...
     *     </div>
     * </div>
     *
     * @param {Element} el Root element. It has to have "overflow: hidden;" and "position: relative;"
     * for proper work
     * @param {Element} listEl Element to change position inside Root element
     * @param {String} itemSelector Selector of list item
     *
     * @param {Object} [options]
     * @param {Boolean} [options.isInfinity=true] If true, the list items are looped up to be able to scroll set of
     * the items infinitely. This mode means that at "mousedown"/"touchstart" event the component  copy the items
     * to insert them before and after source ones.
     * @param {Function} [options.onDragStart] Callback for "drag start" event
     * @param {Function} [options.onSlideChange] Callback to call when current element is changed
     * @param {Function} [options.onSlideChangeDone] Callback for end of slide change animation
     * @param {Boolean} [options.scrollToCurrentItem=true] If true, the list is to be scrolled up to left side of
     * the nearest item  when scrolling by user is finished
     * @param {Number} [options.changeItemOffset=15] Option is used when `scrollToCurrentItem` is `true`. It means
     * minimal distance which is to be scrolled to change left element
     * @param {String} [options.animationMod='animation'] Class to add transition to list element for animation
     */
    constructor(el, listEl, itemSelector, options) {
        const scrollOptions = {
            ...{
                isInfinity: true,
                onDragStart: () => {},
                onSlideChange: () => {},
                onSlideChangeDone: () => {},
                scrollToCurrentItem: true,
                changeItemOffset: 15,
                animationMod: 'animation',
            },
            ...options,
        };

        const items = _toArray(listEl.querySelectorAll(itemSelector));

        items.forEach((itemEl, index) => {
            _setData(itemEl, slideIdDataKey, `item_${index}`);
        });

        this._el = el;
        this._listEl = listEl;

        this._itemSelector = itemSelector;
        this._options = scrollOptions;

        this._currentItemEl = items[0];
        this._itemsLength = items.length;
        this._isTouchable = true;
        this._isDragging = false;

        this._bindListeners();
    }

    moveRight = () => {
        this._setupFakeItems();

        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
        const leftItemElIndex = items.indexOf(this._currentItemEl);
        const nextItemsLength = leftItemElIndex > -1 ? items.length - (leftItemElIndex + 1) : 0;

        if (nextItemsLength >= this._itemsLength) {
            this._setCurrentItem(items[leftItemElIndex + 1]);
            this._scrollToCurrent();
        }
    };

    moveLeft = () => {
        this._setupFakeItems();

        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
        const leftItemElIndex = items.indexOf(this._currentItemEl);
        const prevItemEl = items[leftItemElIndex - 1];

        if (prevItemEl) {
            this._setCurrentItem(prevItemEl);
            this._scrollToCurrent();
        }
    };

    moveTo = (id) => {
        const currentSlideId = ScrollHorizontal.getSlideId(this._currentItemEl);
        const currentSlideIndex = this._getIndexFromId(currentSlideId);
        const targetSlideIndex = this._getIndexFromId(id);
        let itemEl;

        if (targetSlideIndex < 0 || targetSlideIndex >= this._itemsLength) {
            return;
        }

        if (targetSlideIndex < currentSlideIndex) {
            this._setupFakeItems();

            const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
            itemEl = this._getItemById(id, items.slice(0, items.indexOf(this._currentItemEl)).reverse());
        } else if (targetSlideIndex > currentSlideIndex) {
            const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
            itemEl = this._getItemById(id, items.slice(items.indexOf(this._currentItemEl)));
        }

        if (itemEl) {
            this._setCurrentItem(itemEl);
            this._scrollToCurrent();
        }
    };

    canScroll = () => {
        const marginLeft = parseFloat(_getCSSProp(this._listEl, 'marginLeft'));
        const marginRight = parseFloat(_getCSSProp(this._listEl, 'marginRight'));
        const listWidth = this._listEl.scrollWidth + marginLeft + marginRight;

        return this._el.getBoundingClientRect().width < listWidth;
    };

    resetListEl = () => {
        this._listEl.style.transform = '';
    };

    getCurrentSlideId = () => ScrollHorizontal.getSlideId(this._currentItemEl);

    _bindListeners = () => {
        _on(this._listEl, 'touchstart', this._dragStartHandler, { passive: true });
        _on(this._listEl, 'mousedown', (e) => {
            this._dragStartHandler(e);
            e.preventDefault();
        });
    };

    _getIndexFromId = (id) => {
        const indexRE = /item_(\d+)/;
        const match = id.match(indexRE);

        return match ? parseInt(match[1], 10) : -1;
    };

    _getItemById = (id, items) => {
        for (let i = 0; i < items.length; i += 1) {
            if (ScrollHorizontal.getSlideId(items[i]) === id) {
                return items[i];
            }
        }

        return null;
    };

    _dragStartHandler = (e) => {
        const isMouse = this._isMouse(e);

        if (this.canScroll() && this._isTouchable && this._isMainPointer(e)) {
            this._options.onDragStart();
            this._stopAnimation();

            this._isDragging = true;

            _on(document, isMouse ? 'mousemove' : 'touchmove', this._dragMoveHandler);
            _on(document, isMouse ? 'mouseup' : 'touchend', this._dragEndHandler);
        }
    };

    _isMainPointer = e => (this._isMouse(e) && e.which === 1) || (e.touches && e.touches.length === 1);

    _isMouse = e => e.type.indexOf('mouse') === 0;

    _getClientX = e => (e.touches && e.touches.length ? e.touches[0].clientX : e.clientX);

    _getTranslateX = () => {
        const transformValue = _getCSSProp(this._listEl, 'transform');
        const translateX = /matrix\(-?[.0-9]+, -?[.0-9]+, -?[.0-9]+, -?[.0-9]+, (-?[.0-9]+), -?[.0-9]+\)/
            .exec(transformValue);

        if (translateX) {
            return parseFloat(translateX[1]);
        }

        return 0;
    };

    _setTranslateX = (value) => {
        this._listEl.style.transform = `translateX(${value}px)`;
    };

    _getCurrentSlideX = () => {
        const listElRect = this._listEl.getBoundingClientRect();
        const currentElRect = this._currentItemEl.getBoundingClientRect();

        return listElRect.left - currentElRect.left;
    };

    _dragMoveHandler = (e) => {
        if (this._isDragging === true && !this._startClientX) {
            this._setupFakeItems();

            this._startScrollTop = window.pageYOffset;
            this._startClientX = this._getClientX(e);
            this._startTranslateX = this._getTranslateX();
        } else if (Math.abs(window.pageYOffset - this._startScrollTop) > 1) {
            this._dragEndHandler({
                type: this._isMouse(e) ? 'mouseup' : 'touchend',
                which: 1,
            });
        }

        if (this._startClientX !== undefined && this._startTranslateX !== undefined) {
            const clientX = this._getClientX(e);
            const prevClientX = this._clientX || this._startClientX;
            const translateX = this._startTranslateX + (clientX - this._startClientX);
            const clientXDiff = clientX - prevClientX;

            this._clientX = clientX;

            if (clientXDiff < 0) {
                this._direction = DIRECTION_LEFT;
            } else if (clientXDiff > 0) {
                this._direction = DIRECTION_RIGHT;
            } else {
                this._direction = undefined;
            }

            this._setTranslateX(translateX);
        }
    };

    _dragEndHandler = (e) => {
        const isMouse = this._isMouse(e);
        const wasDragged = (this._clientX !== undefined);

        if (isMouse && e.which !== 1) {
            return;
        }

        _off(document, isMouse ? 'mousemove' : 'touchmove', this._dragMoveHandler);
        _off(document, isMouse ? 'mouseup' : 'touchend', this._dragEndHandler);

        this._setCurrentItem(this._getCurrentItemEl(this._direction));

        if (isMouse && Math.abs(this._clientX - this._startClientX) > 5) {
            this._preventClick(e.target);
        }

        this._isDragging = false;

        this._startClientX = undefined;
        this._startTranslateX = undefined;
        this._clientX = undefined;
        this._direction = undefined;

        if (this._options.scrollToCurrentItem && wasDragged) {
            this._isTouchable = false;
            this._scrollToCurrent();
        }
    };

    _preventClick = (el) => {
        const parentLinkEl = this._closestLinkEl(el);
        const preventDefaultFn = (clickEvent) => {
            clickEvent.preventDefault();
            clickEvent.stopPropagation();
        };

        if (parentLinkEl) {
            _on(parentLinkEl, 'click', preventDefaultFn, { once: true });

            setTimeout(() => {
                _off(parentLinkEl, 'click', preventDefaultFn);
            }, 100);
        }
    };

    _closestLinkEl = (el) => {
        while (el && el !== this._el && el.nodeType === Node.ELEMENT_NODE) {
            if (el.tagName.toLowerCase() === 'a') {
                return el;
            }

            el = el.parentNode;
        }

        return null;
    };

    _getCurrentItemEl = (direction) => {
        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));
        const elRect = this._el.getBoundingClientRect();
        const targetLeft = elRect.left;
        const maxIndex = items.length > this._itemsLength
            ? items.length - this._itemsLength
            : items.length - Math.max(Math.floor(elRect.width / items[0].getBoundingClientRect().width), 1);

        let index = -1;
        let minOffset = Infinity;

        items.forEach((item, i) => {
            const itemOffset = item.getBoundingClientRect().left - targetLeft;

            if (Math.abs(itemOffset) < Math.abs(minOffset) && i <= maxIndex) {
                minOffset = itemOffset;
                index = i;
            }
        });

        let currentItemEl = items[index];

        if (Math.abs(minOffset) > this._options.changeItemOffset) {
            if (direction === DIRECTION_RIGHT && minOffset > 0 && index > 0) {
                currentItemEl = items[index - 1];
            } else if (direction === DIRECTION_LEFT && minOffset < 0 && index < maxIndex) {
                currentItemEl = items[index + 1];
            }
        }

        return currentItemEl;
    };

    _setCurrentItem = (itemEl) => {
        if (this._currentItemEl !== itemEl) {
            this._currentItemEl = itemEl;
            this._options.onSlideChange(itemEl);
        }
    };

    _scrollToCurrent = () => {
        this._stopAnimation();
        this._setupFakeItems();

        const targetTranslateX = this._getCurrentSlideX();

        if (Math.round(Math.abs(targetTranslateX - this._getTranslateX())) < 1) {
            this._onAnimationDone();
        } else {
            this._listEl.classList.add(this._options.animationMod);

            _onTransitionEnd(this._listEl, this._onAnimationDone);
            this._setTranslateX(targetTranslateX);
        }
    };

    _stopAnimation = () => {
        this._setTranslateX(this._getTranslateX());
        this._listEl.classList.remove(this._options.animationMod);

        _reflow(this._listEl);
    };

    _onAnimationDone = () => {
        this._listEl.classList.remove(this._options.animationMod);

        this._removeFakeItems();
        this._isTouchable = true;

        this._options.onSlideChangeDone(this._currentItemEl);
    };

    _setupFakeItems = () => {
        if (!this._options.isInfinity) {
            return;
        }

        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));

        if (items.length === this._itemsLength) {
            const prevTranslateX = this._getTranslateX();
            const prevListElWidth = this._listEl.scrollWidth;
            const parentEl = items[0].parentNode;
            let fragment;

            fragment = document.createDocumentFragment();
            items.forEach(itemEl => fragment.appendChild(itemEl.cloneNode(true)));
            parentEl.insertBefore(fragment, items[0]);

            this._setTranslateX(prevTranslateX - (this._listEl.scrollWidth - prevListElWidth));

            fragment = document.createDocumentFragment();
            items.forEach(itemEl => fragment.appendChild(itemEl.cloneNode(true)));
            parentEl.appendChild(fragment);
        }
    };

    _removeFakeItems = () => {
        if (!this._options.isInfinity) {
            return;
        }

        const items = _toArray(this._listEl.querySelectorAll(this._itemSelector));

        if (items.length !== this._itemsLength) {
            const startIndex = items.indexOf(this._currentItemEl);
            const prevTranslateX = this._getTranslateX();
            const prevListElWidth = this._listEl.scrollWidth;
            const parentEl = items[0].parentNode;

            for (let i = 0; i < startIndex; i += 1) {
                parentEl.removeChild(items[i]);
            }

            this._setTranslateX(prevTranslateX - (this._listEl.scrollWidth - prevListElWidth));

            for (let i = startIndex + this._itemsLength; i < items.length; i += 1) {
                parentEl.removeChild(items[i]);
            }
        }
    };

    static installTo(el, listEl, itemSelector, options) {
        return new ScrollHorizontal(el, listEl, itemSelector, options);
    }

    static getSlideId(itemEl) {
        return _getData(itemEl, slideIdDataKey);
    }
}

export default ScrollHorizontal;
