import components from '../../common/_components';
import { _getData } from '../../common/_core';
import { _off, _on } from '../../common/_events';
import Tooltip from '../../components.theme/tooltip/tooltip';
import './wysiwyg-newsletter.scss';

const blockName = 'wysiwyg-newsletter';

components.add(`js-${blockName}__form`, (formEl) => {
    const inputEl = formEl.querySelector(`.js-${blockName}__input`);
    const buttonEl = formEl.querySelector(`.js-${blockName}__button`);

    let tooltip;

    function hideTooltip() {
        if (tooltip) {
            tooltip.hide();
        }
    }

    function sendForm() {
        const iframe = document.createElement('iframe');
        const name = `iframe-${blockName}-${Date.now()}`;
        const abortTimer = setTimeout(() => {
            _off(iframe, 'load', onIframeReady);
            _off(iframe, 'load', onDataLoaded);

            _on(window, 'scroll', hideTooltip, { once: true });
            tooltip.show(_getData(formEl, 'messageFail'));

            inputEl.disabled = false;
            buttonEl.disabled = false;

            iframe.parentNode.removeChild(iframe);
        }, 5000);

        function onDataLoaded() {
            if (abortTimer) {
                clearTimeout(abortTimer);
            }

            inputEl.placeholder = _getData(formEl, 'messageSuccess');
            inputEl.value = '';

            iframe.parentNode.removeChild(iframe);
        }

        function onIframeReady() {
            _on(iframe, 'load', onDataLoaded, { once: true });

            formEl.setAttribute('target', name);
            formEl.submit();
        }

        inputEl.disabled = true;
        buttonEl.disabled = true;

        iframe.setAttribute('name', name);
        iframe.setAttribute('src', 'about:blank');
        iframe.setAttribute('width', '0');
        iframe.setAttribute('height', '0');

        iframe.style.position = 'absolute';
        iframe.style.top = '-100px';
        iframe.style.left = '-100px';

        _on(iframe, 'load', onIframeReady, { once: true });
        document.body.append(iframe);
    }

    _on(inputEl, 'input', () => {
        const isValid = inputEl.checkValidity ? inputEl.checkValidity() : inputEl.value.length > 0;

        hideTooltip();
        buttonEl.disabled = !isValid;
    });

    _on(formEl, 'submit', (e) => {
        tooltip = new Tooltip(inputEl, {
            availablePositions: ['top-center'],
        });

        e.preventDefault();
        tooltip.hide();

        if (!buttonEl.disabled) {
            sendForm();
        }
    });
});
